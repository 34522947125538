import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { LatLng, pointArrayToGeoJsonPolygonCoordinates } from '@lib/gis';
import { area, polygon } from '@turf/turf';
import { useEffect } from 'react';
import { DisruptionAreaEditorManager } from './useDisruptionAreaEditorManager';
import cityDisruptionBg from './city-disruption.svg?inline';
import AlertBox from '../AlertBox';

const MAX_AREA = 2.005e6;

export default function DisruptionAreaEditorAutoSavePanel({
  disruptionAreaEditorManager,
}: {
  disruptionAreaEditorManager: DisruptionAreaEditorManager;
}) {
  const {
    polygonCreatorManager,
    disruptionAreaState,
    isMatchWorkAreas,
    hasWorkAreas,
    resetDisruptionArea,
  } = disruptionAreaEditorManager;
  const { isCreating, startCreating, stopCreating } = polygonCreatorManager;
  const areaSize = disruptionAreaState
    ? getPolygonAreaSize(disruptionAreaState)
    : 0;

  useEffect(() => {
    if (hasWorkAreas && !disruptionAreaState) {
      resetDisruptionArea();
    }
  }, [hasWorkAreas, disruptionAreaState, resetDisruptionArea]);

  if (isCreating || !disruptionAreaState) {
    return (
      <LayoutDisruptionAreaPanel>
        <Box>
          <Text fontSize="xs">
            <FormattedMessage
              id="bVNDf+"
              defaultMessage="Selecting your disruption area will allow us to give you context of potential local business and transport disruption"
            />
          </Text>
          {isCreating ? (
            <Button onClick={stopCreating} variant="outline" size="xs">
              <FormattedMessage id="4P6d6V" defaultMessage="Cancel drawing" />
            </Button>
          ) : (
            <Button
              onClick={startCreating}
              variant="solid"
              colorScheme="greenDark"
              size="sm"
            >
              <FormattedMessage id="g2dK3U" defaultMessage="Define area" />
            </Button>
          )}
        </Box>
      </LayoutDisruptionAreaPanel>
    );
  }

  return (
    <LayoutDisruptionAreaPanel>
      <Text fontSize="xs" m="0">
        {isMatchWorkAreas && (
          <FormattedMessage
            id="1rpFdi"
            defaultMessage="We’ve automatically defined a disruption area based on your work area. "
          />
        )}
        <FormattedMessage
          id="kalo7x"
          defaultMessage="You can edit this area by dragging the circle handles on the map, or you can draw a new area."
        />
      </Text>

      <HStack spacing={4}>
        <Button
          onClick={startCreating}
          variant="solid"
          colorScheme="greenDark"
          size="xs"
        >
          <FormattedMessage id="pnhDfh" defaultMessage="Draw new area" />
        </Button>
        {hasWorkAreas && (
          <Button
            onClick={resetDisruptionArea}
            variant="outline"
            isDisabled={isMatchWorkAreas}
            size="xs"
          >
            <FormattedMessage id="JCLv8a" defaultMessage="Reset to work area" />
          </Button>
        )}
      </HStack>

      <Text
        fontSize="xs"
        m={0}
        sx={{
          strong: {
            color: areaSize > MAX_AREA ? 'red.500' : 'gray.700',
          },
        }}
      >
        <FormattedMessage
          defaultMessage="Total area: <strong>{area}</strong>"
          id="zZ7kOB"
          values={{
            area: <FormattedAreaSize areaSize={areaSize} />,
          }}
        />
      </Text>
      {areaSize > MAX_AREA && (
        <AlertBox
          title={
            <FormattedMessage id="BRNP5/" defaultMessage="Area too large" />
          }
          message={
            <FormattedMessage
              id="gpTJQD"
              defaultMessage="We currently only support disruption areas of 2km² or less. Please reduce the size or your disruption area."
            />
          }
        />
      )}
    </LayoutDisruptionAreaPanel>
  );
}

function LayoutDisruptionAreaPanel({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <VStack spacing={4} alignItems="left">
      <Box
        background={`bottom center/100% no-repeat url(${cityDisruptionBg})`}
        backgroundColor="yellow.100"
        borderRadius="3xl"
        padding="6"
        paddingBottom="38px"
      >
        <Text fontSize="sm" fontWeight="bold" mb={1}>
          <FormattedMessage
            id="jUkeBH"
            defaultMessage="Disruption areas help you better understand your potential impact"
          />
        </Text>
        <Text fontSize="xs">
          <FormattedMessage
            id="NMo53B"
            defaultMessage="See potential business and public transport disrupted by your work."
          />
        </Text>
      </Box>
      {children}
    </VStack>
  );
}

function FormattedAreaSize({ areaSize }: { areaSize: number }) {
  const areaSizeInKm = areaSize / 1e6;
  if (areaSizeInKm >= 1) {
    return (
      <FormattedMessage
        defaultMessage="{areaSize, number}km²"
        values={{ areaSize: areaSizeInKm.toFixed(2) }}
        id="Is+kif"
      />
    );
  }
  return (
    <FormattedMessage
      defaultMessage="{areaSize, number}m²"
      values={{ areaSize }}
      id="dhxCnF"
    />
  );
}

function getPolygonAreaSize(points: LatLng[]) {
  const polygonCoordinates = pointArrayToGeoJsonPolygonCoordinates(points);
  const tPolygon = polygon(polygonCoordinates);
  const areaSize = area(tPolygon);
  return Math.round(areaSize);
}
