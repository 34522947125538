import { Heading, HStack, VStack, Text, Button } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import Taperecorder from '../../svg/tape-recorder.svg?react';
import LayoutPanel from '../LayoutPanel';

export default function SiteImpactEmptyStatePanel({
  variant,
  onManageBaselinesClick,
}: {
  variant: 'recording' | 'empty';
  onManageBaselinesClick?: () => void;
}) {
  return (
    <LayoutPanel
      data-testid="view-site-chart-panel-empty-site-impact"
      padding="0px"
      backgroundColor="yellow.50"
    >
      <HStack
        w="full"
        alignItems="center"
        h="76px"
        justifyContent="space-between"
        px={4}
      >
        <HStack>
          <VStack alignItems="flex-start">
            <Heading size="md">Site impact</Heading>
            <Text fontSize="xs" as="span">
              {variant === 'recording' && (
                <FormattedMessage
                  defaultMessage="Recording in progress"
                  id="rOEJRJ"
                />
              )}
              {variant === 'empty' && (
                <FormattedMessage
                  defaultMessage="Understand how your site is performing compared to a baseline period"
                  id="d1pDub"
                />
              )}
            </Text>
          </VStack>
          {variant === 'recording' && <Taperecorder width="112px" />}
        </HStack>
        {onManageBaselinesClick && (
          <Button
            variant="outline"
            size="xs"
            onClick={onManageBaselinesClick}
            data-testid="manage-baselines-trigger"
          >
            <FormattedMessage defaultMessage="Manage baselines" id="wFD8u1" />
          </Button>
        )}
      </HStack>
    </LayoutPanel>
  );
}
