import { Flex, Text, Heading, Box, HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Card from '../../atoms/card';

export const ListItem = ({
  onMouseEnter,
  onMouseLeave,
  title,
  description,
  content,
  rightActions,
  colorMode,
}: {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  content?: ReactNode;
  rightActions?: ReactNode;
  title: ReactNode;
  description: ReactNode;
  colorMode?: 'light' | 'dark';
}) => (
  <Card
    colorMode={colorMode}
    variant="link"
    width="full"
    data-testid="list-item"
    marginBottom={4}
    padding={4}
    position="relative"
    boxShadow="soft"
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <Flex justifyContent="space-between" alignItems="center">
      <Box>
        <Heading size="sm">{title}</Heading>
        <Text fontSize="xs" lineHeight={4} m={0}>
          {description}
        </Text>
        {content}
      </Box>
      <HStack spacing={2} alignContent="stretch" alignItems="center">
        {rightActions}
      </HStack>
    </Flex>
  </Card>
);
