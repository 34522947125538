import { ReactNode } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Box,
} from '@chakra-ui/react';

export interface AlertBoxProps {
  title?: ReactNode;
  message?: ReactNode;
  cta?: {
    label: ReactNode;
    action: () => void;
  };
  status?: 'warning' | 'success' | 'info';
}

export default function AlertBox({
  title,
  message,
  cta,
  status = 'warning',
}: AlertBoxProps): JSX.Element {
  return (
    <Alert status={status}>
      <AlertIcon />
      <Box>
        {title && <AlertTitle>{title}</AlertTitle>}
        {message && <AlertDescription>{message}</AlertDescription>}
        {cta && (
          <Button
            onClick={cta.action}
            variant="link"
            fontSize="sm"
            fontWeight="bold"
            textDecoration="underline"
          >
            {cta.label}
          </Button>
        )}
      </Box>
    </Alert>
  );
}
