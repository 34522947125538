import useSWR from 'swr';
import { Permission } from 'permissions';
import { trpc } from '../config/trpc';

interface FetcherKey {
  keyName: string;
  orgId: string;
}

export const useOrgPermissions = (orgId: string | undefined) => {
  const { data, error, isValidating } = useSWR<Permission[]>(
    orgId
      ? {
          keyName: 'userOrgPermissions',
          orgId,
        }
      : null,
    async (params: FetcherKey) =>
      trpc.user.orgPermissions.query({
        orgId: params.orgId,
      }),
    { revalidateOnFocus: false }
  );
  return {
    data,
    error,
    isValidating,
  };
};
