import { SmallCloseIcon } from '@chakra-ui/icons';
import {
  ModalProps,
  ModalOverlayProps,
  ModalContentProps,
  ModalHeaderProps,
  ModalBodyProps,
  ModalFooterProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  Box,
  IconButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

export function StyledModal({
  header,
  children,
  footer,
  isOpen,
  onClose,
  modalProps,
  modalOverelayProps,
  modalContentProps,
  modalHeaderProps,
  modalBodyProps,
  modalFooterProps,
}: {
  header: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  isOpen: ModalProps['isOpen'];
  onClose: ModalProps['onClose'];
  modalProps?: Omit<ModalProps, 'isOpen' | 'onClose' | 'children'>;
  modalOverelayProps?: ModalOverlayProps;
  modalContentProps?: Omit<ModalContentProps, 'children'>;
  modalHeaderProps?: Omit<ModalHeaderProps, 'children'>;
  modalBodyProps?: Omit<ModalBodyProps, 'children'>;
  modalFooterProps?: Omit<ModalFooterProps, 'children'>;
}) {
  return (
    <Modal isCentered {...(modalProps ?? {})} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        backgroundColor="whiteAlpha.800"
        {...(modalOverelayProps ?? {})}
      />
      <ModalContent
        padding={8}
        gap={4}
        borderRadius="32px"
        {...(modalContentProps ?? {})}
      >
        <ModalHeader padding={0} {...(modalHeaderProps ?? {})}>
          <Flex justifyContent="space-between" gap={2}>
            <Box flex={1}>{header}</Box>
            <IconButton
              variant="solid"
              size="sm"
              aria-label="close"
              onClick={onClose}
              icon={<SmallCloseIcon />}
            />
          </Flex>
        </ModalHeader>
        <ModalBody padding={0} {...(modalBodyProps ?? {})}>
          {children}
        </ModalBody>
        {footer && (
          <ModalFooter
            padding={0}
            justifyContent="start"
            {...(modalFooterProps ?? {})}
          >
            {footer}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}
