// DEPRECATED: use react-hook-form instead

import { useState, useEffect, useRef } from 'react';
import {
  UseFormProps,
  FormFieldsConfig,
  FormValidationResult,
  FormValidationConfig,
} from '../types/form';

export default function useForm<TFieldsConfig extends FormFieldsConfig>(
  fieldsConfig: TFieldsConfig
): UseFormProps<TFieldsConfig> {
  const fieldsConfigRef = useRef(fieldsConfig);
  const [formState, setFormState] = useState(fieldsConfig);
  const formFields: UseFormProps['fields'] = {};
  useEffect(() => {
    if (fieldsConfigRef.current !== fieldsConfig) {
      fieldsConfigRef.current = fieldsConfig;
    }
  }, [fieldsConfigRef, fieldsConfig]);
  Object.keys(fieldsConfig).forEach((fieldKey) => {
    formFields[fieldKey] = {
      value: formState[fieldKey],
      // onChange has type of function only for string and boolean fields (to be directly assigned to input elements)
      // other types will get the same function, but typescript will warn that onChange is undefined
      // @ts-ignore
      onChange: (e) => {
        const target = e.currentTarget as HTMLInputElement;
        formFields[fieldKey].setValue(
          target.type === 'checkbox' ? target.checked : target.value
        );
      },
      setValue: (newValue) => {
        setFormState((asyncState) => ({
          ...asyncState,
          [fieldKey]: newValue,
        }));
      },
    };
  });
  return {
    fields: formFields as UseFormProps<TFieldsConfig>['fields'],
    values: formState,
    setValues: (newValues) => {
      setFormState(newValues);
    },
    reset: () => {
      setFormState(fieldsConfigRef.current);
    },
  };
}

export function useFormValidation<
  TUseFormProps extends UseFormProps<any>,
  TFormValidationConfig extends FormValidationConfig<TUseFormProps>,
>(
  form: TUseFormProps,
  validationConfig: TFormValidationConfig
): FormValidationResult<TUseFormProps, TFormValidationConfig> {
  const [validationState, setValidationState] = useState<{
    errors: Record<string, string | undefined>;
    failedValues: Record<string, unknown>;
  }>({
    errors: {},
    failedValues: {},
  });
  const validationFields: any = {};
  Object.keys(validationConfig).forEach((fieldKey) => {
    validationFields[fieldKey] = {
      error: validationState.errors[fieldKey],
      checkError: () => {
        const checkErrorFunction =
          validationConfig[fieldKey as keyof TFormValidationConfig];
        const error =
          typeof checkErrorFunction === 'function'
            ? checkErrorFunction(form.values[fieldKey], form.values)
            : undefined;
        setValidationState((asyncState) => ({
          errors: { ...asyncState.errors, [fieldKey]: error },
          failedValues: !error
            ? asyncState.failedValues
            : {
                ...asyncState.failedValues,
                [fieldKey]: form.values[fieldKey],
              },
        }));
        return error;
      },
      validate: () => !validationFields[fieldKey].checkError(),
    };
  });
  // clear error when failed value updates
  useEffect(() => {
    Object.keys(validationState.failedValues).forEach((fieldKey) => {
      if (validationState.failedValues[fieldKey] !== form.values[fieldKey]) {
        setValidationState((asyncState) => {
          const updatedFailedValues = {
            ...asyncState.failedValues,
          };
          delete updatedFailedValues[fieldKey];
          return {
            errors: { ...asyncState.errors, [fieldKey]: undefined },
            failedValues: updatedFailedValues,
          };
        });
      }
    });
  }, [form, validationState.failedValues]);
  return {
    fields: validationFields as FormValidationResult<
      TUseFormProps,
      TFormValidationConfig
    >['fields'],
    validate: () => {
      let isValid = true;
      Object.keys(validationConfig).forEach((fieldKey) => {
        isValid = validationFields[fieldKey].validate() && isValid;
      });
      return isValid;
    },
  };
}
