import {
  parse,
  format,
  addMinutes,
  isBefore,
  startOfWeek,
  Day,
} from 'date-fns';
import { findTimeZone, getZonedTime } from 'timezone-support';
import { z } from 'zod';

export function getOffset(date: number, zoneName: string) {
  const tz = findTimeZone(zoneName);
  const zonedDate = getZonedTime(date, tz);
  return (zonedDate.zone?.offset ?? 0) / 60;
}

/**
 * YYYY-MM-DD
 */
export type LocalDate =
  `${number}${number}${number}${number}-${number}${number}-${number}${number}`;
export function isLocalDate(date?: string): date is LocalDate {
  return (
    typeof date === 'string' &&
    /^\d{4}-\d{2}-\d{2}$/.test(date) &&
    !Number.isNaN(Date.parse(date))
  );
}

export function parseDate(
  date: LocalDate,
  zone?: { offset?: number; name?: string }
): Date {
  const d = parse(date, 'yyyy-MM-dd', new Date());
  if (zone) {
    const currentOffset = d.getTimezoneOffset();
    return new Date(
      d.getTime() +
        ((zone.offset ?? getOffset(d.getTime(), zone.name!)) * 60 -
          currentOffset) *
          60 *
          1000
    );
  }
  return d;
}

export function startOfWeekInTimezone(
  date: Date | number,
  timezone: string,
  weekStartsOn: Day
): Date {
  // in local timezone
  const d = new Date(date);
  const localDate = formatLocalDate(d); // to make sure we removed any timezone
  const startOfWeekInLocal = startOfWeek(parseDate(localDate), {
    weekStartsOn,
  });
  return parseDate(formatLocalDate(startOfWeekInLocal), { name: timezone });
}

export function formatLocalDate(date: Date): LocalDate {
  return format(date, 'yyyy-MM-dd') as LocalDate;
}
export function parseLocalDate(localDateStr: LocalDate): Date {
  const [year, month, day] = localDateStr.split('-');
  return new Date(Number(year), Number(month) - 1, Number(day));
}

export enum DayOfWeek {
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
  SAT = 6,
  SUN = 7,
}

export const WeekDays = [
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
] as const;

export function sortedWeekDays(weekStart: number) {
  return WeekDays.slice(weekStart).concat(WeekDays.slice(0, weekStart));
}

export type WeekDay = (typeof WeekDays)[number];

export type Time = `${number}${number}:${number}${number}:${number}${number}`;

export function getSecondsSinceStartOfDay(time: Time): number {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 60 * 60 + minutes * 60 + seconds;
}

export function getMinutesSinceStartOfWeek(date: Date, zone: string): number {
  const tz = findTimeZone(zone);
  const zonedDate = getZonedTime(date, tz);
  return (
    zonedDate.hours * 60 +
    zonedDate.minutes +
    ((zonedDate.dayOfWeek || 7) - 1) * 24 * 60 // Sunday is 0, but should be 7
  );
}

/**
 * @param startTime: milliseconds
 * @param endTime: milliseconds
 * @param binMinutes: minutes
 */
export const getFutureDatapoints = (
  startTime: number,
  endTime: number,
  binMinutes: number
) => {
  const futureDatapoints: {
    value: number | null;
    time: number;
  }[] = [];
  const now = new Date().getTime();
  let time = addMinutes(startTime, binMinutes).getTime();
  if (time > now && endTime > now) {
    while (isBefore(time, endTime)) {
      futureDatapoints.push({
        time,
        value: null,
      });
      time = addMinutes(time, binMinutes).getTime();
    }
  }
  return futureDatapoints;
};

export const weekDaysSchema = z.union([
  z.literal('MON'),
  z.literal('TUE'),
  z.literal('WED'),
  z.literal('THU'),
  z.literal('FRI'),
  z.literal('SAT'),
  z.literal('SUN'),
]);
