import useSWR from 'swr';
import {
  DisruptionPackResponse,
  DisruptionPackType,
} from '@webapp/bff/src/types/report';
import { useEffect, useState } from 'react';
import { trpc } from '../config/trpc';

export default function useDisruptionPacksData(
  siteId?: string,
  suspense = false
) {
  const [hasPendingPackState, setHasPendingPackState] = useState(false);
  const { data, mutate, isValidating, error } = useSWR<
    DisruptionPackResponse[]
  >(
    `${siteId}-disruption-packs`,
    !siteId
      ? null
      : () => trpc.report.getDisruptionPacksBySiteId.query({ siteId }),
    {
      suspense,
      refreshInterval: hasPendingPackState ? 10000 : undefined,
    }
  );
  useEffect(() => {
    const dataHasPendingPack = (data || []).some(
      (pack) => pack.status === 'PENDING'
    );
    if (dataHasPendingPack !== hasPendingPackState) {
      setHasPendingPackState(dataHasPendingPack);
    }
  }, [hasPendingPackState, data]);

  const mutateThenUpdate =
    (serverOp) =>
    async (
      ...args: Parameters<typeof serverOp>
    ): Promise<ReturnType<typeof serverOp>> => {
      const response = await serverOp(...args);
      await mutate();
      return response;
    };

  return {
    data,
    error,
    isValidating,
    requestImpactAssessment: ({
      recipientEmails,
      impactAssessmentType,
    }: {
      recipientEmails: string[];
      impactAssessmentType: DisruptionPackType[];
    }) =>
      mutateThenUpdate(trpc.report.createDisruptionPack.mutate)({
        recipientEmails,
        impactAssessmentType,
        siteId,
      }),
    createDisruptionPack: ({
      recipientEmails,
      requestVolumeAnalysis,
    }: {
      recipientEmails: string[];
      requestVolumeAnalysis: boolean;
    }) =>
      mutateThenUpdate(trpc.report.createDisruptionPack.mutate)({
        recipientEmails,
        requestVolumeAnalysis,
        siteId,
      }),
  };
}
