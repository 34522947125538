import {
  BoxProps,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { DisruptionPackResponse } from '@webapp/bff/src/types/report';
import { DATE_PRESET_SHORT_D_M_Y } from 'design-system/utilities/date-presets';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { TIME_PRESET_COMPLETE_HOUR12 } from '../../utils/dateUtils';

export interface ImpactAssessmentHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  requestsHistory: DisruptionPackResponse[];
}

const tableCellStyles: BoxProps = {
  paddingLeft: 0,
  paddingY: 2,
  border: 'none',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'normal',
  verticalAlign: 'top',
};

export function ImpactAssessmentHistoryModal({
  isOpen,
  onClose,
  requestsHistory,
}: ImpactAssessmentHistoryModalProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay backgroundColor="whiteAlpha.800" />
      <ModalContent borderRadius="32px">
        <ModalHeader
          paddingTop={8}
          paddingLeft={8}
          paddingRight={16}
          paddingBottom={4}
        >
          <FormattedMessage
            defaultMessage="Impact assessment history"
            id="aJZvZV"
          />
          <ModalCloseButton
            backgroundColor="gray.100"
            top={8}
            right={8}
            size="sm"
          />
        </ModalHeader>
        <ModalBody paddingX={8} paddingY={0}>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th {...tableCellStyles}>
                    <FormattedMessage defaultMessage="Date" id="P7PLVj" />
                  </Th>
                  <Th {...tableCellStyles}>
                    <FormattedMessage defaultMessage="Sent to" id="juAYBw" />
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {requestsHistory.map((disruptionPack) => (
                  <Tr key={disruptionPack.disruptionPackId}>
                    <Td {...tableCellStyles}>
                      <FormattedTime
                        value={new Date(disruptionPack.createdAt)}
                        {...TIME_PRESET_COMPLETE_HOUR12}
                      />
                      {', '}
                      <FormattedDate
                        value={new Date(disruptionPack.createdAt)}
                        {...DATE_PRESET_SHORT_D_M_Y}
                      />
                    </Td>
                    <Td {...tableCellStyles}>
                      <Wrap spacing={1}>
                        {disruptionPack.recipientEmails.map(
                          (recipientEmail) => (
                            <WrapItem key={recipientEmail}>
                              <Tag
                                size="sm"
                                variant="solid"
                                backgroundColor="purple.700"
                              >
                                <TagLabel>{recipientEmail}</TagLabel>
                              </Tag>
                            </WrapItem>
                          )
                        )}
                      </Wrap>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter
          justifyContent="start"
          paddingX={8}
          paddingBottom={8}
          paddingTop={4}
        >
          <Button variant="solid" colorScheme="greenDark" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
