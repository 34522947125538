import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { ActiveVms } from '@webapp/bff/src/types/vms';
import { RefObject, useImperativeHandle } from 'react';
import { useHookFormSubmitAction } from '../../utils/form';

export default function StepBoardDetails({
  vms,
  saveVms,
  submitActionRef,
}: {
  vms?: ActiveVms;
  saveVms: (vms: Pick<ActiveVms, 'vmsName'>) => Promise<void>;
  submitActionRef: RefObject<() => Promise<void>>;
}) {
  const { formatMessage } = useIntl();
  const {
    register,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      vmsName: vms?.vmsName ?? '',
    },
  });
  const submitAction = useHookFormSubmitAction({
    handleSubmit,
    onSubmit: (formValues) => saveVms({ vmsName: formValues.vmsName }),
    showToastOnSubmitError: true,
  });
  useImperativeHandle(submitActionRef, () => submitAction);
  const vmsNameValue = watch('vmsName');
  return (
    <Box>
      <FormControl isInvalid={Boolean(errors.vmsName)}>
        <FormLabel fontSize="xs" fontWeight="normal">
          <FormattedMessage defaultMessage="Board name" id="VTALe0" />
        </FormLabel>
        <Input
          data-testid="create-vms-vms-name"
          size="sm"
          placeholder={formatMessage({
            defaultMessage: 'Give your board a name',
            id: 'dS8dbH',
            description: 'Board name input field placeholder',
          })}
          isDisabled={isSubmitting}
          maxLength={36}
          autoFocus
          {...register('vmsName', { required: true })}
        />
        {vmsNameValue.length > 36 - 16 && (
          <FormHelperText>
            <FormattedMessage
              defaultMessage="characters left: {charCount}"
              id="LobvrA"
              values={{
                charCount: 36 - vmsNameValue.length,
              }}
            />
          </FormHelperText>
        )}
        <FormErrorMessage>
          <FormattedMessage
            defaultMessage="Board name is required"
            id="5UTenb"
          />
        </FormErrorMessage>
      </FormControl>
    </Box>
  );
}
