import { Polyline } from '@react-google-maps/api';
import { getRouteColor } from '../../utilities/route-colors';
import { POLYLINE_ICONS } from '../../settings/google-maps';

export interface MapLayerRoutesProps {
  routes: {
    id: string;
    path: google.maps.LatLngLiteral[];
  }[];
}

export default function MapLayerRoutes({ routes }: MapLayerRoutesProps) {
  return routes.map((route, index) => {
    return (
      <Polyline
        key={route.id}
        options={{
          strokeColor: getRouteColor(index),
          icons: POLYLINE_ICONS,
          strokeOpacity: 0.6,
        }}
        path={route.path}
      />
    );
  });
}
