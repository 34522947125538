import {
  Box,
  Text,
  Flex,
  IconButton,
  Step,
  StepIndicator,
  StepStatus,
  Stepper,
  Circle,
  UseStepsReturn,
} from '@chakra-ui/react';
import { useCallback, useRef, useEffect } from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';

const activeInnerCircleSize = 8;
const inCompleteCircleSize = 16;
const activeCircleSize = 28;
const stepLength = 172;
const viewMarginLeft = (activeCircleSize - inCompleteCircleSize) / 2;

export interface ProgressStepsProps {
  steps: {
    name: string;
  }[];
  stepsManager: UseStepsReturn;
  displayStepsCount?: number;
  onStepExit?: () => void | Promise<void>;
  isDisabled?: boolean;
}

const animatedCircle = (
  <div data-ignore-visual-test>
    <motion.div
      transition={{
        ease: 'easeInOut',
        duration: 1,
        repeat: Infinity,
        repeatType: 'mirror',
      }}
      initial={{
        scale: 0.75,
      }}
      animate={{
        scale: 1,
      }}
    >
      <Circle
        zIndex={1}
        size={`${activeCircleSize}px`}
        bg="green.400"
        boxShadow="md"
      >
        <Circle size={`${activeInnerCircleSize}px`} bg="white" />
      </Circle>
    </motion.div>
  </div>
);

export default function ProgressSteps({
  steps,
  stepsManager,
  displayStepsCount = 2,
  isDisabled = false,
}: ProgressStepsProps) {
  const { activeStep, setActiveStep } = stepsManager;
  const progressRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (progressRef.current) {
      progressRef.current.style.left = `${
        -(stepLength + inCompleteCircleSize) * activeStep
      }px`;
    }
  }, [activeStep]);
  const maxStepperWidth =
    (stepLength + inCompleteCircleSize) * (steps.length - 1) +
    inCompleteCircleSize;
  const findPreviousStep = useCallback(
    (step: number) => (step > 0 ? step - 1 : 0),
    []
  );
  const goPrevious = async () => {
    setActiveStep((currentStep) => findPreviousStep(currentStep));
  };
  const onClickStep = async (stepIndex: number) => {
    setActiveStep(stepIndex);
  };
  return (
    <Flex>
      {activeStep > 0 && (
        <IconButton
          onClick={goPrevious}
          aria-label="Previous step"
          size="sm"
          variant="outline"
          isRound
          icon={<ArrowBackIcon />}
          isDisabled={isDisabled}
        />
      )}
      <Box
        position="relative"
        width="full"
        pl={`${viewMarginLeft}px`}
        pt="8px"
        ml={2}
        height="52px"
        overflow="hidden"
      >
        <Box
          position="relative"
          width={`${maxStepperWidth}px`}
          ref={progressRef}
          transition="left 0.5s ease-out"
        >
          <Stepper size="xs" index={activeStep} gap="0" colorScheme="green">
            {steps.map((step, index) => (
              <Step
                key={step.name}
                // @ts-ignore - Cannot find gap attribute to pass the typescript, but needed
                gap="0"
                onClick={isDisabled ? undefined : () => onClickStep(index)}
                transition="opacity 0.5s ease-out"
                opacity={
                  index < activeStep ||
                  index > activeStep + (displayStepsCount - 1)
                    ? 0
                    : 1
                }
              >
                <StepIndicator
                  borderWidth={0}
                  cursor={index > activeStep ? 'pointer' : ''}
                >
                  <StepStatus
                    active={animatedCircle}
                    complete={animatedCircle}
                  />
                </StepIndicator>
                <Box
                  w="full"
                  h="1px"
                  flex="1"
                  bg={
                    index === activeStep && index < steps.length - 2
                      ? 'green.400'
                      : 'gray.200'
                  }
                />
              </Step>
            ))}
          </Stepper>
          <Flex position="relative">
            {steps.map((step, index) => (
              <Flex
                key={step.name}
                width={stepLength}
                position="absolute"
                left={`${
                  (stepLength + inCompleteCircleSize) * index -
                  (index <= activeStep ? viewMarginLeft : 0)
                }px`}
                transition="opacity 0.5s ease-out"
                opacity={
                  index < activeStep ||
                  index > activeStep + (displayStepsCount - 1)
                    ? 0
                    : 1
                }
              >
                <Text
                  mt={2}
                  minWidth="38px"
                  fontSize="xs"
                  color={index > activeStep ? 'gray.400' : 'gray.600'}
                >
                  Step {index + 1}:
                </Text>
                <Text
                  ml={1}
                  isTruncated
                  mt={2}
                  fontSize="xs"
                  fontWeight="700"
                  color={index > activeStep ? 'gray.500' : 'gray.700'}
                >
                  {step.name}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}
