import { Box, Center, Flex, Heading, Select, Spinner } from '@chakra-ui/react';
import { sortedWeekDays, WeekDay, WeekDays } from '@lib/dates';
import SelectionGroup from 'design-system/molecules/selection-group';
import {
  FormattedMessage,
  useIntl,
  defineMessage,
  MessageDescriptor,
} from 'react-intl';
import React from 'react';
import CalendarIcon from '../../svg/calendar-line.svg?react';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import DailyBaselineChart from './DailyBaselineChart';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import useBaselineProfileData from '../../data/useBaselineProfileData';
import LayoutPanel from '../LayoutPanel';
import { colorsSortedByKey } from '../../utils/routes';
import { BaselineDetailsPanelLayout } from './BaselineDetailsPanelLayout';

const WeekDayMessages: Record<WeekDay, MessageDescriptor> = {
  MON: defineMessage({
    defaultMessage: 'Monday',
    id: 'oS+73O',
    description: 'Monday',
  }),
  TUE: defineMessage({
    defaultMessage: 'Tuesday',
    id: '4yZnhu',
    description: 'Tuesday',
  }),
  WED: defineMessage({
    defaultMessage: 'Wednesday',
    id: 'QTjbYN',
    description: 'Wednesday',
  }),
  THU: defineMessage({
    defaultMessage: 'Thursday',
    id: 'iQbtT5',
    description: 'Thursday',
  }),
  FRI: defineMessage({
    defaultMessage: 'Friday',
    id: 'qHAKG3',
    description: 'Friday',
  }),
  SAT: defineMessage({
    defaultMessage: 'Saturday',
    id: 'DvPWyz',
    description: 'Saturday',
  }),
  SUN: defineMessage({
    defaultMessage: 'Sunday',
    id: '1mW5DK',
    description: 'Sunday',
  }),
};

export default function DailyBaselinePanel({
  routes,
  baselineId,
  baselineName,
  siteTimeZone,
}: {
  routes: { name: string; id: string }[];
  baselineId: string;
  baselineName: string;
  siteTimeZone: string;
}) {
  const { track } = useAnalytics();
  const { formatMessage } = useIntl();
  const [selectedDays, setSelectedDays] = React.useState<WeekDay[]>(
    sortedWeekDays(1)
  );
  const [selectedRoute, setSelectedRoute] = React.useState<string>(
    routes[0]?.id
  );
  const chartDataHandler = useBaselineProfileData({
    baselineId,
    routeId: selectedRoute,
    daysOfWeek: sortedWeekDays(1),
    timezone: siteTimeZone,
  });
  const colorsByDay = colorsSortedByKey<Record<string, WeekDay>>(
    WeekDays.map((day) => ({ key: day })),
    'key'
  );
  const isFetching = chartDataHandler.isValidating;
  const hasData =
    !hasDataHandlerFeedBack([chartDataHandler]) && chartDataHandler.data;

  return (
    <BaselineDetailsPanelLayout
      title={formatMessage({
        defaultMessage: 'Baseline by day of week',
        id: 'Zcjy7Z',
      })}
      leftPanel={
        <SelectionGroup
          title={<FormattedMessage defaultMessage="Day of week" id="gD6r6x" />}
          titleIcon={<CalendarIcon />}
          options={sortedWeekDays(1).map((day) => ({
            id: day,
            label: WeekDayMessages[day],
            color: colorsByDay[day],
          }))}
          selectedOptions={selectedDays}
          onSelectedOptionsChange={setSelectedDays}
        />
      }
      rightPanel={
        isFetching && !hasData ? (
          <Flex
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            p={10}
          >
            <Spinner variant="mooven" size="xl" />
          </Flex>
        ) : (
          <Box w="full" position="relative">
            <Flex alignItems="center" w="full" pl={4}>
              <Heading size="md" mr={2}>
                Route:
              </Heading>
              <Box>
                <Select
                  size="sm"
                  onChange={(ev) => {
                    setSelectedRoute(ev.target.value);
                    track('Daily baseline panel', {
                      action: 'route selected',
                      label: ev.target.value,
                      referrer: 'Daily baseline panel',
                      feature: 'Baselines',
                    });
                  }}
                >
                  {routes.map((r) => (
                    <option value={r.id}>{r.name}</option>
                  ))}
                </Select>
              </Box>
            </Flex>
            {!hasData && (
              <LayoutPanel data-testid="daily-baseline-chart-panel-loading">
                <Center height="400px">
                  <DataHandlerFeedback dataHandlersParam={[chartDataHandler]} />
                </Center>
              </LayoutPanel>
            )}
            {hasData && (
              <DailyBaselineChart
                routeName={routes.find((r) => r.id === selectedRoute)!.name}
                colorsByDay={colorsByDay}
                daysOfWeek={selectedDays}
                baselineName={baselineName}
                data={chartDataHandler
                  .data!.filter(
                    (item) =>
                      selectedDays.includes(item.day) &&
                      item.routeId === selectedRoute
                  )
                  .map((item) => ({
                    name: item.day,
                    label: formatMessage(WeekDayMessages[item.day]),
                    items: item.data.map((d) => ({
                      date: d.minsFromStartOfDay,
                      value: d.value,
                    })),
                  }))}
              />
            )}
          </Box>
        )
      }
    />
  );
}
