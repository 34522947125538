import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalOverlayProps,
  ModalProps,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';

const padding = {
  base: 4,
  md: 8,
  xl: 12,
};
export function FullScreenModal({
  header,
  children,
  footer,
  isOpen,
  onClose,
  modalProps,
  modalOverelayProps,
  modalContentProps,
  modalHeaderProps,
  modalBodyProps,
  modalFooterProps,
}: {
  header: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  isOpen: ModalProps['isOpen'];
  onClose: ModalProps['onClose'];
  modalProps?: Omit<ModalProps, 'isOpen' | 'onClose' | 'children'>;
  modalOverelayProps?: ModalOverlayProps;
  modalContentProps?: Omit<ModalContentProps, 'children'>;
  modalHeaderProps?: Omit<ModalHeaderProps, 'children'>;
  modalBodyProps?: Omit<ModalBodyProps, 'children'>;
  modalFooterProps?: Omit<ModalFooterProps, 'children'>;
}) {
  return (
    <Modal
      size="full"
      scrollBehavior="inside"
      {...(modalProps ?? {})}
      onClose={onClose}
      isOpen={isOpen}
    >
      <ModalOverlay
        backgroundColor="whiteAlpha.800"
        {...(modalOverelayProps ?? {})}
      />
      <ModalContent backgroundColor="gray.50" {...(modalContentProps ?? {})}>
        <ModalHeader
          backgroundColor="rgba(255, 255, 255, 0.9)"
          backdropFilter="blur(4px)"
          padding={padding}
          boxShadow="soft"
          {...(modalHeaderProps ?? {})}
        >
          <Container maxWidth="container.xl" padding="0" position="relative">
            {header}
          </Container>
          <ModalCloseButton size="lg" />
        </ModalHeader>
        <ModalBody padding={padding} {...(modalBodyProps ?? {})}>
          <Container maxWidth="container.xl" padding="0">
            {children}
          </Container>
        </ModalBody>
        <ModalFooter
          paddingX={padding}
          justifyContent="start"
          backgroundColor="rgba(255, 255, 255, 0.9)"
          backdropFilter="blur(4px)"
          borderTop="1px"
          borderTopColor="gray.200"
          {...(modalFooterProps ?? {})}
        >
          <Container maxWidth="container.xl" padding="0">
            {footer || (
              <Button onClick={onClose} variant="solid" colorScheme="greenDark">
                <FormattedMessage defaultMessage="Close" id="rbrahO" />
              </Button>
            )}
          </Container>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
