import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  VStack,
} from '@chakra-ui/react';
import { RefObject, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import BaselineForm from '../Baselines/BaselineForm';
import useSiteBaselinesData from '../../data/useSiteBaselinesData';
import conesBg from '../../svg/cones.svg?inline';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import { FEATURE_SITE_IMPACT_UI_BASELINES_PHASE_2 } from '../../constants/features';

export default function StepBaseline({
  submitActionRef,
  siteId,
}: {
  submitActionRef: RefObject<() => Promise<void>>;
  siteId: string;
}) {
  const featureBaselinesPhase2 = useFeatureSwitch(
    FEATURE_SITE_IMPACT_UI_BASELINES_PHASE_2
  );
  const isFeatureBaselinesPhase2Enabled =
    featureBaselinesPhase2 === FeatureSwitchState.ON;
  const { data, requestBaselineCreation } = useSiteBaselinesData(siteId);
  const baselineProps = data?.baselineRequests?.[0];
  const [isFormActive, setIsFormActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { track } = useAnalytics();

  return (
    <VStack spacing={8} alignItems="stretch">
      <VStack spacing={4} alignItems="stretch">
        <VStack spacing={2} alignItems="stretch">
          <Box>
            <FormattedMessage
              defaultMessage="Is a baseline required to measure performance against?"
              id="IRTAL3"
            />
          </Box>
          <FormControl>
            <HStack spacing={2} alignItems="center">
              <Switch
                isChecked={isFormActive}
                isDisabled={isSubmitting}
                size="md"
                data-testid="baseline-create-switch"
                colorScheme="green"
                onChange={(e) => {
                  setIsFormActive(e.target.checked);
                  track('Baseline Create Switch Toggled', {
                    referrer: 'Site Create Wizard',
                    siteId,
                    active: e.target.checked,
                    feature: 'Baselines',
                  });
                }}
              />
              <FormLabel fontWeight="normal" mb={0}>
                <FormattedMessage defaultMessage="Yes" id="a5msuh" />
              </FormLabel>
            </HStack>
          </FormControl>
        </VStack>
        <Box
          background={`bottom right/auto no-repeat url(${conesBg})`}
          backgroundColor="yellow.100"
          borderRadius="3xl"
          padding="6"
          paddingRight="60px"
          paddingBottom="100px"
        >
          <Box fontSize="sm" fontWeight="bold" mb={1}>
            <FormattedMessage
              id="3BOufy"
              defaultMessage="Baselines help you benchmark"
            />
          </Box>
          <Box fontSize="xs">
            <FormattedMessage
              id="lewIC2"
              defaultMessage="Baselines allow you to compare the performance of your site with either a defined period in time or a rolling average of the prior weeks. This can give a good indication of the impact of your work compared to a time prior to work occurring."
            />
          </Box>
        </Box>
      </VStack>
      <Box>
        <BaselineForm
          key={baselineProps?.jobId ?? 'new'}
          baselineCreationTypes={
            data?.baselineCreationTypes ?? ['SIMPLE_RANGE_BASELINE']
          }
          defaultValues={
            baselineProps && {
              name: baselineProps.name,
              description: baselineProps.description,
              startDate: baselineProps.config?.startDate,
              endDate: baselineProps.config?.endDate,
              baselineType: baselineProps.baselineType,
            }
          }
          submitActionRef={submitActionRef}
          onSubmit={async (formValues) => {
            setIsSubmitting(true);
            try {
              await requestBaselineCreation(formValues);
            } finally {
              setIsSubmitting(false);
            }
          }}
          isDisabled={!isFormActive}
          minDate={moment().format('YYYY-MM-DD')}
          isFeatureBaselinesPhase2Enabled={isFeatureBaselinesPhase2Enabled}
          variant="narrow"
        />
      </Box>
    </VStack>
  );
}
