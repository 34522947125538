import { useState } from 'react';
import {
  DisruptionPackResponse,
  DisruptionPackType,
} from '@webapp/bff/src/types/report';
import { QuestionIcon } from '@chakra-ui/icons';
import {
  useDisclosure,
  Text,
  Tooltip,
  Stack,
  InputGroup,
  Input,
  InputRightAddon,
  Box,
  Button,
  Alert,
  AlertIcon,
  VStack,
  AlertTitle,
  AlertDescription,
  Checkbox,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { emailValidator } from '../../utils/email';
import AlertBox from '../AlertBox';
import { ImpactAssessmentHistoryModal } from './ImpactAssessmentHistoryModal';
import { useActionWithFeedback } from '../../hooks/useActionWithFeedback';

export interface ImpactAssessmentRecipientSectionProps {
  currentUserEmail?: string;
  requestsHistory: DisruptionPackResponse[];
  impactAssessmentTypeDefaultValue?: DisruptionPackType[];
  showImpactAssessmentTypeOptions?: boolean;
  requestImpactAssessment: (requestParams: {
    recipientEmails: string[];
    impactAssessmentType: DisruptionPackType[];
  }) => Promise<void>;
  requestImpactAssessmentTrackProps?: {
    referrer: string;
    siteId: string;
    feature?: string;
  };
  hasDisruptionArea: boolean;
  plannedDates: {
    startDate?: string;
    endDate?: string;
  };
  goToDisruptionArea: () => void;
  goToSetDates: () => void;
}

export default function ImpactAssessmentRecipientsSection({
  currentUserEmail,
  requestsHistory,
  impactAssessmentTypeDefaultValue,
  showImpactAssessmentTypeOptions = false,
  requestImpactAssessment,
  requestImpactAssessmentTrackProps,
  hasDisruptionArea,
  plannedDates,
  goToDisruptionArea,
  goToSetDates,
}: ImpactAssessmentRecipientSectionProps) {
  const { formatMessage } = useIntl();
  const requestForm = useForm<{
    recipientEmails: string[];
    impactAssessmentType: DisruptionPackType[];
  }>({
    defaultValues: {
      recipientEmails: currentUserEmail ? [currentUserEmail] : ([] as string[]),
      impactAssessmentType: impactAssessmentTypeDefaultValue ?? [
        'PUBLIC_TRANSPORT_AND_BUSINESSES',
      ],
    },
  });
  const recipientEmailsValue = requestForm.watch('recipientEmails');
  const historyModal = useDisclosure();
  const pendingRequest = requestsHistory.find(
    (pack) => pack.status === 'PENDING'
  );
  const hasPlannedDates = Boolean(
    plannedDates.startDate && plannedDates.endDate
  );
  const areDatesFuture =
    hasPlannedDates && new Date(plannedDates.startDate!) > new Date();
  const isImpactAssessmentInputValid =
    hasDisruptionArea && hasPlannedDates && recipientEmailsValue.length > 0;

  const handleEmailAdded = (newEmail) => {
    const uniqueRecipientEmails = new Set([
      ...requestForm.getValues('recipientEmails'),
      newEmail,
    ]);

    requestForm.setValue('recipientEmails', Array.from(uniqueRecipientEmails));
  };
  const handleEmailRemoved = (removedRecipientEmail: string) => {
    requestForm.setValue(
      'recipientEmails',
      requestForm
        .getValues('recipientEmails')
        .filter((recipientEmail) => recipientEmail !== removedRecipientEmail)
    );
  };

  const {
    performAction: submitImpactAssessmentRequest,
    isPerformingAction: isSubmitting,
  } = useActionWithFeedback({
    action: requestForm.handleSubmit(requestImpactAssessment),
    trackEvent: requestImpactAssessmentTrackProps && {
      name: 'Disruption pack created',
      data: {
        ...requestImpactAssessmentTrackProps,
        ...requestForm.getValues(),
      },
    },
    successMessage: () => ({
      title: formatMessage({
        defaultMessage: 'Impact assessment requested',
        id: '60cv1O',
      }),
      description: requestForm
        .getValues()
        .impactAssessmentType.every(
          (requestedType) => requestedType === 'PUBLIC_TRANSPORT_AND_BUSINESSES'
        )
        ? formatMessage({
            defaultMessage:
              'It should be in your inbox within the next 10 minutes.',
            id: '821pMU',
          })
        : formatMessage({
            defaultMessage:
              'A Customer Success Manager will be in contact with you.',
            id: 'jGIA7Q',
          }),
    }),
  });

  return (
    <>
      <Stack spacing={4}>
        <Text marginBottom={0} fontSize="xs">
          <FormattedMessage
            defaultMessage="Send to team members who would find value in receiving a disruption pack. Roles that are useful to include are those in Communications, Planning, Delivery, Traffic management and Client liaison. All recipients will be also be invited to your site in Mooven."
            id="rtD18z"
          />
        </Text>
        <Stack spacing={1}>
          <Text marginBottom={0} fontSize="xs">
            <FormattedMessage
              defaultMessage="Add recipients of your impact assessment"
              id="1IoEh3"
            />
          </Text>
          <AddEmailInput
            onEmailAdded={handleEmailAdded}
            isDisabled={!!pendingRequest}
          />
        </Stack>
        {pendingRequest && pendingRequest.recipientEmails.length > 0 && (
          <Alert status="info">
            <AlertIcon />
            <VStack alignItems="left" spacing={1}>
              <AlertTitle fontSize="xs" lineHeight="4">
                <FormattedMessage
                  defaultMessage="Sending impact assessment to"
                  id="mvpyWI"
                />
              </AlertTitle>
              <AlertDescription fontSize="xs" lineHeight="4">
                <FormattedMessage
                  defaultMessage="{alertRecipientEmails}{br}
                  It should arrive within the next 10 minutes"
                  id="+nG2gZ"
                  values={{
                    br: <br />,
                    alertRecipientEmails:
                      pendingRequest.recipientEmails.join(', '),
                  }}
                />
              </AlertDescription>
            </VStack>
          </Alert>
        )}
        {!pendingRequest && recipientEmailsValue.length > 0 && (
          <Stack spacing={2} align="start">
            {recipientEmailsValue.map((recipientEmail) => (
              <Tag
                key={recipientEmail}
                size="sm"
                variant="solid"
                backgroundColor="purple.700"
              >
                <TagLabel>{recipientEmail}</TagLabel>
                <TagCloseButton
                  onClick={() => handleEmailRemoved(recipientEmail)}
                />
              </Tag>
            ))}
          </Stack>
        )}
        {hasPlannedDates && hasDisruptionArea && (
          <>
            {showImpactAssessmentTypeOptions && (
              <VStack alignItems="left" spacing={2}>
                <Text mb={0} fontSize="sm" fontWeight="bold" lineHeight={5}>
                  <FormattedMessage
                    defaultMessage="Included analysis"
                    id="kcD9Sd"
                  />
                </Text>
                {[
                  {
                    value: 'PUBLIC_TRANSPORT_AND_BUSINESSES',
                    label: (
                      <FormattedMessage
                        defaultMessage="Public transport and businesses"
                        id="5yOfb+"
                      />
                    ),
                  },
                  {
                    value: 'TRAFFIC_VOLUME',
                    label: (
                      <FormattedMessage
                        defaultMessage="Add traffic volume assessment"
                        id="SxYD9l"
                      />
                    ),
                  },
                  {
                    value: 'DETOUR',
                    label: (
                      <FormattedMessage
                        defaultMessage="Add detour analysis"
                        id="qi6Raa"
                      />
                    ),
                  },
                  {
                    value: 'SPEED_SAFETY',
                    label: (
                      <FormattedMessage
                        defaultMessage="Add speed and safety analysis"
                        id="OaozJC"
                      />
                    ),
                  },
                  {
                    value: 'JOURNEY_TIME',
                    label: (
                      <FormattedMessage
                        defaultMessage="Add journey time assessment"
                        id="enQrYV"
                      />
                    ),
                  },
                ].map((option) => (
                  <Checkbox
                    key={option.value}
                    value={option.value}
                    size="lg"
                    readOnly={
                      option.value === 'PUBLIC_TRANSPORT_AND_BUSINESSES'
                    }
                    {...requestForm.register('impactAssessmentType', {
                      disabled:
                        !isImpactAssessmentInputValid || !!pendingRequest,
                    })}
                  >
                    <Box fontSize="xs">{option.label}</Box>
                  </Checkbox>
                ))}
              </VStack>
            )}
            <HStack spacing="10px">
              <Button
                variant="solid"
                colorScheme="greenDark"
                onClick={submitImpactAssessmentRequest}
                isDisabled={!isImpactAssessmentInputValid || !!pendingRequest}
                isLoading={isSubmitting}
              >
                <FormattedMessage
                  defaultMessage="Order impact assessment"
                  id="4LAPfV"
                />
              </Button>
              <Tooltip
                hasArrow
                label={formatMessage({
                  defaultMessage:
                    'Public transport and business impact assessment will be emailed to the recipients above. If additional analysis is requested, we will contact you for further details.',
                  id: '/mTy+/',
                })}
              >
                <QuestionIcon color="gray.700" width={6} height={6} />
              </Tooltip>
            </HStack>
          </>
        )}
        {requestsHistory.length > 0 && (
          <Box>
            <Button variant="outline" size="xs" onClick={historyModal.onOpen}>
              <FormattedMessage defaultMessage="History" id="djJp6c" />
            </Button>
          </Box>
        )}
        {!hasDisruptionArea && (
          <AlertBox
            message={
              <FormattedMessage
                defaultMessage="In order to receive an impact assessment you'll need to add a disruption area."
                id="xVCV7U"
              />
            }
            cta={{
              label: (
                <FormattedMessage
                  defaultMessage="Add a disruption area"
                  id="U3yKPy"
                />
              ),
              action: goToDisruptionArea,
            }}
          />
        )}
        {!hasPlannedDates && (
          <AlertBox
            message={
              <FormattedMessage
                defaultMessage="In order to receive an impact assessment that includes public transport disruption we require a planned date for works."
                id="vfDd7P"
              />
            }
            cta={{
              label: (
                <FormattedMessage
                  defaultMessage="Add planned dates"
                  id="1iEwWt"
                />
              ),
              action: goToSetDates,
            }}
          />
        )}
        {hasPlannedDates && !areDatesFuture && (
          <AlertBox
            message={
              <FormattedMessage
                defaultMessage="In order to receive an impact assessment that includes public transport disruption we require future dates."
                id="j73Yzj"
              />
            }
            cta={{
              label: (
                <FormattedMessage
                  defaultMessage="Update planned dates"
                  id="xsM5lf"
                />
              ),
              action: goToSetDates,
            }}
          />
        )}
      </Stack>
      <ImpactAssessmentHistoryModal
        isOpen={historyModal.isOpen}
        onClose={historyModal.onClose}
        requestsHistory={requestsHistory}
      />
    </>
  );
}

function AddEmailInput({
  onEmailAdded,
  isDisabled,
}: {
  onEmailAdded: (newEmail: string) => void;
  isDisabled: boolean;
}) {
  const { formatMessage } = useIntl();
  const [currentRecipientEmail, setCurrentRecipientEmail] = useState('');
  const isCurrentRecipientEmailValid =
    currentRecipientEmail.length > 0 && emailValidator([currentRecipientEmail]);
  const handleCurrentRecipientEmailSubmit = () => {
    if (isCurrentRecipientEmailValid) {
      onEmailAdded(currentRecipientEmail.trim().toLowerCase());
      setCurrentRecipientEmail('');
    }
  };
  return (
    <InputGroup>
      <Input
        disabled={isDisabled}
        type="email"
        value={currentRecipientEmail}
        onChange={(event) => setCurrentRecipientEmail(event.target.value)}
        placeholder={formatMessage({
          defaultMessage: 'email address',
          id: 'eGwKE1',
        })}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleCurrentRecipientEmailSubmit();
          }
        }}
      />
      <InputRightAddon
        as={Button}
        type="button"
        isDisabled={!isCurrentRecipientEmailValid}
        onClick={handleCurrentRecipientEmailSubmit}
      >
        +
      </InputRightAddon>
    </InputGroup>
  );
}
