import { FullScreenModal } from 'design-system/molecules/modal';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Button,
  Flex,
  VStack,
} from '@chakra-ui/react';

import { FormattedMessage, useIntl } from 'react-intl';
import { BaselineConfig } from '@webapp/bff/src/types/baseline';
import DailyBaselinePanel from './DailyBaselinePanel';
import { SiteInfo } from '../../data/useSiteData';
import { DATE_PRESET_SHORT_FULL_YEAR } from '../../utils/dateUtils';
import { Route } from '../../types/route';
import BaselineJourneyTimePanel from './BaselineJourneyTimePanel';

export default function BaselineChartsModal({
  siteProps,
  routes,
  baseline,
  isOpen,
  onClose,
  onEditClick,
}: {
  siteProps?: Pick<SiteInfo, 'siteId' | 'siteName' | 'siteTimeZone'>;
  routes?: Route[];
  baseline?: BaselineConfig;
  isOpen?: boolean;
  onClose?: () => void;
  onEditClick?: () => void;
}) {
  const { formatDate } = useIntl();
  if (!baseline || !siteProps || !routes) {
    return null;
  }
  return (
    <FullScreenModal
      header={
        <Flex justifyContent="space-between" alignItems="flex-end">
          <VStack spacing={0} alignItems="flex-start">
            <Breadcrumb>
              <BreadcrumbItem fontWeight="600" fontSize="xs">
                <BreadcrumbLink onClick={onClose}>
                  {siteProps.siteName}
                </BreadcrumbLink>
                <BreadcrumbSeparator />
              </BreadcrumbItem>
            </Breadcrumb>
            <Box fontSize="2xl" fontWeight="bold">
              {baseline.name}
            </Box>
            {baseline.description && (
              <Box fontSize="xs" fontWeight="normal">
                {baseline.description}
              </Box>
            )}
            {baseline.config && (
              <Box fontSize="xs">
                {formatDate(
                  new Date(baseline.config?.startDate),
                  DATE_PRESET_SHORT_FULL_YEAR
                )}{' '}
                -{' '}
                {formatDate(
                  new Date(baseline.config?.endDate),
                  DATE_PRESET_SHORT_FULL_YEAR
                )}
              </Box>
            )}
          </VStack>
          <Button variant="outline" size="sm" onClick={onEditClick}>
            <FormattedMessage defaultMessage="Edit baseline" id="22rAaf" />
          </Button>
        </Flex>
      }
      isOpen={isOpen ?? false}
      onClose={onClose ?? (() => {})}
    >
      <VStack spacing={12} alignItems="stretch">
        <BaselineJourneyTimePanel baselineId={baseline.baselineId} />
        <DailyBaselinePanel
          routes={
            routes?.map((r) => ({
              name: r.name,
              id: r.routeId.toString(),
            })) ?? []
          }
          siteTimeZone={siteProps.siteTimeZone}
          baselineId={baseline.baselineId}
          baselineName={baseline.name ?? 'Baseline'}
        />
        {/* TODO: Add baseline journey time chart here */}
      </VStack>
    </FullScreenModal>
  );
}
