import { useState, createRef } from 'react';
import { useSteps } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { LocalDate } from '@webapp/bff/src/types/dates';
import StepCreate from './StepCreate';
import useMapPositionManager from './useMapPositionManager';
import CreateSiteMap from './CreateSiteMap';
import { SiteType } from '../CreateSiteTrigger/types';
import { pathSitesEdit } from '../../constants/path';
import { SiteFormSections } from '../SiteEdit/EditSite';
import {
  WorkAreasEditorList,
  useWorkAreasEditorManager,
} from '../WorkAreasEditor';
import useWorkAreasData from '../../data/useWorkAreasData';
import OccupationDatesEditor from '../OccupationDatesEditor';
import useSiteData from '../../data/useSiteData';
import SiteDetailStep from './SiteDetailStep';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { RoutesEditorSection, useRoutesEditorManager } from '../RoutesEditor';
import useRoutesData from '../../data/useRoutesData';
import MonitoringSection from './MonitoringSection';
import useSitesData from '../../data/useSitesData';
import { useSitePermissions } from '../../hooks/useSitePermissions';
import LayoutMultiPanel from '../LayoutMultiPanel';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import { FEATURE_SITE_IMPACT_UI_BASELINES } from '../../constants/features';
import StepBaseline from './StepBaseline';
import WizardLayout, { PreventWizardStepExit } from '../WizardLayout';
import { catchValidationErrorAndPreventStepExit } from '../../utils/form';

enum CreateSiteStepsOriginal {
  CREATE,
  DATES,
  WORK_AREAS,
  ROUTES,
  MONITORING,
}
enum CreateSiteStepsFeatureFlagBaseline {
  CREATE,
  DATES,
  WORK_AREAS,
  ROUTES,
  BASELINE,
  MONITORING,
}

export default function SiteCreate() {
  const { insightId, siteType: siteTypeFragment } = useParams();
  if (!insightId) {
    throw new Error('Insight ID is required');
  }
  const siteType = fragmentToSiteType(siteTypeFragment);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const stepsManager = useSteps();
  const mapPositionManager = useMapPositionManager();
  const [currentSiteId, setCurrentSiteId] = useState<string>();
  const { checkSitePermission } = useSitePermissions(
    insightId && currentSiteId
      ? {
          insightId,
          siteId: currentSiteId,
        }
      : null
  );
  const goToSitePage = (siteId: string) => {
    navigate(
      {
        pathname: pathSitesEdit(insightId),
        search: `?siteId=${siteId}`,
      },
      {
        state: {
          siteFormSection: SiteFormSections.ROUTES,
          mapCenter: mapPositionManager.mapCenter,
        },
      }
    );
  };
  const siteCreatedHandler = (siteId: string) => {
    if (siteType === SiteType.PROJECT_SITE) {
      setCurrentSiteId(siteId);
    } else {
      goToSitePage(siteId);
    }
  };
  const { track } = useAnalytics();

  const workAreasDataHandler = useWorkAreasData(currentSiteId);
  const workAreasEditorManager = useWorkAreasEditorManager(
    workAreasDataHandler.data
  );

  const currentSiteDataHandler = useSiteData(
    !currentSiteId
      ? null
      : {
          orgId: insightId,
          siteId: currentSiteId,
        }
  );

  const routesDataHandler = useRoutesData(
    currentSiteId !== undefined ? parseInt(currentSiteId, 10) : undefined
  );
  const baselineSubmitActionRef = createRef<() => Promise<void>>();
  const siteDetailAubmitActionRef =
    createRef<() => Promise<void | PreventWizardStepExit>>();
  const routesEditorManager = useRoutesEditorManager();

  const { startSiteMonitoring, stopSiteMonitoring } = useSitesData(insightId);

  const featureBaselinesUI = useFeatureSwitch(FEATURE_SITE_IMPACT_UI_BASELINES);
  const isFeatureBaselineUI = featureBaselinesUI === FeatureSwitchState.ON;
  const CreateSiteSteps = isFeatureBaselineUI
    ? CreateSiteStepsFeatureFlagBaseline
    : CreateSiteStepsOriginal;

  const stepsOriginal = [
    {
      name: formatMessage({
        defaultMessage: 'Name & location',
        id: 'lWJcAl',
      }),
      title: formatMessage({
        defaultMessage: 'Site details',
        id: '3sIiZI',
      }),
      content: (
        <SiteDetailStep
          insightId={insightId!}
          siteId={currentSiteId}
          onSiteCreated={siteCreatedHandler}
          siteName={currentSiteDataHandler.data?.siteName ?? ''}
          mapPositionManager={mapPositionManager}
          imperativeSaveRef={siteDetailAubmitActionRef}
        />
      ),
      onStepWillExit: () => siteDetailAubmitActionRef.current?.(),
    },
    {
      name: formatMessage({
        defaultMessage: 'Occupation dates',
        id: 'hWl4QU',
      }),
      title: formatMessage({
        defaultMessage: 'Define occupation dates',
        id: 'Hpfw8k',
      }),
      content: (
        <OccupationDatesEditor
          occupationDates={{
            startDate: currentSiteDataHandler.data?.occupationStartDate,
            endDate: currentSiteDataHandler.data?.occupationEndDate,
          }}
          updateOccupationDates={async ({ startDate, endDate }) => {
            if (
              currentSiteDataHandler.data?.occupationStartDate ||
              currentSiteDataHandler.data?.occupationEndDate
            ) {
              track('Occupation dates updated', {
                referrer: 'Create Site',
                siteId: Number(currentSiteId),
                startDate,
                endDate,
              });
            } else {
              track('Occupation dates set', {
                referrer: 'Create Site',
                siteId: Number(currentSiteId),
                startDate,
                endDate,
              });
            }
            await currentSiteDataHandler.updateSiteOccupationDates({
              startDate: startDate as LocalDate,
              endDate: endDate as LocalDate,
            });
          }}
        />
      ),
    },
    {
      name: formatMessage({
        defaultMessage: 'Work area',
        id: 'Edr2gU',
      }),
      title: formatMessage({
        defaultMessage: 'Define work areas',
        id: '99Cnzu',
      }),
      content: (
        <WorkAreasEditorList
          workAreas={workAreasDataHandler.data || []}
          workAreasEditorManager={workAreasEditorManager}
          createWorkArea={workAreasDataHandler.createWorkArea}
          editWorkArea={workAreasDataHandler.editWorkArea}
          deleteWorkArea={workAreasDataHandler.deleteWorkArea}
        />
      ),
      onStepWillExit: () => {
        if (workAreasEditorManager.polygonCreatorManager.isCreating) {
          workAreasEditorManager.polygonCreatorManager.stopCreating();
        }
      },
    },
    {
      name: formatMessage({
        defaultMessage: 'Routes',
        id: 'eyx5By',
      }),
      title: formatMessage({
        defaultMessage: 'Add routes to your site',
        id: 'oTDRfm',
      }),
      content: (
        <RoutesEditorSection
          canEditRoutes={checkSitePermission('CanEditSiteRoutes')}
          routes={routesDataHandler.data}
          createRoute={routesDataHandler.createRoute}
          editRoute={routesDataHandler.editRoute}
          deleteRoute={routesDataHandler.deleteRoute}
          routesEditorManager={routesEditorManager}
        />
      ),
    },
    {
      name: formatMessage({
        defaultMessage: 'Monitoring',
        id: '1P6GMj',
      }),
      title: formatMessage({
        defaultMessage: 'Turn on monitoring?',
        id: 'fe1rQk',
      }),
      content: (
        <MonitoringSection
          hasRoutes={!!routesDataHandler.data?.length}
          isMonitoring={!!currentSiteDataHandler.data?.active}
          startMonitoring={async () => {
            await startSiteMonitoring(Number(currentSiteId));
          }}
          stopMonitoring={async () => {
            await stopSiteMonitoring(Number(currentSiteId));
          }}
          goToRoutes={() => {
            stepsManager.setActiveStep(CreateSiteSteps.ROUTES);
          }}
        />
      ),
    },
  ];
  const stepBaseline = {
    name: formatMessage({
      defaultMessage: 'Baseline',
      id: 'QFTqtX',
    }),
    title: formatMessage({
      defaultMessage: 'Define baseline',
      id: 'NhyBQf',
    }),
    content: !currentSiteId ? null : (
      <StepBaseline
        siteId={currentSiteId}
        submitActionRef={baselineSubmitActionRef}
      />
    ),
    onStepWillExit: () =>
      baselineSubmitActionRef
        .current?.()
        .catch(catchValidationErrorAndPreventStepExit),
  };
  const stepsFeatureFlagBaseline = [
    ...stepsOriginal.slice(0, CreateSiteStepsFeatureFlagBaseline.BASELINE),
    stepBaseline,
    ...stepsOriginal.slice(CreateSiteStepsFeatureFlagBaseline.BASELINE),
  ];

  return (
    <LayoutMultiPanel
      contentSidebar={
        siteType === SiteType.MONITORED_NETWORK ? (
          <StepCreate
            insightId={insightId!}
            onSiteCreated={siteCreatedHandler}
            mapPositionManager={mapPositionManager}
          />
        ) : (
          <WizardLayout
            stepsManager={stepsManager}
            onExit={
              currentSiteId ? () => goToSitePage(currentSiteId) : undefined
            }
            onCancel={
              currentSiteId
                ? undefined
                : () => {
                    navigate(-1);
                  }
            }
            steps={
              isFeatureBaselineUI ? stepsFeatureFlagBaseline : stepsOriginal
            }
          />
        )
      }
      contentMain={
        <CreateSiteMap
          mapPositionManager={mapPositionManager}
          workAreas={workAreasDataHandler.data || []}
          workAreasEditorManager={workAreasEditorManager}
          routesEditorManager={routesEditorManager}
          routes={routesDataHandler.data || []}
          showSatelliteImage={
            stepsManager.activeStep === CreateSiteSteps.WORK_AREAS
          }
          showLocationPin={
            !currentSiteId || stepsManager.activeStep === CreateSiteSteps.CREATE
          }
        />
      }
    />
  );
}

function fragmentToSiteType(fragmentString?: string): SiteType {
  if (fragmentString === 'network') {
    return SiteType.MONITORED_NETWORK;
  }
  return SiteType.PROJECT_SITE;
}
