import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState, useEffect } from 'react';
import SelectionGroup from 'design-system/molecules/selection-group';
import {
  Box,
  Center,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { useCurrentSiteData } from '../../data/useSiteData';
import useBaselineJourneyTimeData from '../../data/useBaselineJourneyTimeData';
import { colorsSortedByKey } from '../../utils/routes';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import { BaselineDetailsPanelLayout } from './BaselineDetailsPanelLayout';
import RouteIcon from '../../svg/route.svg?react';
import LayoutPanel from '../LayoutPanel';
import BaselineJourneyTimeChart from './BaselineJourneyTimeChart';

export interface BaselineJourneyTimePanelProps {
  baselineId: string;
}
export default function BaselineJourneyTimePanel({
  baselineId,
}: BaselineJourneyTimePanelProps) {
  const { track } = useAnalytics();
  const { formatMessage } = useIntl();
  const [aggMethod, setAggMethod] = useState<'max' | 'avg'>('max');
  const [selectedRouteIds, setSelectedRouteIds] = useState<string[]>([]);
  const [baselineRoutes, setBaselineRoutes] = useState<
    { routeId: string; routeName: string }[]
  >([]);
  const currentSiteDataHandler = useCurrentSiteData();
  const baselineDataChartHandler = useBaselineJourneyTimeData({
    baselineId,
    aggMethod,
  });
  const isFetching = baselineDataChartHandler.isValidating;
  const hasData =
    !hasDataHandlerFeedBack([
      baselineDataChartHandler,
      currentSiteDataHandler,
    ]) && baselineDataChartHandler.data;

  useEffect(() => {
    if (hasData) {
      const newBaselineRoutes = hasData.routes.map((route) => ({
        routeId: route.routeId,
        routeName: route.routeName,
      }));
      if (baselineRoutes.length === 0 && newBaselineRoutes.length > 0) {
        setBaselineRoutes(newBaselineRoutes);
        setSelectedRouteIds(newBaselineRoutes.map((route) => route.routeId));
      }
    }
  }, [baselineRoutes, hasData]);
  const colorByRoute = colorsSortedByKey(baselineRoutes, 'routeId');
  return (
    <BaselineDetailsPanelLayout
      title={formatMessage({
        defaultMessage: 'Baseline journey time',
        id: 'S0pGZP',
      })}
      leftPanel={
        <SelectionGroup
          title={<FormattedMessage defaultMessage="Routes" id="eyx5By" />}
          titleIcon={<RouteIcon />}
          options={baselineRoutes.map((route) => ({
            id: route.routeId,
            label: route.routeName,
            color: colorByRoute[route.routeId],
          }))}
          selectedOptions={selectedRouteIds}
          onSelectedOptionsChange={setSelectedRouteIds}
        />
      }
      rightPanel={
        isFetching && !hasData ? (
          <Flex
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            p={10}
          >
            <Spinner variant="mooven" size="xl" />
          </Flex>
        ) : (
          <Box w="full" position="relative">
            <Flex alignItems="center" w="full" pl={4}>
              <Heading size="md" mr={2}>
                <FormattedMessage
                  defaultMessage="Use journey time:"
                  id="yeYDY0"
                />
              </Heading>
              <Box ml={3}>
                <RadioGroup
                  onChange={(newValue: 'max' | 'avg') => {
                    setAggMethod(newValue);
                    track('Baseline journey time panel', {
                      action: 'Agg method selected',
                      label: newValue,
                      referrer: 'Baseline journey time panel',
                      feature: 'Baselines',
                    });
                  }}
                  value={aggMethod}
                >
                  <Stack direction="row">
                    <Radio value="max" size="lg">
                      <Box
                        fontSize="xs"
                        fontWeight={400}
                        lineHeight={4}
                        color="gray.700"
                      >
                        <FormattedMessage
                          defaultMessage="Maximum"
                          id="2wsmXq"
                          description="Maximum aggregation"
                        />
                      </Box>
                    </Radio>
                    <Radio value="avg" size="lg">
                      <Box
                        fontSize="xs"
                        fontWeight={400}
                        lineHeight={4}
                        color="gray.700"
                      >
                        <FormattedMessage
                          defaultMessage="Average"
                          id="CdDMRU"
                          description="Average aggregation"
                        />
                      </Box>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Box>
            </Flex>
            {!hasData && (
              <LayoutPanel data-testid="baseline-journey-time-chart-panel-loading">
                <Center height="400px">
                  <DataHandlerFeedback
                    dataHandlersParam={[
                      baselineDataChartHandler,
                      currentSiteDataHandler,
                    ]}
                  />
                </Center>
              </LayoutPanel>
            )}
            {hasData && (
              <BaselineJourneyTimeChart
                data={hasData.routes.map((route) => ({
                  name: route.routeId,
                  label: route.routeName,
                  items: route.items,
                }))}
                excludeDayRanges={baselineDataChartHandler.data!.datesToExclude}
                colorByRoute={colorByRoute}
                displayRoutes={selectedRouteIds}
                timeZone={currentSiteDataHandler.data!.siteTimeZone}
                baselineName={baselineDataChartHandler.data!.baselineName}
              />
            )}
          </Box>
        )
      }
    />
  );
}
