import { createContext } from 'react';

export const LayoutContext = createContext({
  // For content that overtakes current content to show more details, similar to a modal but contained in the same area.
  // Example: drilling down into a list item, such as DrillDownContent component in route creation step of site creation wizard.
  LayoutContextDrillDown: ({ children }) => children,
  //
  // Examples of possible layout context areas that have not been implemented yet:
  // LayoutContextMain: ({ children }) => children, // For indirectly related content rendered in the main area of the layout. Example: main content area with additional information like our map boxes.
  // LayoutContextAside: ({ children }) => children, // For indirectly related content rendered in a less prominent area of the layout. Example: a sidebar with additional information.
  // LayoutContextFooter: ({ children }) => children,
  // LayoutContextHeader: ({ children }) => children,
});
