import { useState, createRef, useEffect } from 'react';
import { Box, useSteps } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { LocalDate } from '@webapp/bff/src/types/dates';
import { pointArrayToWkt } from '@lib/gis';
import { DisruptionPackType } from '@webapp/bff/src/types/report';
import { pathSitesView } from '../../constants/path';
import {
  WorkAreasEditorList,
  useWorkAreasEditorManager,
} from '../WorkAreasEditor';
import useWorkAreasData from '../../data/useWorkAreasData';
import OccupationDatesEditor from '../OccupationDatesEditor';
import useSiteData from '../../data/useSiteData';
import ImpactAssessmentRecipientsSection from '../ImpactAssessmentRecipientsSection';
import useDisruptionPacksData from '../../data/useDisruptionPacksData';
import { useDisruptionAreaEditorManager } from '../DisruptionAreaEditor';
import DisruptionAreaEditorAutoSavePanel from '../DisruptionAreaEditor/DisruptionAreaEditorAutoSavePanel';
import useCurrentUserData from '../../data/useCurrentUserData';
import LayoutMultiPanel from '../LayoutMultiPanel';
import WizardLayout, { WizardStepConfig } from '../WizardLayout';
import ImpactAssessmentRequestMap from './ImpactAssessmentRequestMap';
import useRoutesData from '../../data/useRoutesData';
import StepImpactAssessmentType from './StepImpactAssessmentType';
import MainPanelImpactAssessmentType from './MainPanelImapctAssessmentType';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

enum ImpactAssessmentRequestSteps {
  ASSESSMENT_TYPE,
  DATES,
  WORK_AREAS,
  DISRUPTION_AREA,
  RECIPIENTS,
}

export default function ImpactAssessmentRequest() {
  const { insightId, siteId } = useParams();
  if (!insightId || !siteId) {
    throw new Error('Missing required params from URL');
  }
  const { track } = useAnalytics();
  const currentSiteDataHandler = useSiteData({
    orgId: insightId,
    siteId,
  });
  useEffect(() => {
    track('Impact Assessment Wizard Opened', {
      siteId,
      referrer: 'Impact assessment wizard',
      feature: 'ImpactAssessment',
    });
  }, [track, siteId]);
  const { formatMessage } = useIntl();
  const stepsManager = useSteps();
  const navigate = useNavigate();
  const goToSitePage = () => {
    navigate(pathSitesView(insightId, siteId));
  };

  const [impactAssessmentType, setImpactAssessmentType] = useState<
    DisruptionPackType[]
  >(['PUBLIC_TRANSPORT_AND_BUSINESSES']);

  const impactAssessmentTypeSubmitActionRef = createRef<() => Promise<void>>();

  const workAreasDataHandler = useWorkAreasData(siteId);
  const workAreasEditorManager = useWorkAreasEditorManager(
    workAreasDataHandler.data
  );

  const routesDataHandler = useRoutesData(Number(siteId));

  const disruptionAreaEditorManager = useDisruptionAreaEditorManager({
    workAreas: workAreasDataHandler.data,
    disruptionArea: currentSiteDataHandler.data?.disruptionArea,
  });

  const currentUserDataHandler = useCurrentUserData();
  const disruptionPacksDataHandler = useDisruptionPacksData(siteId);

  const steps: WizardStepConfig[] = [
    {
      name: formatMessage({
        defaultMessage: 'Assessment type',
        id: 'uyv5Ef',
      }),
      title: formatMessage({
        defaultMessage: 'Impact assessment',
        id: 'bf+0+y',
      }),
      content: (
        <StepImpactAssessmentType
          defaultValues={{
            impactAssessmentType,
          }}
          onSubmit={(formValues) => {
            setImpactAssessmentType(formValues.impactAssessmentType);
          }}
          submitActionRef={impactAssessmentTypeSubmitActionRef}
        />
      ),
      onStepWillExit: () => impactAssessmentTypeSubmitActionRef.current?.(),
    },
    {
      name: formatMessage({
        defaultMessage: 'Occupation dates',
        id: 'hWl4QU',
      }),
      title: formatMessage({
        defaultMessage: 'Define occupation dates',
        id: 'Hpfw8k',
      }),
      content: (
        <OccupationDatesEditor
          occupationDates={{
            startDate: currentSiteDataHandler.data?.occupationStartDate,
            endDate: currentSiteDataHandler.data?.occupationEndDate,
          }}
          updateOccupationDates={async ({ startDate, endDate }) => {
            await currentSiteDataHandler.updateSiteOccupationDates({
              startDate: startDate as LocalDate,
              endDate: endDate as LocalDate,
            });
          }}
        />
      ),
    },
    {
      name: formatMessage({
        defaultMessage: 'Work area',
        id: 'Edr2gU',
      }),
      title: formatMessage({
        defaultMessage: 'Define work areas',
        id: '99Cnzu',
      }),
      content: (
        <WorkAreasEditorList
          workAreas={workAreasDataHandler.data || []}
          workAreasEditorManager={workAreasEditorManager}
          createWorkArea={workAreasDataHandler.createWorkArea}
          editWorkArea={workAreasDataHandler.editWorkArea}
          deleteWorkArea={workAreasDataHandler.deleteWorkArea}
        />
      ),
      onStepWillExit: () => {
        if (workAreasEditorManager.polygonCreatorManager.isCreating) {
          workAreasEditorManager.polygonCreatorManager.stopCreating();
        }
      },
    },
    {
      name: formatMessage({
        defaultMessage: 'Disruption area',
        id: 'SmWcwR',
      }),
      title: formatMessage({
        defaultMessage: 'Define your disruption area',
        id: 'XsPr2S',
      }),
      content: (
        <DisruptionAreaEditorAutoSavePanel
          disruptionAreaEditorManager={disruptionAreaEditorManager}
        />
      ),
      onStepWillExit: async () => {
        const {
          polygonCreatorManager: { isCreating, stopCreating },
          disruptionAreaState,
          hasUnsavedChanges,
        } = disruptionAreaEditorManager;
        if (isCreating) {
          stopCreating();
        }
        if (
          !hasUnsavedChanges ||
          !disruptionAreaState ||
          disruptionAreaState.length < 3
        ) {
          return;
        }
        const wkt = pointArrayToWkt(disruptionAreaState);
        await currentSiteDataHandler.updateSiteDisruptionArea(wkt);
      },
    },
    {
      name: formatMessage({
        defaultMessage: 'Recipients',
        id: '8N9wG+',
      }),
      title: formatMessage({
        defaultMessage: 'Add recipients',
        id: 'ZGYPiR',
      }),
      content: (
        <ImpactAssessmentRecipientsSection
          impactAssessmentTypeDefaultValue={impactAssessmentType}
          currentUserEmail={currentUserDataHandler.data?.email}
          requestsHistory={disruptionPacksDataHandler.data || []}
          requestImpactAssessment={
            disruptionPacksDataHandler.requestImpactAssessment
          }
          requestImpactAssessmentTrackProps={{
            siteId,
            referrer: 'Impact assessment wizard',
            feature: 'ImpactAssessment',
          }}
          hasDisruptionArea={Boolean(
            currentSiteDataHandler.data?.disruptionArea
          )}
          plannedDates={{
            startDate: currentSiteDataHandler.data?.occupationStartDate,
            endDate: currentSiteDataHandler.data?.occupationEndDate,
          }}
          goToDisruptionArea={() => {
            stepsManager.setActiveStep(
              ImpactAssessmentRequestSteps.DISRUPTION_AREA
            );
          }}
          goToSetDates={() => {
            stepsManager.setActiveStep(ImpactAssessmentRequestSteps.DATES);
          }}
        />
      ),
    },
  ];

  return (
    <LayoutMultiPanel
      contentSidebar={
        <WizardLayout
          stepsManager={stepsManager}
          onExit={{
            action: () => {
              goToSitePage();
              track('Impact Assessment Wizard Exited', {
                siteId,
                referrer: 'Impact assessment wizard',
                feature: 'ImpactAssessment',
              });
            },
            label: formatMessage({
              defaultMessage: 'Back to site',
              id: 'oWjhon',
            }),
            buttonProps: {
              variant: 'outline',
            },
          }}
          steps={steps}
        />
      }
      contentMain={
        <Box display="flex" height="100%" position="relative">
          <ImpactAssessmentRequestMap
            workAreas={workAreasDataHandler.data || []}
            workAreasEditorManager={workAreasEditorManager}
            disruptionAreaEditorManager={disruptionAreaEditorManager}
            routes={routesDataHandler.data || []}
            isEditingDisruptionArea={
              stepsManager.activeStep ===
              ImpactAssessmentRequestSteps.DISRUPTION_AREA
            }
            showSatelliteImage={
              stepsManager.activeStep ===
              ImpactAssessmentRequestSteps.WORK_AREAS
            }
          />
          {stepsManager.activeStep ===
            ImpactAssessmentRequestSteps.ASSESSMENT_TYPE && (
            <Box position="absolute" top="0" left="0" right="0" bottom="0">
              <MainPanelImpactAssessmentType />
            </Box>
          )}
        </Box>
      }
    />
  );
}
