import { Button, ButtonProps, HStack, ModalProps } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { StyledModal } from '../modal';

export interface ConfirmationModalProps {
  body: ReactNode;
  cancelButtonText?: string;
  confirmButtonColorScheme?: ButtonProps['colorScheme'];
  confirmButtonText?: string;
  onCancel: () => void;
  onConfirm: () => void | Promise<void>;
  open: boolean;
  title: ReactNode;
  size?: ModalProps['size'];
}

export default function ConfirmationModal(props: ConfirmationModalProps) {
  const { formatMessage } = useIntl();
  const [inProgress, setInProgress] = useState(false);
  const {
    body,
    cancelButtonText = formatMessage({
      id: 'HSXXAS',
      description: 'Confirmation modal cancel button text.',
      defaultMessage: 'Cancel',
    }),
    confirmButtonColorScheme = 'greenDark',
    confirmButtonText = formatMessage({
      id: 'rqXCrj',
      description: 'Confirmation modal confirm button text.',
      defaultMessage: 'Confirm',
    }),
    onCancel,
    onConfirm,
    open,
    title,
    size,
  } = props;
  return (
    <StyledModal
      isOpen={open}
      onClose={onCancel}
      header={title}
      modalProps={{ size }}
      footer={
        <HStack spacing={2}>
          <Button
            data-testid="confirmation-modal-confirm-button"
            colorScheme={confirmButtonColorScheme}
            onClick={async () => {
              setInProgress(true);
              try {
                await onConfirm();
              } finally {
                setInProgress(false);
              }
            }}
            isLoading={inProgress}
          >
            {confirmButtonText}
          </Button>
          <Button onClick={onCancel} variant="outline">
            {cancelButtonText}
          </Button>
        </HStack>
      }
    >
      {body}
    </StyledModal>
  );
}
