import { useCallback, useRef, useState } from 'react';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import ContextMenu, {
  ContextMenuGroupItemConfig,
  ContextMenuItemConfig,
} from 'design-system/molecules/context-menu';
import Highcharts, { Chart } from 'highcharts';
import { LocalDate, parseDate } from '@lib/dates';
import { formatDateRange } from 'design-system/molecules/date-range';
import InsightChart from '../InsightChart';
import ChartEditableAnnotationLayer from './ChartEditableAnnotationLayer';
import { Annotation } from '../../types/annotations';
import { DATE_PRESET_SHORT_FULL_YEAR } from '../../utils/dateUtils';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

export function SiteImpactChartPanelContent({
  baselines,
  createAnnotation,
  resendAnnotationAlert,
  selectedBaselineId,
  setSelectedBaselineId,
  onManageBaselinesClick,
  ...chartProps
}: Omit<
  React.ComponentProps<typeof InsightChart>,
  'actions' | 'extraConfig' | 'callback'
> & {
  baselines: {
    baselineId: string;
    name?: string;
    isDefault: boolean;
    baselineType: string;
    config?: {
      startDate: LocalDate;
      endDate: LocalDate;
    };
  }[];
  createAnnotation: (annotation: Partial<Annotation>) => Promise<void>;
  resendAnnotationAlert: (annotationId: number) => Promise<void>;
  setSelectedBaselineId: (baselineId: string) => void;
  selectedBaselineId?: string;
  onManageBaselinesClick?: () => void;
}) {
  const [internalChart, setInternalChart] = useState<Chart | null>(null);
  const [isEditingAnnotation, setEditingAnnotation] = useState(false);
  const [showAnnotation, setShowAnnotation] = useState<boolean>(true);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { formatMessage, formatDate } = useIntl();
  const { track } = useAnalytics();

  const baselineOptions:
    | ContextMenuItemConfig[]
    | ContextMenuGroupItemConfig[] = (baselines ?? []).map((baseline) => ({
    key: baseline.baselineId.toString(),
    label:
      baseline.baselineType === '4WEEKS_AVERAGE_BASELINE' && baseline.config
        ? `${baseline.name} (${formatDateRange(
            parseDate(baseline.config.startDate, { name: chartProps.timeZone }),
            parseDate(baseline.config.endDate, { name: chartProps.timeZone }),
            chartProps.timeZone
          )})`
        : baseline.name ||
          formatMessage({ defaultMessage: 'Site baseline', id: 'ujO9kZ' }),
    action: () => {
      setSelectedBaselineId(baseline.baselineId.toString());
      track('Baseline Selected From Dropdown', {
        referrer: 'View Site Tab',
        feature: 'Baselines',
        baselineId: baseline.baselineId.toString(),
      });
    },
  }));
  const selectedBaseline = selectedBaselineId
    ? baselineOptions.find((b) => b.key === selectedBaselineId)
    : baselineOptions[0];

  const customExportChartRender = useCallback(
    (chartInstance: Highcharts.Chart) => {
      const padding = 4;
      const baseline = baselines.find(
        (b) => b.baselineId === selectedBaselineId
      );
      const baselineName = baseline?.name || 'Site baseline';
      const textElement = chartInstance.renderer
        .text('Compared to', 25, 65)
        .css({
          color: '#56586B',
          fontSize: '12px',
          fontWeight: '500',
        })
        .add();
      const textBBox = textElement.getBBox();
      const baselineElement = chartInstance.renderer
        .text(baselineName, textBBox.x + textBBox.width + 3 * padding, 65)
        .css({
          color: '#FFFFFF',
          fontSize: '12px',
          fontWeight: '500',
        })
        .attr({
          zIndex: 1,
        })
        .add();
      const baselineBBox = baselineElement.getBBox();
      chartInstance.renderer
        .rect(
          baselineBBox.x - padding,
          baselineBBox.y - padding,
          baselineBBox.width + 2 * padding,
          baselineBBox.height + 2 * padding,
          6
        )
        .attr({
          fill: '#553C9A',
          'stroke-width': 0,
        })
        .add();
      if (baseline?.config?.startDate && baseline?.config?.endDate) {
        chartInstance.renderer
          .text(
            `${formatDate(baseline.config.startDate, DATE_PRESET_SHORT_FULL_YEAR)} - ${formatDate(baseline.config.endDate, DATE_PRESET_SHORT_FULL_YEAR)}`,
            baselineBBox.x + baselineBBox.width + 3 * padding,
            65
          )
          .css({
            color: '#56586B',
            fontSize: '12px',
            fontWeight: '500',
          })
          .add();
      }
    },
    [baselines, formatDate, selectedBaselineId]
  );
  return (
    <InsightChart
      {...chartProps}
      chartContainerRef={chartContainerRef}
      annotations={showAnnotation ? chartProps.annotations : []}
      chartConfig={
        !isEditingAnnotation
          ? chartProps.chartConfig
          : {
              ...chartProps.chartConfig,
              zoomingType: undefined,
              disableCrosshair: true,
              disableTooltip: true,
            }
      }
      actions={
        internalChart && (
          <ChartEditableAnnotationLayer
            chart={internalChart}
            timeZone={chartProps.timeZone}
            isEditingAnnotation={isEditingAnnotation}
            setEditingAnnotation={setEditingAnnotation}
            showAnnotation={showAnnotation}
            setShowAnnotation={setShowAnnotation}
            createAnnotation={createAnnotation}
            resendAnnotationAlert={resendAnnotationAlert}
          />
        )
      }
      callback={setInternalChart}
      extraConfig={
        selectedBaseline ? (
          <HStack gap={2} pt={1}>
            <Box flexGrow={1}>
              <Text as="span" style={{ whiteSpace: 'nowrap' }}>
                <FormattedMessage
                  defaultMessage="Performance compared to:"
                  id="n3zNJn"
                />
              </Text>
            </Box>
            <ContextMenu
              data-testid="baseline-selection-dropdown"
              triggerLabel={selectedBaseline.label}
              items={baselineOptions}
              actions={
                onManageBaselinesClick && (
                  <VStack w="full" alignItems="flex-start" px={2} pt={2}>
                    <Button
                      size="xs"
                      onClick={onManageBaselinesClick}
                      data-testid="manage-baselines-trigger"
                    >
                      <FormattedMessage
                        defaultMessage="Manage baselines"
                        id="wFD8u1"
                      />
                    </Button>
                  </VStack>
                )
              }
            />
          </HStack>
        ) : undefined
      }
      customExportChartRender={customExportChartRender}
    />
  );
}
