/* eslint-disable max-classes-per-file */

import {
  FieldValues,
  SubmitHandler,
  UseFormHandleSubmit,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSuccessToast } from '../hooks/useSuccessToast';
import { useFailureToast } from '../hooks/useFailureToast';
import { PreventWizardStepExit } from '../components/WizardLayout';

export class FormSubmitError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FormSubmitError';
  }
}

export class FormValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FormValidationError';
  }
}

export function useHookFormSubmitAction<
  TFieldValues extends FieldValues = FieldValues,
>({
  handleSubmit,
  onSubmit,
  showToastOnSuccess,
  showToastOnValidationError,
  showToastOnSubmitError,
}: {
  handleSubmit: UseFormHandleSubmit<TFieldValues>;
  onSubmit: SubmitHandler<TFieldValues>;
  showToastOnSuccess?:
    | true
    | ((
        response: Awaited<ReturnType<SubmitHandler<TFieldValues>>>
      ) => string | undefined);
  showToastOnValidationError?: true | ((errors: Object) => string | undefined);
  showToastOnSubmitError?: true | ((error: unknown) => string | undefined);
}) {
  const successToast = useSuccessToast();
  const failureToast = useFailureToast();
  const { formatMessage } = useIntl();
  return async function handleSubmitAction() {
    let validationErrors;
    let submitResponse;
    await handleSubmit(
      async (formValues) => {
        try {
          submitResponse = await onSubmit(formValues);
        } catch (error) {
          if (showToastOnSubmitError) {
            const errorMessage =
              showToastOnSubmitError === true
                ? formatMessage({
                    id: 'NxuEiR',
                    defaultMessage: 'Form submission failed',
                  })
                : showToastOnSubmitError(error);
            if (errorMessage) {
              failureToast({ title: errorMessage });
            }
          }
          if (error instanceof FormValidationError) {
            throw error;
          }
          throw new FormSubmitError(
            error instanceof Error ? error.message : 'Form submission failed'
          );
        }
      },
      (errors) => {
        validationErrors = errors;
      }
    )();
    if (validationErrors) {
      if (showToastOnValidationError) {
        const validationErrorMessage =
          showToastOnValidationError === true
            ? formatMessage({
                id: 'Wb0E1r',
                defaultMessage: 'Form validation failed',
              })
            : showToastOnValidationError(validationErrors);
        if (validationErrorMessage) {
          failureToast({ title: validationErrorMessage });
        }
      }
      throw new FormValidationError('Form validation failed');
    }
    if (showToastOnSuccess) {
      const successMessage =
        showToastOnSuccess === true
          ? formatMessage({
              id: 'uaiQsg',
              defaultMessage: 'Form submitted successfully',
            })
          : showToastOnSuccess(submitResponse);
      if (successMessage) {
        successToast({ title: successMessage });
      }
    }
    return submitResponse;
  };
}
export function catchValidationErrorAndPreventStepExit(
  error
): PreventWizardStepExit | never {
  if (error instanceof FormValidationError) {
    return 'PREVENT_WIZARD_STEP_EXIT';
  }
  throw error;
}

export function catchValidationError(error) {
  if (error instanceof FormValidationError) {
    return;
  }
  throw error;
}
