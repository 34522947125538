import useSWR from 'swr';
import { WorkArea } from '@webapp/bff/src/types/work-area';
import { trpc } from '../config/trpc';
import { useAnalytics } from '../hooks/analytics/useAnalytics';

export default function useWorkAreasData(siteId?: string, suspense = false) {
  const { data, mutate, isValidating, error } = useSWR<WorkArea[]>(
    siteId ? `useWorkAreasData-${siteId}` : null,
    !siteId ? null : () => trpc.workArea.getWorkAreas.query({ siteId }),
    {
      suspense,
    }
  );
  const { track } = useAnalytics();

  const mutateThenUpdate =
    (serverOp) =>
    async (
      ...args: Parameters<typeof serverOp>
    ): Promise<ReturnType<typeof serverOp>> => {
      const response = await serverOp(...args);
      await mutate();
      return response;
    };

  return {
    data,
    error,
    isValidating,
    createWorkArea: (workArea) => {
      track('Work area created', {
        referrer: 'Create Work Area',
        siteId,
      });
      return mutateThenUpdate(trpc.workArea.createWorkArea.mutate)({
        ...workArea,
        siteId,
      });
    },
    editWorkArea: (workArea) => {
      track('Work area updated', {
        referrer: 'Create Work Area',
        siteId,
        workAreaName: workArea.name,
        workAreaId: workArea.id,
      });
      return mutateThenUpdate(trpc.workArea.updateWorkArea.mutate)({
        id: workArea.id,
        name: workArea.name,
        wkt: workArea.wkt,
        fillColor: workArea.fillColor,
        fillTransparency: workArea.fillTransparency,
      });
    },
    deleteWorkArea: (workAreaId) =>
      mutateThenUpdate(trpc.workArea.deleteWorkArea.mutate)({
        id: workAreaId,
      }),
  };
}
