import useSWRImmutable from 'swr/immutable';
import { WeekDay } from '@lib/dates';
import type { DailyProfileData } from '@webapp/bff';
import { dataTrpc } from '../config/trpc';

export default function useBaselineProfileData(params: {
  baselineId: string;
  routeId: string;
  daysOfWeek: WeekDay[];
  timezone?: string;
}): {
  data: DailyProfileData | null;
  isValidating: boolean;
  error: any;
} {
  const { data, isValidating, error } = useSWRImmutable(
    params.timezone
      ? {
          keyName: 'useBaselineProfileData',
          baselineId: params.baselineId,
          routeId: params.routeId,
          daysOfWeek: params.daysOfWeek,
          timezone: params.timezone,
        }
      : null,
    async (swrParams) => {
      const dailyBaselineProfile =
        await dataTrpc.viewData.getBaselineDailyProfile.query({
          routeId: swrParams.routeId,
          daysOfWeek: swrParams.daysOfWeek,
          baselineId: swrParams.baselineId,
          timezone: swrParams.timezone,
        });
      return dailyBaselineProfile;
    }
  );

  return {
    data: data ?? null,
    isValidating,
    error,
  };
}
