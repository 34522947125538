import {
  Box,
  Flex,
  SimpleGrid,
  VStack,
  Button,
  SimpleGridProps,
} from '@chakra-ui/react';
import { useCalendar } from '@h6s/calendar';
import { useIntl } from 'react-intl';
import { LocalDate, formatLocalDate, parseLocalDate } from '@lib/dates';

export interface MultiDayPickerProps {
  minDate: LocalDate;
  maxDate: LocalDate;
  holidayDates?: LocalDate[];
  value: LocalDate[];
  onChange: (value: LocalDate[]) => void;
  isDisabled?: boolean;
  columns?: SimpleGridProps['columns'];
}

export default function MultiDayPicker({
  minDate,
  maxDate,
  holidayDates,
  value,
  onChange,
  isDisabled,
  columns = 2,
}: MultiDayPickerProps) {
  const monthDates = getMonthsBetween(minDate, maxDate);
  return (
    <SimpleGrid spacing={2} columns={columns} width="100%">
      {monthDates.map((monthDate) => (
        <CalendarMonth
          key={monthDate}
          monthDate={monthDate}
          minDate={minDate}
          maxDate={maxDate}
          holidayDates={holidayDates}
          isDisabled={isDisabled}
          selectedDays={value}
          onDayClick={(dayDate) => {
            if (value.includes(dayDate)) {
              onChange(value.filter((date) => date !== dayDate));
            } else {
              onChange([...value, dayDate]);
            }
          }}
        />
      ))}
    </SimpleGrid>
  );
}

function CalendarMonth({
  monthDate,
  minDate,
  maxDate,
  holidayDates = [],
  selectedDays = [],
  onDayClick = () => {},
  isDisabled = false,
}: {
  monthDate?: LocalDate;
  minDate?: LocalDate;
  maxDate?: LocalDate;
  holidayDates?: LocalDate[];
  selectedDays?: LocalDate[];
  onDayClick?: (date: LocalDate) => void;
  isDisabled?: boolean;
}) {
  const { formatDate } = useIntl();
  const minDateObj = minDate && parseLocalDate(minDate);
  const maxDateObj = maxDate && parseLocalDate(maxDate);
  const defaultDate = monthDate ? parseLocalDate(monthDate) : new Date();
  const calendarElements = useCalendar({
    defaultDate,
    defaultWeekStart: 1,
  });
  return (
    <VStack
      width="100%"
      bgColor="gray.50"
      padding={4}
      spacing={6}
      borderRadius="2xl"
      alignItems="stretch"
    >
      <Box fontSize="lg" fontWeight="bold">
        {formatDate(defaultDate, { month: 'long', year: 'numeric' })}
      </Box>
      <VStack spacing={2} alignItems="stretch">
        <SimpleGrid columns={7} spacing={0}>
          {calendarElements.headers.weekDays.map((weekday) => (
            <Box
              key={weekday.key}
              textAlign="center"
              fontSize="sm"
              fontWeight="bold"
            >
              {formatDate(weekday.value, { weekday: 'narrow' })}
            </Box>
          ))}
        </SimpleGrid>
        <Box>
          {calendarElements.body.value.map((daysRow) => (
            <SimpleGrid key={daysRow.key} columns={7} spacing={0}>
              {daysRow.value.map((day) => {
                if (!day.isCurrentMonth) {
                  return <Box key={day.key} />;
                }
                const isDayInRange =
                  (!minDateObj || day.value >= minDateObj) &&
                  (!maxDateObj || day.value <= maxDateObj);
                if (!isDayInRange) {
                  return (
                    <Flex
                      key={day.key}
                      flex={1}
                      justifyContent="center"
                      alignItems="center"
                      height="40px"
                      color="gray.300"
                    >
                      {day.date}
                    </Flex>
                  );
                }
                const dayStringDate = formatLocalDate(day.value);
                const isDaySelected = selectedDays.some(
                  (selectedDate) => selectedDate === dayStringDate
                );
                const isHoliday = holidayDates.some(
                  (holidayDate) => holidayDate === dayStringDate
                );
                return (
                  <Flex
                    position="relative"
                    key={day.key}
                    flex={1}
                    padding="2px"
                  >
                    <Button
                      flex={1}
                      minWidth={0}
                      padding={1}
                      justifyContent="center"
                      alignItems="center"
                      height="36px"
                      borderRadius="lg"
                      fontSize="sm"
                      colorScheme={isDaySelected ? 'red' : 'cyan'}
                      color="white"
                      onClick={() => {
                        onDayClick(dayStringDate);
                      }}
                      isDisabled={isDisabled}
                    >
                      {day.date}
                    </Button>
                    {isHoliday && (
                      <Box
                        position="absolute"
                        top="-6px"
                        right="-4px"
                        width="16px"
                        height="16px"
                        borderRadius="50%"
                        backgroundColor="purple.500"
                        fontSize="10px"
                        color="white"
                        fontWeight="600"
                        textAlign="center"
                        lineHeight="16px"
                        pointerEvents="none"
                      >
                        H
                      </Box>
                    )}
                  </Flex>
                );
              })}
            </SimpleGrid>
          ))}
        </Box>
      </VStack>
    </VStack>
  );
}

function getMonthsBetween(minDate: LocalDate, maxDate: LocalDate) {
  const months: LocalDate[] = [];
  const minDateObj = parseLocalDate(minDate);
  minDateObj.setDate(1);
  const maxDateObj = parseLocalDate(maxDate);
  maxDateObj.setDate(1);
  const currentDate = minDateObj;
  while (currentDate <= maxDateObj) {
    months.push(formatLocalDate(currentDate));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  return months;
}
