import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { DataType } from '@webapp/bff';
import { throttle } from 'lodash';
import { useMemo } from 'react';
import { StyledModal } from 'design-system/molecules/modal';
import moment from 'moment';
import LayoutSite from '../LayoutSite';
import useSitesData from '../../data/useSitesData';
import VisibleRoutesSelector, { VisibleRoute } from '../VisibleRoutesSelector';
import UserViewContainer from '../UserViewConfig/UserViewContainer';
import useInsightData from '../../data/useInsightData';
import EmptySite, { EmptySiteMode } from '../EmptySite';
import useRoutesData from '../../data/useRoutesData';
import AnnotationsOverlay from '../AnnotationsOverlay/AnnotationsOverlay';
import { useCurrentSiteData } from '../../data/useSiteData';
import ViewTimeSelection from '../../local_design_system/organisms/ViewTimeSelection';
import useSiteView from '../../hooks/useSiteView';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import useParamInsightId from '../../hooks/useParamInsightId';
import SummaryPanel from '../SummaryPanel';
import SiteViewMap from '../SiteViewMap';
import { colorsSortedByKey } from '../../utils/routes';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import SiteInsightChart from '../SiteInsightChart';
import useMapInsightsData from '../../data/useMapInsightsData';
import { MESSAGE_ERROR_SITE_NOT_FOUND } from '../../constants/messages';
import SiteExport from './SiteExport';
import useWorkAreasData from '../../data/useWorkAreasData';
import useInsightChartData from '../../data/useInsightChartData';
import useIncidentsData from '../../data/useIncidentsData';
import { errorReport } from '../../utils/errors';
import VisibleWorkAreasSelector from '../VisibleWorkAreasSelector';
import VisibleVmsSelector from '../VisibleVmsSelector';
import { SiteType } from '../CreateSiteTrigger/types';
import useSiteVmsData from '../../data/useSiteVmsData';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import { FEATURE_SITE_IMPACT_UI_BASELINES } from '../../constants/features';
import Baselines from '../Baselines';

type LatLngLiteral = google.maps.LatLngLiteral;

const debouncedNoChartsError = throttle(() => {
  errorReport.critical('No collected data types for site');
}, 5000);

export default function SiteView() {
  const insightId = useParamInsightId();
  const { formatMessage } = useIntl();

  const featureBaselinesUI = useFeatureSwitch(FEATURE_SITE_IMPACT_UI_BASELINES);
  const isFeatureBaselineUI = featureBaselinesUI === FeatureSwitchState.ON;
  const manageBaselineDisclosure = useDisclosure();

  const insightDataHandler = useInsightData(insightId);
  const { track } = useAnalytics();
  const sitesDataHandler = useSitesData(insightId);
  const incidentsDataHandler = useIncidentsData();
  const siteView = useSiteView();
  const mapPanel = insightDataHandler.data?.sections?.find(
    (panel) => panel.panelType === 'map'
  );
  const mapInsightsDataHandler = useMapInsightsData({
    insightId,
    siteId: siteView.state.siteId,
    panelId: mapPanel?.panelId,
  });
  const workAreasDataHandler = useWorkAreasData(
    siteView.state.siteId.toString()
  );
  const routesDataHandler = useRoutesData(siteView.state.siteId);
  const vmsDataHandler = useSiteVmsData(siteView.state.siteId.toString());
  const vmsColors = useMemo(
    () =>
      !vmsDataHandler.data
        ? {}
        : colorsSortedByKey(vmsDataHandler.data, 'vmsConfigId'),
    [vmsDataHandler.data]
  );

  const currentSiteDataHandler = useCurrentSiteData();

  // all possible chart types must have a data handler because hooks can't change after mounting
  const getParams = (insightType: DataType) =>
    !currentSiteDataHandler.data ||
    !currentSiteDataHandler.data.collectedDataTypes?.some(
      (dt) => dt === insightType
    )
      ? null
      : {
          insightId,
          siteId: currentSiteDataHandler.data.siteId.toString(),
          siteTimeZone: currentSiteDataHandler.data.siteTimeZone,
          timePeriod: siteView.state.period,
          dataType: insightType,
          timePeriodReference: currentSiteDataHandler.data.active
            ? undefined
            : currentSiteDataHandler.data.endPollingDate,
        };
  const allChartsDataHandlers = {
    JOURNEY_TIME: useInsightChartData(getParams('JOURNEY_TIME')),
    TIME_DELAY: useInsightChartData(getParams('TIME_DELAY')),
    SPEED: useInsightChartData(getParams('SPEED')),
    VEHICLE_COUNT: useInsightChartData(getParams('VEHICLE_COUNT')),
    QUEUE_LENGTH: useInsightChartData(getParams('QUEUE_LENGTH')),
    NOISE: useInsightChartData(getParams('NOISE')),
    DUST: useInsightChartData(getParams('DUST')),
    RAIN: useInsightChartData(getParams('RAIN')),
    ENVIRONMENT: useInsightChartData(getParams('ENVIRONMENT')),
    COMPLETED_TRIP_TIME: useInsightChartData(getParams('COMPLETED_TRIP_TIME')),
    DWELL_TIME: useInsightChartData(getParams('DWELL_TIME')),
    SITE_IMPACT_DELAY: useInsightChartData(getParams('SITE_IMPACT_DELAY')),
  };

  const routesColors = useMemo(
    () => colorsSortedByKey(routesDataHandler.data || [], 'trackId'),
    [routesDataHandler.data]
  );

  if (currentSiteDataHandler.error?.message === 'Not Found') {
    return (
      <div className="text-center pt-5">
        {formatMessage(MESSAGE_ERROR_SITE_NOT_FOUND)}
      </div>
    );
  }

  if (
    hasDataHandlerFeedBack([
      insightDataHandler,
      sitesDataHandler,
      currentSiteDataHandler,
      workAreasDataHandler,
      routesDataHandler,
      vmsDataHandler,
    ])
  ) {
    return (
      <DataHandlerFeedback
        dataHandlersParam={[
          insightDataHandler,
          sitesDataHandler,
          currentSiteDataHandler,
          workAreasDataHandler,
          routesDataHandler,
          vmsDataHandler,
        ]}
      />
    );
  }
  if (
    !insightDataHandler.data ||
    !sitesDataHandler.data ||
    !currentSiteDataHandler.data ||
    !siteView.state
  ) {
    return null;
  }

  const currentSite = currentSiteDataHandler.data;
  const highLevelPanelData = insightDataHandler.data.sections?.find(
    (panel) => panel.panelType === 'high_level'
  );

  const hasSiteMonitored = routesDataHandler.data?.some(
    (route) => route.monitorStarted
  );

  const showEmptySites =
    !hasSiteMonitored || !currentSite.routes || currentSite.routes.length === 0;

  const collectedDataTypes = currentSiteDataHandler.data?.collectedDataTypes;
  if (!collectedDataTypes && !showEmptySites) {
    debouncedNoChartsError();
  }
  if (
    collectedDataTypes &&
    isFeatureBaselineUI &&
    !collectedDataTypes.includes('SITE_IMPACT_DELAY')
  ) {
    collectedDataTypes.unshift('SITE_IMPACT_DELAY');
  }
  return (
    <>
      <LayoutSite
        windowTitle={`Mooven | ${currentSite.siteName}`}
        sideBarContent={
          <>
            <UserViewContainer
              insightId={Number(insightId)}
              siteView={siteView}
            />
            <Box bgColor="gray.50" p={4}>
              <ViewTimeSelection
                startMonitoringDate={(currentSite.startPollingDate ?? 0) * 1000}
                timePeriod={siteView.state.period}
                setTimePeriod={(period, selected) => {
                  siteView.setPeriod(period);
                  track('View Time Selection Changed', {
                    referrer: 'View Site',
                    period: period.type,
                    selected,
                    siteId: siteView.state.siteId,
                  });
                }}
                timeZone={currentSite.siteTimeZone}
              />
            </Box>
            <VisibleRoutesSelector
              routes={currentSite.routes as VisibleRoute[]}
              hiddenRoutes={siteView.state.hiddenRoutes}
              setHiddenRoutes={siteView.setHiddenRoutes}
            />
            {currentSite.siteType === SiteType.PROJECT_SITE && (
              <VisibleWorkAreasSelector
                workAreas={workAreasDataHandler.data! || []}
                hiddenWorkAreas={siteView.state.hiddenWorkAreas}
                setHiddenWorkAreas={siteView.setHiddenWorkAreas}
              />
            )}
            {vmsDataHandler.data && vmsDataHandler.data.length > 0 && (
              <VisibleVmsSelector
                vms={vmsDataHandler.data}
                deleteVmsBoard={vmsDataHandler.deleteVmsBoard}
                vmsColors={vmsColors}
                hiddenVms={siteView.state.hiddenVms}
                setHiddenVms={siteView.setHiddenVms}
                insightId={insightId}
                siteId={siteView.state.siteId.toString()}
              />
            )}
          </>
        }
        mapContent={
          <SiteViewMap
            orgId={insightId}
            deleteVmsBoard={vmsDataHandler.deleteVmsBoard}
            site={currentSite}
            incidents={incidentsDataHandler.data}
            workAreas={workAreasDataHandler.data!}
            vms={vmsDataHandler.data}
            routes={routesDataHandler.data!}
            hiddenRoutes={siteView.state.hiddenRoutes}
            hiddenWorkAreas={siteView.state.hiddenWorkAreas}
            hiddenVms={siteView.state.hiddenVms}
            vmsColors={vmsColors}
            mapInsights={mapInsightsDataHandler.data}
            routesColors={routesColors}
            siteLocation={currentSite.siteLocation as LatLngLiteral}
            setHiddenRoutes={siteView.setHiddenRoutes}
          />
        }
      >
        <Box p={4} pb={12}>
          {showEmptySites ? (
            <EmptySite
              siteId={siteView.state.siteId}
              emptySiteMode={
                currentSite.routes?.length
                  ? EmptySiteMode.NO_DATA
                  : EmptySiteMode.NO_ROUTE
              }
            />
          ) : (
            <>
              {highLevelPanelData && (
                <Flex direction={{ md: 'row', base: 'column' }} mb={4}>
                  <SummaryPanel />
                  <SiteExport
                    allChartsData={
                      currentSiteDataHandler.data?.collectedDataTypes?.map(
                        (dataType) => allChartsDataHandlers[dataType].data
                      ) ?? []
                    }
                    isLoading={
                      currentSiteDataHandler.data?.collectedDataTypes?.some(
                        (dataType) =>
                          allChartsDataHandlers[dataType].isValidating &&
                          !allChartsDataHandlers[dataType].error &&
                          !allChartsDataHandlers[dataType].data
                      ) ?? false
                    }
                    site={currentSite}
                  />
                </Flex>
              )}
              {collectedDataTypes?.map((dataType) => (
                <SiteInsightChart
                  key={dataType}
                  dataType={dataType}
                  insightId={insightId}
                  onManageBaselinesClick={manageBaselineDisclosure.onOpen}
                />
              ))}
            </>
          )}
        </Box>
        <AnnotationsOverlay />
      </LayoutSite>
      <StyledModal
        header={
          <FormattedMessage defaultMessage="Manage baselines" id="wFD8u1" />
        }
        isOpen={manageBaselineDisclosure.isOpen}
        onClose={manageBaselineDisclosure.onClose}
        modalProps={{
          size: '3xl',
        }}
      >
        <Baselines
          siteProps={currentSite}
          routes={routesDataHandler.data}
          minBaselineDate={moment(
            currentSite.startPollingDate
              ? currentSite.startPollingDate * 1000
              : undefined
          )
            .tz(currentSite.siteTimeZone)
            .format('YYYY-MM-DD')}
        />
      </StyledModal>
    </>
  );
}
