import React from 'react';
import {
  AlignValue,
  AxisTitleAlignValue,
  AxisTypeValue,
  SeriesLineOptions,
  TooltipFormatterContextObject,
  VerticalAlignValue,
} from 'highcharts';
import moment from 'moment-timezone';
import { Box, useToken } from '@chakra-ui/react';
import Chart from 'design-system/atoms/chart';
import { useIntl } from 'react-intl';
import ReactDOMServer from 'react-dom/server';
import { formatSeconds } from '../../utils/insightCharts';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import Tooltip from '../InsightChart/Tooltip';

interface DataItem {
  date: number;
  value: number | null; // seconds
}

export interface BaselineJourneyTimeChartProps {
  data: {
    name: string; // routeId
    label: string;
    items: DataItem[];
  }[];
  displayRoutes: string[]; // routeIds
  colorByRoute: Record<string, string>;
  timeZone: string;
  excludeDayRanges?: { start: number; end: number }[];
  baselineName?: string;
}

export default function BaselineJourneyTimeChart({
  data,
  displayRoutes,
  colorByRoute,
  timeZone,
  excludeDayRanges = [],
  baselineName,
}: BaselineJourneyTimeChartProps) {
  const [excludeBandColor] = useToken('colors', ['red.300']);
  const [excludeTextColor] = useToken('colors', ['red.700']);
  const { formatMessage } = useIntl();
  const { track } = useAnalytics();
  const trackChartEvents = (eventName, eventProps = {}) => {
    track(eventName, {
      ...eventProps,
      referrer: 'Baseline Journey Time Chart',
    });
  };
  const filteredData = data.filter((item) =>
    displayRoutes?.includes(item.name)
  );
  const formattedSeries = filteredData.map((routeData) => {
    const formattedRouteData = routeData.items.map((item) => [
      item.date,
      item.value,
    ]);
    return {
      type: 'line',
      data: formattedRouteData,
      name: routeData.label,
      color: colorByRoute[routeData.name],
    } as SeriesLineOptions;
  });
  const plotBands = excludeDayRanges.map((excludeDay) => ({
    color: excludeBandColor,
    borderRadius: 16,
    from: excludeDay.start,
    to: excludeDay.end,
    label: {
      text: formatMessage({
        defaultMessage: 'Excluded',
        id: 'uDlpba',
        description: 'Excluded day label',
      }),
      style: {
        color: excludeTextColor,
        fontWeight: '700',
        fontSize: '12px',
      },
      rotation: -90,
      align: 'left' as AlignValue,
      textAlign: 'left' as AlignValue,
      verticalAlign: 'top' as VerticalAlignValue,
      x: 15,
      y: 60,
    },
  }));
  const chartOptions = {
    title: {
      text: undefined,
      margin: 50,
    },
    chart: {
      marginTop: 80,
    },
    yAxis: {
      softMin: 0,
      title: {
        top: '13.5%',
        height: '86.5%',
        text: 'Travel Time (minutes)',
        reserveSpace: false,
        rotation: 0,
        x: 0,
        y: -32,
        align: 'high' as AxisTitleAlignValue,
        textAlign: 'left' as AlignValue,
      },
      labels: {
        formatter(item) {
          return formatSeconds(item.value);
        },
      },
      tickInterval: 60,
    },
    xAxis: {
      type: 'datetime' as AxisTypeValue,
      plotBands,
      dateTimeLabelFormats: {
        day: '%e %b',
      },
      title: {
        style: {
          fontSize: '12px',
        },
        text: formatMessage(
          {
            defaultMessage: 'Time in {timeZone}',
            id: 'Ez/Pyo',
            description: 'Time based chart x axis label',
          },
          { timeZone }
        ),
      },
    },
    time: {
      timezone: timeZone,
      moment,
    },
    legend: {
      enabled: false,
    },
    series: formattedSeries,
    exporting: {
      enabled: true,
      csv: {
        dateFormat: '%y-%m-%d',
      },
      chartOptions: {
        chart: {
          marginTop: 100,
        },
        title: {
          text: `${baselineName ?? 'Site baseline'} - ${formatMessage({
            defaultMessage: 'Baseline journey time chart',
            id: '/DsVoi',
          })}`,
        },
        legend: {
          enabled: true,
        },
      },
    },
    tooltip: {
      useHTML: true,
      shared: true,
      animation: true,
      borderRadius: 6,
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      borderColor: '#f3f3f3',
      shadow: false,
      outside: true,
      style: {
        zIndex: 2000,
      },
      formatter() {
        const formatterObject =
          this as unknown as TooltipFormatterContextObject;
        if (!formatterObject.points && !formatterObject.series) {
          return '';
        }
        if (formatterObject.points) {
          formatterObject.points.sort(
            (pointA, pointB) => pointB.y! - pointA.y!
          );
        }
        const tooltipInHtml = ReactDOMServer.renderToStaticMarkup(
          <Tooltip
            timeZone={timeZone}
            points={formatterObject.points || [formatterObject]}
            formatValue={formatSeconds}
            formatMessage={formatMessage}
          />
        );
        return tooltipInHtml.toString();
      },
    },
  };
  return (
    <Box position="relative">
      <Chart options={chartOptions} eventTracker={trackChartEvents} />
    </Box>
  );
}
