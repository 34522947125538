import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Text,
  Select,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Substep from 'design-system/molecules/substep';
import {
  ReactNode,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { SwapIcon, VmsBoardIcon } from 'design-system/atoms/custom-icons';
import { useForm } from 'react-hook-form';
import { ActiveVms, IsVendorType, VendorType } from '@webapp/bff/src/types/vms';
import { StyledModal } from 'design-system/molecules/modal';
import type { ProviderMessage } from '@vms/shared/src/lib/integrationTypes';
import { DeviceStatus } from '@vms/shared/src/lib/integrationTypes';
import AlertBox from '../AlertBox';
import { intercomLinkFormatter } from '../../utils/messageUtils';
import { useUserRoles } from '../../hooks/useUserRoles';
import { useHookFormSubmitAction } from '../../utils/form';

type TestStatus = 'idle' | 'testing' | 'success' | 'error';

export default function StepBoardConnect({
  vms,
  updateVms,
  testConnection,
  getVmsList,
  setMainPanelContent,
  submitActionRef,
}: {
  vms: ActiveVms;
  updateVms: (vms: Partial<ActiveVms>) => Promise<void>;
  testConnection: (
    vmsProps: Pick<ActiveVms, 'vmsConfigId' | 'vmsId' | 'vmsType' | 'vmsApiKey'>
  ) => Promise<ProviderMessage>;
  getVmsList: (
    vmsProps: Pick<ActiveVms, 'vmsType' | 'vmsApiKey'>
  ) => Promise<DeviceStatus[]>;
  setMainPanelContent: (content?: ReactNode) => void;
  submitActionRef: RefObject<() => Promise<void>>;
}) {
  const { isAdmin } = useUserRoles();
  const { vmsConfigId, providerMessage } = vms;
  const {
    register,
    watch,
    getValues,
    formState: { isSubmitting, dirtyFields },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      vmsType: vms.vmsType,
      vmsId: vms.vmsId,
      userEmail: vms.userEmail,
      hasCredentials: vms.hasCredentials ?? false,
      userPassword: undefined,
      j1Password: undefined,
    },
  });
  const submitAction = useHookFormSubmitAction({
    handleSubmit,
    onSubmit: async ({ userEmail, userPassword, vmsType, vmsId }) => {
      let updatedFields: Partial<ActiveVms> = { vmsType, vmsId };
      if (
        ['Datasigns', 'J1'].includes(vmsType ?? '') &&
        userEmail &&
        userPassword
      ) {
        const vmsApiKey = btoa(`${userEmail}:${userPassword}`);
        updatedFields = { ...updatedFields, vmsApiKey };
      }
      await updateVms(updatedFields);
    },
    showToastOnSubmitError: true,
  });
  useImperativeHandle(submitActionRef, () => submitAction);
  const vmsTypeValue = watch('vmsType');
  const hasCredentials = watch('hasCredentials');
  const [devices, setDevices] = useState<DeviceStatus[]>([]);
  const [devicesState, setDevicesState] = useState<TestStatus>('idle');
  const [connectionTestState, setConnectionTestState] =
    useState<TestStatus>('idle');
  const [message, setMessage] = useState<ProviderMessage>({ status: 200 });

  const anyFieldChanged = Object.keys(dirtyFields).length > 0;
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  const handlePasswordFieldFocus = () => {
    if (getValues('userPassword') === '') {
      setPasswordFieldType('text');
    }
  };

  const handlePasswordFieldBlur = () => {
    setPasswordFieldType('password');
  };

  const handlePasswordFieldChange = (event) => {
    const { vmsType } = getValues();
    if (event.target.value === '') {
      setPasswordFieldType('text');
    }
    if (vmsType === 'J1') {
      setDevices([]);
    }
  };

  const handleUserEmailFieldChange = () => {
    setDevices([]);
  };
  const handleVmsTypeChange = () => {
    setDevices([]);
    setValue('vmsId', '');
  };

  const testReqRef = useRef(0);
  const vmsListClickHandler = async () => {
    const { vmsType, userEmail, userPassword } = getValues();
    if (!vmsType || !userEmail || !userPassword) {
      return;
    }
    const vmsApiKey = btoa(`${userEmail}:${userPassword}`);
    try {
      const response = await getVmsList({
        vmsType,
        vmsApiKey,
      });
      setDevices(response);
      setDevicesState('success');
    } catch (e) {
      setDevicesState('error');
    }
  };
  const testConnectionClickHandler = async () => {
    setConnectionTestState('testing');
    const { vmsId, vmsType, userEmail, userPassword } = getValues();
    if (!vmsConfigId || !vmsId || !vmsType) {
      setConnectionTestState('error');
      return;
    }
    const latestReqId = testReqRef.current + 1;
    testReqRef.current = latestReqId;
    let vmsApiKey;
    if (
      (vmsTypeValue === VendorType.Datasigns ||
        vmsTypeValue === VendorType['J1-LED']) &&
      userEmail &&
      userPassword
    ) {
      vmsApiKey = btoa(`${userEmail}:${userPassword}`);
    }
    try {
      const response = await testConnection({
        vmsConfigId,
        vmsId,
        vmsType,
        vmsApiKey,
      });
      const connectionSuccessful =
        response.status === 200 && !response.warningMessage;
      if (testReqRef.current === latestReqId) {
        setConnectionTestState(connectionSuccessful ? 'success' : 'error');
        setMessage(response);
      }
    } catch (error) {
      if (testReqRef.current === latestReqId) {
        setConnectionTestState('error');
      }
    }
  };

  useEffect(() => {
    setMainPanelContent(
      IsVendorType(vmsTypeValue) &&
        vmsTypeValue !== VendorType.Datasigns &&
        vmsTypeValue !== VendorType['J1-LED'] ? (
        <ManufacturerInstructions vendor={vmsTypeValue} />
      ) : undefined
    );
    return () => {
      setMainPanelContent();
    };
  }, [vmsTypeValue, setMainPanelContent]);

  const { formatMessage } = useIntl();
  const getCofigurationByVendor = (vendor: VendorType) => {
    switch (vendor) {
      case VendorType['J1-LED']:
      case VendorType.Datasigns:
        return (
          <>
            <Substep
              stepNumber={2}
              stepText={
                <FormattedMessage defaultMessage="Login details" id="58O3SF" />
              }
            />
            <Box paddingLeft={8}>
              <Text mb={1} fontSize="xs" lineHeight={4}>
                {vendor === VendorType.Datasigns && (
                  <FormattedMessage id="sy+pv5" defaultMessage="Email" />
                )}
                {vendor === VendorType['J1-LED'] && (
                  <FormattedMessage defaultMessage="Username" id="JCIgkj" />
                )}
              </Text>
              <Input
                size="sm"
                placeholder="-"
                isDisabled={isSubmitting}
                {...register('userEmail', {
                  onChange: handleUserEmailFieldChange,
                })}
                mb={3}
              />
              <Text mb={1} fontSize="xs" lineHeight={4}>
                <FormattedMessage id="5sg7KC" defaultMessage="Password" />
              </Text>
              <Input
                autoComplete="new-password"
                size="sm"
                placeholder={hasCredentials ? '••••••••' : '-'}
                onFocus={handlePasswordFieldFocus}
                type={passwordFieldType}
                isDisabled={isSubmitting}
                {...register('userPassword', {
                  onBlur: handlePasswordFieldBlur,
                  onChange: handlePasswordFieldChange,
                })}
              />
            </Box>
          </>
        );
      case VendorType.Bartco:
        return (
          <>
            <Substep
              stepNumber={2}
              stepText={
                <FormattedMessage
                  id="SuJiBI"
                  defaultMessage="Give Mooven access to your board"
                />
              }
            />
            <AlertBox
              message={
                <FormattedMessage
                  id="kneRvu"
                  defaultMessage="In order to connect this board you'll first need to grant access to Mooven by cross-hiring it."
                />
              }
            />
          </>
        );
      case VendorType.VirtualVms:
        return (
          <AlertBox
            message={
              <FormattedMessage
                id="NZ7YUb"
                defaultMessage="Test board configuration is not backed by a physical board."
              />
            }
          />
        );
      default:
        return null;
    }
  };
  return (
    <>
      <VStack spacing={4} alignItems="stretch">
        <Box fontSize="sm">
          <FormattedMessage
            id="SY2xEW"
            defaultMessage="Let's start by connecting to your board"
          />
        </Box>
        <VStack spacing={1} alignItems="stretch">
          {!anyFieldChanged && providerMessage && (
            <ConnectionStatus
              status="error"
              providerMessage={providerMessage}
            />
          )}
          <Substep
            stepNumber={1}
            stepText={
              <FormattedMessage
                id="ao8DZi"
                defaultMessage="Select the manufacturer of your board"
              />
            }
          />
          <Box paddingLeft={8}>
            <Select
              size="sm"
              isDisabled={isSubmitting}
              placeholder={formatMessage({
                defaultMessage: 'Select board manufacturer',
                id: 'QPK7Hp',
              })}
              {...register('vmsType', {
                onChange: handleVmsTypeChange,
              })}
            >
              {Object.entries(VendorType)
                .filter(
                  ([, value]) => isAdmin || value !== VendorType.VirtualVms
                )
                .map(([key, value]) => (
                  <option value={value}>{key}</option>
                ))}
            </Select>
          </Box>
        </VStack>
        {!vmsTypeValue ? (
          <AlertBox
            title={
              <FormattedMessage
                id="3YKke7"
                defaultMessage="Board manufacturer not listed?"
              />
            }
            message={
              <FormattedMessage
                id="6TpI8M"
                defaultMessage="Get in <supportLink>contact with Mooven</supportLink> to see what we can do."
                values={{ supportLink: intercomLinkFormatter() }}
              />
            }
          />
        ) : (
          <>
            {getCofigurationByVendor(vmsTypeValue)}
            {vmsTypeValue === 'J1' ? (
              <VStack spacing={1} alignItems="stretch">
                <Substep
                  stepNumber={3}
                  stepText={
                    <FormattedMessage
                      id="B/APD8"
                      defaultMessage="Add your boards’ serial number"
                    />
                  }
                />
                {devices.length === 0 ? (
                  <Box paddingLeft={8}>
                    <Button
                      size="xs"
                      variant="outline"
                      onClick={vmsListClickHandler}
                    >
                      <FormattedMessage
                        defaultMessage="Get Vms list"
                        id="7bmlHi"
                      />
                    </Button>
                  </Box>
                ) : (
                  <Select
                    paddingLeft={8}
                    size="sm"
                    placeholder={formatMessage({
                      defaultMessage: 'Select vms board',
                      id: '8OBdwn',
                    })}
                    {...register('vmsId')}
                  >
                    {devices.map((device) => (
                      <option value={device.vmsId}>{device.name}</option>
                    ))}
                  </Select>
                )}
                {devicesState === 'error' ? (
                  <Box paddingLeft={8}>
                    <AlertBox
                      message={
                        <FormattedMessage
                          defaultMessage="Please add valid email and password."
                          id="WnPmFl"
                        />
                      }
                    />
                  </Box>
                ) : null}
              </VStack>
            ) : (
              <VStack spacing={1} alignItems="stretch">
                <Substep
                  stepNumber={3}
                  stepText={
                    <FormattedMessage
                      id="B/APD8"
                      defaultMessage="Add your boards’ serial number"
                    />
                  }
                />
                <Box paddingLeft={8}>
                  <Input
                    size="sm"
                    placeholder="-"
                    isDisabled={isSubmitting}
                    {...register('vmsId')}
                  />
                </Box>
              </VStack>
            )}
            <Box>
              <Button
                size="xs"
                variant="outline"
                onClick={testConnectionClickHandler}
                isDisabled={isSubmitting}
              >
                <FormattedMessage
                  id="9ELruC"
                  defaultMessage="Test Connection"
                />
              </Button>
            </Box>
          </>
        )}
      </VStack>
      <StyledModal
        isOpen={connectionTestState !== 'idle'}
        onClose={() => setConnectionTestState('idle')}
        header={getModalTitle(connectionTestState)}
        modalContentProps={{ gap: 8 }}
        footer={
          <HStack spacing={2}>
            <Button
              onClick={() => setConnectionTestState('idle')}
              variant="outline"
            >
              <FormattedMessage id="rbrahO" defaultMessage="Close" />
            </Button>
            {connectionTestState === 'error' && (
              <Button onClick={testConnectionClickHandler} variant="outline">
                <FormattedMessage id="FazwRl" defaultMessage="Try again" />
              </Button>
            )}
          </HStack>
        }
      >
        <ConnectionStatus
          status={connectionTestState}
          providerMessage={message}
        />
      </StyledModal>
    </>
  );
}
function getModalTitle(status: TestStatus) {
  switch (status) {
    case 'success':
      return (
        <FormattedMessage
          id="Lr73tQ"
          defaultMessage="Connection test succeeded"
        />
      );
    case 'error':
      return (
        <FormattedMessage id="ZMEtvt" defaultMessage="Connection test failed" />
      );
    default:
      return (
        <FormattedMessage id="CvuiN9" defaultMessage="Testing connection" />
      );
  }
}
function ConnectionStatus({
  status,
  providerMessage,
}: {
  status: TestStatus;
  providerMessage: ProviderMessage;
}) {
  switch (status) {
    case 'success':
      return (
        <AlertBox status="success" title={providerMessage.connectedMessage} />
      );
    case 'error':
      return (
        <VStack spacing={2}>
          {providerMessage.connectedMessage && (
            <AlertBox
              status="success"
              title={providerMessage.connectedMessage}
            />
          )}
          <AlertBox
            title={providerMessage.warningMessage}
            message={
              <FormattedMessage
                defaultMessage="You can complete your board setup and test the connection at a later date."
                id="wMzI0d"
              />
            }
          />
        </VStack>
      );
    default:
      return (
        <Box textAlign="center">
          <Spinner variant="mooven" size="lg" />
        </Box>
      );
  }
}

function ManufacturerInstructions({ vendor }: { vendor: VendorType }) {
  let instructions: ReactNode = null;
  if (vendor === VendorType.Bartco) {
    instructions = <InstructionsBartco />;
  }
  return (
    <Flex width="full" alignItems="center" justifyContent="center" padding={8}>
      <VStack
        maxWidth="100%"
        alignItems="flex-start"
        spacing={4}
        flex="0 1 320px"
      >
        <VStack alignItems="flex-start" spacing={2} color="gray.900">
          <HStack spacing={0}>
            <VmsBoardIcon width="40px" height="40px" />
            <SwapIcon width="40px" height="40px" />
          </HStack>
          <Box fontSize="2xl" fontWeight="bold">
            <FormattedMessage
              id="cs8ahd"
              defaultMessage="Grant access to your board"
            />
          </Box>
        </VStack>
        {instructions}
      </VStack>
    </Flex>
  );
}

function InstructionsBartco() {
  return (
    <>
      <VStack alignItems="flex-start" spacing={1}>
        <Box fontSize="md" fontWeight="bold">
          <FormattedMessage
            id="pWQ+YK"
            defaultMessage="If you’ve hired your board..."
          />
        </Box>
        <Box fontSize="sm">
          <FormattedMessage
            id="0HIG0y"
            defaultMessage="Ask your board provider to “cross-hire” your board to <strong>mooven-bartco-integration@mooven.com</strong> and ask them for your boards serial number."
          />
        </Box>
      </VStack>

      <VStack alignItems="flex-start" spacing={1}>
        <Box fontSize="md" fontWeight="bold">
          <FormattedMessage
            id="gNNYi1"
            defaultMessage="If you own your board..."
          />
        </Box>
        <Box fontSize="sm">
          <FormattedMessage
            id="BODk4A"
            defaultMessage="Allowing cross-hiring is done by logging into Bartco’s portal and adding <strong>mooven-bartco-integration@mooven.com</strong> to your list of cross-hires"
          />
        </Box>
      </VStack>

      <Button
        variant="solid"
        colorScheme="greenDark"
        as={Link}
        to="https://vmsstudio.com/login.php"
        target="_blank"
      >
        <FormattedMessage id="SONbgc" defaultMessage="Login to Bartco" />
      </Button>
    </>
  );
}
