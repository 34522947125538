import ReactDOMServer from 'react-dom/server';
import { useIntl } from 'react-intl';
import { parseDate, WeekDay } from '@lib/dates';
import {
  AlignValue,
  Axis,
  AxisTitleAlignValue,
  AxisTypeValue,
  SeriesLineOptions,
  TooltipFormatterContextObject,
} from 'highcharts';
import Chart from 'design-system/atoms/chart';
import { Box } from '@chakra-ui/react';
import Tooltip from '../InsightChart/Tooltip';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { formatSeconds } from '../../utils/insightCharts';

interface DataItem {
  date: number;
  value: number | null;
}

export interface DailyBaselineChartProps {
  data: {
    name: WeekDay;
    label: string;
    items: DataItem[];
  }[];
  daysOfWeek: WeekDay[];
  colorsByDay: Record<WeekDay, string>;
  routeName: string;
  baselineName: string;
}

export default function DailyBaselineChart({
  data,
  daysOfWeek,
  colorsByDay,
  routeName,
  baselineName,
}: DailyBaselineChartProps) {
  const { track } = useAnalytics();
  const trackChartEvents = (eventName, eventProps = {}) => {
    track(eventName, {
      ...eventProps,
      referrer: 'Daily baseline Chart',
    });
  };
  const { formatTime, formatMessage } = useIntl();
  const formattedSeries = data
    .filter((item) => daysOfWeek?.includes(item.name))
    .map((dailyData) => {
      const formattedDayData = dailyData.items.map((item) => [
        parseDate('2000-01-01', { name: 'UTC' }).getTime() +
          item.date * 60 * 1000,
        item.value,
      ]);
      return {
        type: 'line',
        data: formattedDayData,
        name: dailyData.label,
        color: colorsByDay[dailyData.name],
      } as SeriesLineOptions;
    });
  const chartOptions = {
    title: {
      text: undefined,
      margin: 50,
    },
    chart: {
      spacingBottom: 15,
    },
    tooltip: {
      useHTML: true,
      shared: true,
      borderRadius: 6,
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      borderColor: '#f3f3f3',
      shadow: false,
      outside: true,
      style: {
        zIndex: 2000,
      },
      formatter() {
        const formatterObject =
          this as unknown as TooltipFormatterContextObject;
        if (!formatterObject.points) {
          return '';
        }
        formatterObject.points.sort((pointA, pointB) => pointB.y! - pointA.y!);
        const tooltipInHtml = ReactDOMServer.renderToStaticMarkup(
          <Tooltip
            timeZone="UTC"
            points={formatterObject.points.map((point) => ({
              ...point,
              x: Number(point.x ?? 0),
            }))}
            formatValue={formatSeconds}
            dateFormat="LT"
            formatMessage={formatMessage}
          />
        );
        return tooltipInHtml.toString();
      },
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime' as AxisTypeValue,
      tickInterval: 15,
      labels: {
        formatter: (ctx) =>
          formatTime(ctx.value, {
            hour: 'numeric',
            hour12: true,
            timeZone: 'UTC',
          }),
      },
      title: {
        style: {
          fontSize: '12px',
        },
        text: '',
      },
    },
    yAxis: {
      top: '13.5%',
      height: '86.5%',
      softMin: 0,
      labels: {
        formatter: (item) => `${Math.floor(Number(item.value) / 60)}m`,
      },
      title: {
        text: formatMessage({
          defaultMessage: 'Journey time (mins)',
          id: '+GI6rp',
        }),
        reserveSpace: false,
        rotation: 0,
        x: -10,
        y: -32,
        align: 'high' as AxisTitleAlignValue,
        textAlign: 'left' as AlignValue,
      },
    },
    series: formattedSeries,
    exporting: {
      chartOptions: {
        title: {
          text: `${baselineName} - ${formatMessage({
            defaultMessage: 'Daily baseline chart',
            id: 'USt6oa',
          })}`,
        },
        subtitle: {
          text: routeName,
        },
        legend: {
          enabled: true,
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
          ],
        },
      },
      csv: {
        dateFormat: '%H:%M',
        columnHeaderFormatter: (item) => {
          if (!item || item instanceof Axis) {
            return formatMessage({ defaultMessage: 'Time', id: 'ug01Mk' });
          }
          return item.name;
        },
      },
    },
  };
  return (
    <Box position="relative">
      <Chart eventTracker={trackChartEvents} options={chartOptions} />
    </Box>
  );
}
