import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useAnalytics } from './analytics/useAnalytics';
import { useFailureToast } from './useFailureToast';
import { useSuccessToast } from './useSuccessToast';
import { errorReport } from '../utils/errors';

interface ToastMessageProps {
  title: string;
  description?: string;
}
type ToastMessageValue = string | ToastMessageProps | false;

interface ActionWithFeedbackConfig<
  TAction extends (...args: any[]) => Promise<any> | any,
> {
  action: TAction;
  successMessage?:
    | ToastMessageValue
    | ((response: Awaited<ReturnType<TAction>>) => ToastMessageValue);
  failureMessage?: ToastMessageValue | ((error: any) => ToastMessageValue);
  trackEvent?: {
    name: string;
    data: { referrer: string } & Record<string, any>;
  };
  onActionWillPerform?: (...input: Parameters<TAction>) => void;
  onSuccess?: (value: Awaited<ReturnType<TAction>>) => void;
  onFailure?: (err: any) => void;
  onComplete?: () => void;
}

export function useActionWithFeedback<
  TAction extends (...args: any[]) => Promise<any> | any,
>({
  action,
  successMessage,
  failureMessage,
  trackEvent,
  onActionWillPerform,
  onSuccess,
  onFailure,
  onComplete,
}: ActionWithFeedbackConfig<TAction>) {
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const successToast = useSuccessToast();
  const failureToast = useFailureToast();
  const { formatMessage } = useIntl();
  const { track } = useAnalytics();
  const performAction = async (...args: Parameters<typeof action>) => {
    setIsPerformingAction(true);
    if (trackEvent) {
      track(trackEvent.name, trackEvent.data);
    }
    try {
      if (onActionWillPerform) {
        onActionWillPerform(...args);
      }
      const returnValue = await action(...args);
      if (onSuccess) {
        onSuccess(returnValue);
      }
      const unwrappedSuccessValue =
        typeof successMessage === 'function'
          ? successMessage(returnValue)
          : successMessage;
      if (unwrappedSuccessValue !== false) {
        successToast(
          getToastProps(
            formatMessage({
              id: 'JXdbo8',
              defaultMessage: 'Done',
            }),
            unwrappedSuccessValue
          )
        );
      }
    } catch (err) {
      errorReport.handled(err);
      const unwrappedFailureMessage =
        typeof failureMessage === 'function'
          ? failureMessage(err)
          : failureMessage;
      if (unwrappedFailureMessage !== false) {
        failureToast(
          getToastProps(
            formatMessage({
              id: 'NLgwF5',
              defaultMessage: 'An error has occured',
            }),
            unwrappedFailureMessage
          )
        );
      }
      if (onFailure) {
        onFailure(err);
      }
    } finally {
      setIsPerformingAction(false);
      if (onComplete) {
        onComplete();
      }
    }
  };

  return {
    performAction,
    isPerformingAction,
  };
}

function getToastProps(
  defaultMessage: string,
  customMessage?: string | ToastMessageProps
): ToastMessageProps {
  if (!customMessage) {
    return {
      title: defaultMessage,
    };
  }
  if (typeof customMessage === 'string') {
    return { title: customMessage };
  }
  return customMessage;
}
