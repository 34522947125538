import rg4js from 'raygun4js';
import { envConfig } from './envConfig';

export function initRaygun() {
  if (envConfig.RAYGUN_API_KEY) {
    rg4js('enableCrashReporting', true);
    rg4js('apiKey', envConfig.RAYGUN_API_KEY);
    rg4js('options', {
      captureUnhandledRejections: true,
    });
    rg4js('setVersion', envConfig.APP_VERSION);
  }
}
