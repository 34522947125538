import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import {
  UseStepsReturn,
  HStack,
  Button,
  Box,
  Text,
  Flex,
  ButtonProps,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import ProgressSteps from 'design-system/molecules/progress-steps';
import { createPortal } from 'react-dom';
import { LayoutContext } from '../LayoutContext';

export type PreventWizardStepExit = 'PREVENT_WIZARD_STEP_EXIT';

export interface WizardStepConfig {
  name: string;
  title?: string;
  content: ReactNode;
  onStepWillExit?: () =>
    | void
    | PreventWizardStepExit
    | Promise<void | PreventWizardStepExit>;
}

interface ActionConfig {
  action: () => void | Promise<void>;
  label: string;
  buttonProps?: ButtonProps;
}
function isActionConfig(
  action?: ActionConfig | ActionConfig['action']
): action is ActionConfig {
  return action !== undefined && typeof action !== 'function';
}

export default function WizardLayout({
  steps,
  stepsManager,
  onExit,
  onCancel,
  isDisabled,
  hideStepper,
}: {
  steps: WizardStepConfig[];
  stepsManager: UseStepsReturn;
  onExit?: ActionConfig | ActionConfig['action'];
  onCancel?: ActionConfig | ActionConfig['action'];
  isDisabled?: boolean;
  hideStepper?: boolean;
}) {
  const { activeStep: activeStepIndex, setActiveStep } = stepsManager;
  const currentStep = steps[activeStepIndex];
  const isLastStep = stepsManager.activeStep === steps.length - 1;

  const [isDrillDownContentMounted, setIsDrillDownContentMounted] =
    useState<boolean>(false);
  const [drillDownContentRoot, setDrillDownContentRoot] =
    useState<HTMLDivElement | null>(null);
  const layoutContext = useContext(LayoutContext);
  const wizardLayoutContext = useMemo(
    () => ({
      ...layoutContext,
      LayoutContextDrillDown: createWizardLayoutContextDrillDown({
        setIsDrillDownContentMounted,
        drillDownContentRoot,
      }),
    }),
    [drillDownContentRoot, layoutContext]
  );

  const [waitingStepExit, setWaitingStepExit] = useState<boolean>(false);
  const handleStepExit = async (nextStepIndex: number | 'EXIT_WIZARD') => {
    try {
      setWaitingStepExit(true);
      const exitResponse = await currentStep.onStepWillExit?.();
      if (exitResponse !== 'PREVENT_WIZARD_STEP_EXIT') {
        if (nextStepIndex === 'EXIT_WIZARD') {
          if (isActionConfig(onExit)) {
            await onExit.action();
          } else {
            await onExit?.();
          }
        } else {
          setActiveStep(nextStepIndex);
        }
      }
    } finally {
      setWaitingStepExit(false);
    }
  };
  const nextClickHandler = () => {
    handleStepExit(activeStepIndex + 1);
  };
  const exitClickHandler = () => {
    handleStepExit('EXIT_WIZARD');
  };

  return (
    <LayoutContext.Provider value={wizardLayoutContext}>
      <Flex
        direction="column"
        minHeight="100%"
        ref={(el) => setDrillDownContentRoot(el)}
        display={!isDrillDownContentMounted ? 'none' : undefined}
      />
      <Flex
        direction="column"
        gap={4}
        minHeight="100%"
        display={isDrillDownContentMounted ? 'none' : undefined}
      >
        {!hideStepper && (
          <Box marginRight="-16px" flex="0">
            <ProgressSteps
              steps={steps}
              stepsManager={{
                ...stepsManager,
                setActiveStep: (stateSetter) => {
                  const nextStep =
                    typeof stateSetter === 'function'
                      ? stateSetter(stepsManager.activeStep)
                      : stateSetter;
                  handleStepExit(nextStep);
                },
              }}
              isDisabled={waitingStepExit || isDisabled}
            />
          </Box>
        )}

        <Flex direction="column" gap={8} height="100%" flex="1">
          <Flex direction="column" gap={4} flex="1">
            {currentStep.title && (
              <Text m={0} fontSize="2xl" fontWeight="bold" flex="0">
                {currentStep.title}
              </Text>
            )}
            <Box>{currentStep.content}</Box>
          </Flex>
          <HStack
            spacing={2}
            position="sticky"
            bottom="0"
            margin="-8px -16px -16px"
            padding="8px 16px 16px"
            backgroundColor="rgba(255, 255, 255, 0.9)"
            backdropFilter="blur(4px)"
          >
            {!isLastStep && !hideStepper && (
              <Button
                size="sm"
                variant="solid"
                colorScheme="greenDark"
                onClick={nextClickHandler}
                isLoading={waitingStepExit}
                isDisabled={isDisabled}
              >
                <FormattedMessage id="9+Ddtu" defaultMessage="Next" />
              </Button>
            )}
            {onExit && (
              <Button
                key="exit-button"
                size="sm"
                variant={isLastStep ? 'solid' : 'outline'}
                colorScheme={isLastStep ? 'greenDark' : undefined}
                onClick={exitClickHandler}
                isLoading={waitingStepExit && isLastStep}
                {...(isActionConfig(onExit) ? onExit.buttonProps : {})}
                isDisabled={waitingStepExit || isDisabled}
              >
                {isActionConfig(onExit) ? (
                  onExit.label
                ) : (
                  <FormattedMessage id="+kXXNF" defaultMessage="Save & exit" />
                )}
              </Button>
            )}
            {onCancel && (
              <Button
                key="cancel-button"
                size="sm"
                variant={hideStepper || isLastStep ? 'outline' : 'ghost'}
                onClick={isActionConfig(onCancel) ? onCancel.action : onCancel}
                {...(isActionConfig(onCancel) ? onCancel.buttonProps : {})}
                isDisabled={waitingStepExit || isDisabled}
              >
                {isActionConfig(onCancel) ? (
                  onCancel.label
                ) : (
                  <FormattedMessage id="47FYwb" defaultMessage="Cancel" />
                )}
              </Button>
            )}
          </HStack>
        </Flex>
      </Flex>
    </LayoutContext.Provider>
  );
}

function createWizardLayoutContextDrillDown({
  setIsDrillDownContentMounted,
  drillDownContentRoot,
}) {
  return function WizardLayoutContextDrillDown({ children }) {
    useEffect(() => {
      setIsDrillDownContentMounted(true);
      return () => {
        setIsDrillDownContentMounted(false);
      };
    }, []);

    return drillDownContentRoot && createPortal(children, drillDownContentRoot);
  };
}
