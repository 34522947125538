import React, { useState } from 'react';
import MapLayerVmsBoards from 'design-system/atoms/custom-google-map/map-layer-vms-boards';
import VmsPreview from './VmsPreview';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

export interface MapLayerVmsBoardsProps {
  vmsBoards: {
    vmsConfigId: string;
    latitude?: number;
    longitude?: number;
    lastConnected?: number;
    isRoutePolling: boolean;
    color: string;
    status: 'Active' | 'Past' | 'Future' | 'Draft' | 'Deleted' | 'Cooldown';
    startUpdatingAt?: Date;
    stopUpdatingAt?: Date;
  }[];
  orgId: string;
  siteId: string;
  deleteVmsBoard: (vmsConfigId: number) => Promise<void>;
}

export default function MapLayerVmsBoardsWithPreview({
  vmsBoards,
  orgId,
  siteId,
  deleteVmsBoard,
}: MapLayerVmsBoardsProps) {
  const { track } = useAnalytics();
  const [vmsConfigId, setVmsConfigId] = useState<string | undefined>(undefined);

  const handleOpenPreview = (id: string) => {
    track('Vms preview opened from map layer', {
      property: 'Vms map icon',
      referrer: 'Vms Map layer',
      value: id,
    });
    setVmsConfigId(id);
  };

  const handleClose = () => {
    setVmsConfigId(undefined);
  };

  const handleDeleteVmsBoard = async () => {
    await deleteVmsBoard(Number(vmsConfigId));
    handleClose();
  };
  const selectedBoard = vmsBoards?.find(
    (vmsBoard) => vmsBoard.vmsConfigId === vmsConfigId
  );
  return (
    <>
      <MapLayerVmsBoards vmsBoards={vmsBoards} onVmsClick={handleOpenPreview} />
      {vmsConfigId !== undefined && (
        <VmsPreview
          orgId={orgId}
          siteId={siteId}
          isLive={selectedBoard?.status === 'Active'}
          hasSetupCompleted={
            !!(selectedBoard?.stopUpdatingAt ?? selectedBoard?.startUpdatingAt)
          }
          deleteVmsBoard={handleDeleteVmsBoard}
          vmsConfigId={vmsConfigId}
          isOpen={vmsConfigId !== undefined}
          onCancel={handleClose}
          isRoutePolling={selectedBoard?.isRoutePolling}
          lastUpdatedDate={new Date(Number(selectedBoard?.lastConnected))}
        />
      )}
    </>
  );
}
