import { Box, Flex, Heading } from '@chakra-ui/react';
import Card from 'design-system/atoms/card';

export function BaselineDetailsPanelLayout({
  leftPanel,
  rightPanel,
  title,
}: {
  leftPanel: React.ReactNode;
  rightPanel: React.ReactNode;
  title: string;
}) {
  return (
    <Box>
      <Heading size="md">{title}</Heading>
      <Flex direction={{ base: 'column', md: 'row' }} pt={4}>
        <Card
          minW="300px"
          maxW="100%"
          colorMode="light"
          mr={{ base: 0, md: 4 }}
          mb={{ base: 4, md: 0 }}
        >
          {leftPanel}
        </Card>
        <Card colorMode="light" flexGrow={1}>
          {rightPanel}
        </Card>
      </Flex>
    </Box>
  );
}
