/* eslint-disable camelcase */
import { Marker } from '@react-google-maps/api';
import { Box } from '@chakra-ui/react';
import IconsLegend from './icons-legend.svg?react';
import ute__morning from './ute__morning.svg?url';
import ute__afternoon from './ute__afternoon.svg?url';
import ute__night from './ute__night.svg?url';
import ute__morning_afternoon from './ute__morning_afternoon.svg?url';
import ute__morning_night from './ute__morning_night.svg?url';
import ute__afternoon_night from './ute__afternoon_night.svg?url';
import ute__morning_afternoon_night from './ute__morning_afternoon_night.svg?url';
import truck__morning from './truck__morning.svg?url';
import truck__afternoon from './truck__afternoon.svg?url';
import truck__night from './truck__night.svg?url';
import truck__morning_afternoon from './truck__morning_afternoon.svg?url';
import truck__morning_night from './truck__morning_night.svg?url';
import truck__afternoon_night from './truck__afternoon_night.svg?url';
import truck__morning_afternoon_night from './truck__morning_afternoon_night.svg?url';

import Point = google.maps.Point;
import LatLngLiteral = google.maps.LatLngLiteral;

interface WorkVehicleSite {
  point: LatLngLiteral;
  vehicleType: 'ute' | 'truck';
  shifts:
    | ['morning']
    | ['afternoon']
    | ['night']
    | ['morning', 'afternoon']
    | ['morning', 'night']
    | ['afternoon', 'night']
    | ['morning', 'afternoon', 'night'];
}
export interface MapLayerWorkShiftsProps {
  workSites: WorkVehicleSite[];
}

const icons = {
  ute__morning,
  ute__afternoon,
  ute__night,
  ute__morning_afternoon,
  ute__morning_night,
  ute__afternoon_night,
  ute__morning_afternoon_night,
  truck__morning,
  truck__afternoon,
  truck__night,
  truck__morning_afternoon,
  truck__morning_night,
  truck__afternoon_night,
  truck__morning_afternoon_night,
};
function getIconUrl({ vehicleType, shifts }: WorkVehicleSite) {
  const iconKey = `${vehicleType}__${shifts.join('_')}`;
  return icons[iconKey as keyof typeof icons];
}

export function MapLegendWorkShifts() {
  return (
    <Box
      bgColor="white"
      boxShadow="lg"
      padding={2}
      borderRadius="6px"
      sx={{
        svg: {
          display: 'block',
        },
      }}
    >
      <IconsLegend />
    </Box>
  );
}
export default function MapLayerWorkShifts({
  workSites,
}: MapLayerWorkShiftsProps) {
  return workSites.map((workSite) => (
    <Marker
      key={`${workSite.point.lat}_${workSite.point.lng}_${workSite.vehicleType}_${workSite.shifts.join('_')}`}
      icon={{
        url: getIconUrl(workSite),
        size: new google.maps.Size(83, 25),
        anchor: new Point(41, 25),
      }}
      position={workSite.point}
      clickable={false}
    />
  ));
}

export const hardCodedMapLayerWorkShifts = (
  <MapLayerWorkShifts
    workSites={[
      {
        point: { lat: -33.75080642003875, lng: 150.9495994699891 },
        vehicleType: 'truck',
        shifts: ['morning'],
      },
      {
        point: { lat: -33.76113100904712, lng: 151.036162777863 },
        vehicleType: 'truck',
        shifts: ['morning', 'afternoon', 'night'],
      },
      {
        point: { lat: -33.70702983607846, lng: 151.1181222056559 },
        vehicleType: 'truck',
        shifts: ['morning'],
      },
      {
        point: { lat: -33.76830986396882, lng: 151.1155384761917 },
        vehicleType: 'ute',
        shifts: ['morning', 'afternoon', 'night'],
      },
      {
        point: { lat: -33.81225730093159, lng: 151.1943990461711 },
        vehicleType: 'truck',
        shifts: ['afternoon'],
      },
      {
        point: { lat: -33.87644561516838, lng: 151.2251563596966 },
        vehicleType: 'truck',
        shifts: ['morning'],
      },
      {
        point: { lat: -33.87024784443396, lng: 151.2183358028655 },
        vehicleType: 'truck',
        shifts: ['afternoon', 'night'],
      },
      {
        point: { lat: -33.90974297206521, lng: 151.2135755492193 },
        vehicleType: 'truck',
        shifts: ['morning', 'afternoon'],
      },
      {
        point: { lat: -33.94735936851828, lng: 150.9623800120239 },
        vehicleType: 'ute',
        shifts: ['afternoon'],
      },
      {
        point: { lat: -33.95032931011244, lng: 150.8823303802276 },
        vehicleType: 'truck',
        shifts: ['morning'],
      },
      {
        point: { lat: -33.93996378990521, lng: 151.093355010035 },
        vehicleType: 'truck',
        shifts: ['morning', 'afternoon', 'night'],
      },
      {
        point: { lat: -33.82709956115151, lng: 151.0009861326303 },
        vehicleType: 'truck',
        shifts: ['morning'],
      },
      {
        point: { lat: -33.85788671044723, lng: 151.0743230690304 },
        vehicleType: 'ute',
        shifts: ['morning', 'afternoon'],
      },
      {
        point: { lat: -33.91567115378658, lng: 151.1773580239494 },
        vehicleType: 'truck',
        shifts: ['morning', 'afternoon', 'night'],
      },
      {
        point: { lat: -33.87914099600065, lng: 151.1332881441734 },
        vehicleType: 'truck',
        shifts: ['afternoon'],
      },
    ]}
  />
);
