import { Box, Button, Text, Heading, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import bgImageUrl from './promo-panel-img.png?url';

export default function MainPanelImpactAssessmentType() {
  return (
    <Box
      width="100%"
      height="100%"
      overflow="auto"
      backgroundColor="yellow.100"
      backgroundSize={{
        base: '600px',
        md: '400px',
        lg: '800px',
        '2xl': '1002px',
      }}
      backgroundImage={bgImageUrl}
      backgroundRepeat="no-repeat"
      backgroundPosition="top -20px left 300px"
    >
      <VStack maxWidth="350px" padding={8} spacing={3} alignItems="flex-start">
        <Heading size="lg">
          <FormattedMessage
            id="SGwHp8"
            defaultMessage="Plan better with an impact assessment."
          />
        </Heading>
        <Box fontSize="xs">
          <Text mb={1}>
            Mooven’s impact assessments give you context on your worksite,
            faster and cheaper than alternatives. Understand bus and business
            operating hours and volumes. With the ability to add volume, detour,
            speed and safety, and journey time analysis. Use these to identify
            potential longer working hours, assess viability of detours,
            understand what safety planning is required, and gain greater
            context of your site’s performance.
          </Text>
          <Text mb={1} fontWeight="bold">
            Our assessments are fast!
          </Text>
          <Text mb={0}>
            The free base assessment is available within 10 minutes while added
            analysis will be in your inbox within 5 working days.
          </Text>
        </Box>
        <Button variant="outline" size="xs">
          <FormattedMessage id="TdTXXf" defaultMessage="Learn more" />
        </Button>
      </VStack>
    </Box>
  );
}
