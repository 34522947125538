import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useRef } from 'react';
import BaselineForm from './BaselineForm';
import { useActionWithFeedback } from '../../hooks/useActionWithFeedback';
import { catchValidationError } from '../../utils/form';

export function CreateBaselineModal({
  isOpen,
  onClose,
  createBaseline,
  minBaselineDate,
  baselineCreationTypes,
  analyticsReferrer = 'Baseline modal',
  isFeatureBaselinesPhase2Enabled = false,
}: {
  isOpen: boolean;
  onClose: () => void;
  createBaseline: (input: {
    name: string;
    startDate?: string;
    endDate?: string;
    baselineType: 'SIMPLE_RANGE_BASELINE' | '4WEEKS_AVERAGE_BASELINE';
    description?: string;
  }) => Promise<void>;
  minBaselineDate?: string;
  baselineCreationTypes: (
    | 'SIMPLE_RANGE_BASELINE'
    | '4WEEKS_AVERAGE_BASELINE'
  )[];
  analyticsReferrer?: string;
  isFeatureBaselinesPhase2Enabled?: boolean;
}) {
  const submitActionRef = useRef<any>();
  const { performAction, isPerformingAction } = useActionWithFeedback({
    action: async (values) => {
      await createBaseline(values);
      onClose();
    },
    trackEvent: {
      name: 'Baseline Created',
      data: {
        referrer: analyticsReferrer,
      },
    },
  });
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay backgroundColor="whiteAlpha.800" />
      <ModalContent>
        <ModalHeader>
          <VStack w="full" direction="column" alignItems="flex-start">
            <Box>
              <FormattedMessage defaultMessage="Create baseline" id="VhLfoG" />
            </Box>
          </VStack>
        </ModalHeader>
        <ModalCloseButton backgroundColor="gray.100" />
        <ModalBody overflowY="auto">
          <BaselineForm
            onSubmit={performAction}
            submitActionRef={submitActionRef}
            minDate={minBaselineDate}
            baselineCreationTypes={baselineCreationTypes}
            isFeatureBaselinesPhase2Enabled={isFeatureBaselinesPhase2Enabled}
          />
        </ModalBody>
        <ModalFooter justifyContent="start">
          <Button
            data-testid="create-baseline-confirm"
            variant="solid"
            colorScheme="greenDark"
            mr={2}
            onClick={() =>
              submitActionRef.current?.().catch(catchValidationError)
            }
            isLoading={isPerformingAction}
          >
            <FormattedMessage defaultMessage="Create" id="VzzYJk" />
          </Button>
          <Button
            data-testid="create-baseline-cancel"
            variant="outline"
            mr={2}
            onClick={onClose}
          >
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
