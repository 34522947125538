import useSWRImmutable from 'swr/immutable';
import type { BaselineJourneyTimeData } from '@webapp/bff/src/types/transport';
import { dataTrpc } from '../config/trpc';

export default function useBaselineJourneyTimeData(params: {
  baselineId: string;
  aggMethod: 'max' | 'avg';
}): {
  data: BaselineJourneyTimeData | null;
  isValidating: boolean;
  error: any;
} {
  const { data, isValidating, error } = useSWRImmutable(
    params.baselineId && params.aggMethod
      ? {
          keyName: 'useBaselineJourneyTimeData',
          baselineId: params.baselineId,
          aggMethod: params.aggMethod,
        }
      : null,
    async (swrParams) => {
      const journeyTimeData =
        await dataTrpc.viewData.getBaselineJourneyTimeData.query({
          baselineId: swrParams.baselineId,
          aggMethod: swrParams.aggMethod,
        });
      return journeyTimeData;
    }
  );

  return {
    data: data ?? null,
    isValidating,
    error,
  };
}
