import { useEffect, useMemo, useRef, useState } from 'react';
import { WorkArea } from '@webapp/bff/src/types/work-area';
import CustomGoogleMap from 'design-system/atoms/custom-google-map';
import MapLayerRoutes from 'design-system/atoms/custom-google-map/map-layer-routes';
import { wktToPointArray } from '@lib/gis';
import {
  WorkAreasEditorManager,
  WorkAreasEditorMapLayer,
} from '../WorkAreasEditor';
import {
  DisruptionAreaEditorManager,
  DisruptionAreaEditorMapLayer,
} from '../DisruptionAreaEditor';
import { Route } from '../../types/route';

export default function ImpactAssessmentRequestMap({
  workAreas,
  workAreasEditorManager,
  disruptionAreaEditorManager,
  routes,
  isEditingDisruptionArea,
  showSatelliteImage,
}: {
  workAreas: WorkArea[];
  workAreasEditorManager: WorkAreasEditorManager;
  disruptionAreaEditorManager: DisruptionAreaEditorManager;
  routes: Route[];
  isEditingDisruptionArea: boolean;
  showSatelliteImage: boolean;
}) {
  const [mapTypeId, setMapTypeId] = useState<string>();
  const mapRef = useRef<google.maps.Map>(null);
  useEffect(() => {
    setMapTypeId(
      showSatelliteImage
        ? google.maps.MapTypeId.HYBRID
        : google.maps.MapTypeId.ROADMAP
    );
  }, [showSatelliteImage]);
  const mapOptions = useMemo(
    () => ({
      mapTypeId,
    }),
    [mapTypeId]
  );
  return (
    <CustomGoogleMap
      setBoundsToElements={[...workAreas, ...routes]}
      options={mapOptions}
      onMapTypeIdChanged={() => {
        const newTypeId = mapRef.current?.getMapTypeId();
        if (newTypeId) {
          setMapTypeId(newTypeId);
        }
      }}
    >
      <MapLayerRoutes
        routes={routes.map((r) => ({
          id: r.routeId.toString(),
          path: wktToPointArray(r.wkt),
        }))}
      />
      <WorkAreasEditorMapLayer
        workAreas={workAreas}
        workAreasEditorManager={workAreasEditorManager}
      />
      {isEditingDisruptionArea && (
        <DisruptionAreaEditorMapLayer
          disruptionAreaEditorManager={disruptionAreaEditorManager}
        />
      )}
    </CustomGoogleMap>
  );
}
