import useSWR from 'swr';
import type { DataType } from '@webapp/bff';
import { useMemo } from 'react';
import { TimePeriod } from '../types/timePeriod';
import { dataTrpc } from '../config/trpc';
import useRoutesData from './useRoutesData';
import {
  DateBoundaries,
  getBoundariesForTimePeriod,
} from '../utils/timePeriodUtils';
import { Route } from '../types/route';

export interface UseInsightChartDataParams {
  insightId: string;
  siteId: string;
  siteTimeZone: string;
  dataType: DataType;
  timePeriod: TimePeriod;
  timePeriodReference?: number;
  baselineId?: string;
}

type ViewDataReqParams = Parameters<
  typeof dataTrpc.viewData.getViewData.query
>[0];
type ViewData = Awaited<ReturnType<typeof dataTrpc.viewData.getViewData.query>>;
type ViewDataHandlerResponse = {
  requestParams: ViewDataReqParams;
  mainData: ViewData;
  compareData?: ViewData;
};
export type SiteInsightData = {
  startTime: number;
  endTime: number;
  insightType: ViewDataReqParams['dataType'];
  routes: {
    routeId: number;
    trackId: string;
    name: string;
    items: ViewData[number]['items'];
    compareItems?: ViewData[number]['items'];
  }[];
};

export default function useInsightChartData(
  input: UseInsightChartDataParams | null
) {
  const swrSettings = {
    revalidateOnFocus: false,
    shouldRetryOnError: true,
    errorRetryInterval: 30000,
    loadingTimeout: 60000,
    refreshInterval:
      input?.timePeriod.type === 'TIME_PERIOD_LAST_24_HOURS' ||
      input?.timePeriod.type === 'TIME_PERIOD_LAST_WEEK'
        ? 5 * 60 * 1000
        : undefined,
    refreshWhenHidden: true,
  };
  const routesDataHandler = useRoutesData(
    input ? Number(input.siteId) : undefined
  );
  const viewDataHandler = useSWR<ViewDataHandlerResponse>(
    input,
    viewDataFetcher,
    swrSettings
  );
  const data = useMemo(
    () =>
      transformDataForCharts({
        viewData: viewDataHandler.data,
        routesData: routesDataHandler.data,
      }),
    [viewDataHandler.data, routesDataHandler.data]
  );
  return {
    data,
    error: viewDataHandler.error || routesDataHandler.error,
    isValidating:
      viewDataHandler.isValidating || routesDataHandler.isValidating,
  };
}

function transformDataForCharts({
  viewData,
  routesData,
}: {
  viewData?: ViewDataHandlerResponse;
  routesData?: Route[];
}): SiteInsightData | undefined {
  if (!viewData || !routesData) {
    return undefined;
  }
  const { requestParams, mainData, compareData } = viewData;
  return {
    startTime: requestParams.startTime,
    endTime: requestParams.endTime,
    insightType: requestParams.dataType,
    routes: mainData
      .map((routeViewData) => {
        const routeData = routesData.find(
          (route) => route.routeId === routeViewData.routeId
        );
        const compareSeries = compareData?.find(
          (compareRouteViewData) =>
            compareRouteViewData.routeId === routeViewData.routeId
        );
        if (!routeData) {
          return null;
        }
        return {
          routeId: routeViewData.routeId,
          trackId: routeData.trackId,
          name: routeData.name,
          items: routeViewData.items,
          compareItems: compareSeries?.items,
        };
      })
      .filter((r) => r !== null) as SiteInsightData['routes'],
  };
}

async function viewDataFetcher(requestInput: UseInsightChartDataParams) {
  const originalBoundaries = getBoundariesForTimePeriod(
    requestInput.timePeriod
  );
  const { startTime, endTime, compare } =
    requestInput.timePeriod.type === 'TIME_PERIOD_CUSTOM' ||
    !requestInput.timePeriodReference
      ? originalBoundaries
      : offsetBoundariesToLasPolled(
          originalBoundaries,
          requestInput.timePeriodReference * 1000
        );
  const requestParams: ViewDataReqParams = {
    startTime: startTime.getTime(),
    endTime: endTime.getTime(),
    timezone: requestInput.siteTimeZone,
    siteId: requestInput.siteId,
    dataType: requestInput.dataType,
    orgId: requestInput.insightId,
    baselineId: requestInput.baselineId,
  };

  const dataRequest = dataTrpc.viewData.getViewData.query(requestParams);
  const compareRequest = !compare
    ? Promise.resolve(undefined)
    : dataTrpc.viewData.getViewData.query({
        ...requestParams,
        startTime: compare.startTime.getTime(),
        endTime: compare.endTime.getTime(),
      });
  const [mainData, compareData] = await Promise.all([
    dataRequest,
    compareRequest,
  ]);
  return {
    requestParams,
    mainData,
    compareData,
  };
}

function offsetBoundariesToLasPolled(
  boundaries: DateBoundaries<Date>,
  lastPolled: number
): DateBoundaries<Date> {
  const { startTime, endTime } = boundaries;
  const diff = endTime.valueOf() - lastPolled;
  if (diff <= 0) {
    return boundaries;
  }
  return {
    ...boundaries,
    startTime: new Date(startTime.valueOf() - diff),
    endTime: new Date(lastPolled),
  };
}
