import { Box, Checkbox, Heading, Text, VStack } from '@chakra-ui/react';
import Card from 'design-system/atoms/card';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { RefObject, useImperativeHandle } from 'react';
import { DisruptionPackType } from '@webapp/bff/src/types/report';
import { useHookFormSubmitAction } from '../../utils/form';

export default function StepImpactAssessmentType({
  onSubmit,
  submitActionRef,
  defaultValues,
}: {
  onSubmit: (formValues: {
    impactAssessmentType: DisruptionPackType[];
  }) => void;
  defaultValues?: {
    impactAssessmentType: DisruptionPackType[];
  };
  submitActionRef: RefObject<() => void>;
}) {
  const { register, handleSubmit } = useForm({
    defaultValues,
  });
  const submitAction = useHookFormSubmitAction({
    handleSubmit,
    onSubmit,
  });
  useImperativeHandle(submitActionRef, () => submitAction);
  return (
    <VStack spacing={4} alignItems="stretch">
      <Box>
        <Text fontSize="xs">
          <FormattedMessage
            defaultMessage="Request an Impact Assessment report to help plan traffic management strategies (including closures and detours) and reveal opportunities to request longer working windows."
            id="8sZgdf"
          />
        </Text>
      </Box>
      <VStack spacing={2} alignItems="flex-start">
        <Card padding={4} boxShadow="soft">
          <Checkbox
            {...register('impactAssessmentType')}
            value="PUBLIC_TRANSPORT_AND_BUSINESSES"
            alignItems="flex-start"
            isReadOnly
            size="lg"
          >
            <VStack alignItems="flex-start">
              <Heading size="sm">
                <FormattedMessage
                  defaultMessage="Public transport and businesses"
                  id="5yOfb+"
                />
              </Heading>
              <Text fontSize="xs" as="span">
                <FormattedMessage
                  defaultMessage="Automated assessment of potential disruption to nearby Public transport and businesses"
                  id="Q0lZ8v"
                />
              </Text>
              <Text fontSize="xs" color="green.400" fontWeight="bold" as="span">
                <FormattedMessage defaultMessage="FREE" id="7GOXli" />
              </Text>
            </VStack>
          </Checkbox>
        </Card>
        <Card padding={4} boxShadow="soft" w="full">
          <Checkbox
            {...register('impactAssessmentType')}
            value="TRAFFIC_VOLUME"
            alignItems="flex-start"
            size="lg"
          >
            <Heading size="sm">
              <FormattedMessage
                defaultMessage="Add traffic volume assessment"
                id="SxYD9l"
              />
            </Heading>
          </Checkbox>
        </Card>
        <Card padding={4} boxShadow="soft" w="full">
          <Checkbox
            {...register('impactAssessmentType')}
            value="DETOUR"
            alignItems="flex-start"
            size="lg"
          >
            <Heading size="sm">
              <FormattedMessage
                defaultMessage="Add detour analysis"
                id="qi6Raa"
              />
            </Heading>
          </Checkbox>
        </Card>
        <Card padding={4} boxShadow="soft" w="full">
          <Checkbox
            {...register('impactAssessmentType')}
            value="SPEED_SAFETY"
            alignItems="flex-start"
            size="lg"
          >
            <Heading size="sm">
              <FormattedMessage
                defaultMessage="Add speed and safety analysis"
                id="OaozJC"
              />
            </Heading>
          </Checkbox>
        </Card>
        <Card padding={4} boxShadow="soft" w="full">
          <Checkbox
            {...register('impactAssessmentType')}
            value="JOURNEY_TIME"
            alignItems="flex-start"
            size="lg"
          >
            <Heading size="sm">
              <FormattedMessage
                defaultMessage="Add journey time assessment"
                id="enQrYV"
              />
            </Heading>
          </Checkbox>
        </Card>
      </VStack>
    </VStack>
  );
}
