import { Marker } from '@react-google-maps/api';
import ReactDOMServer from 'react-dom/server';

export interface MapLayerVmsBoardsProps {
  vmsBoards: {
    vmsConfigId: string;
    latitude?: number;
    longitude?: number;
    lastConnected?: number;
    isRoutePolling: boolean;
    color: string;
    status: 'Active' | 'Past' | 'Future' | 'Draft' | 'Deleted' | 'Cooldown';
  }[];
  onVmsClick?: (vmsConfigId: string) => void;
}

const MS_ONE_HOUR = 60 * 60 * 1000;

export default function MapLayerVmsBoards({
  vmsBoards,
  onVmsClick,
}: MapLayerVmsBoardsProps) {
  const msNow = Date.now();
  return vmsBoards
    .filter(
      (vmsData) =>
        vmsData.latitude !== undefined && vmsData.longitude !== undefined
    )
    .map((vmsBoard) => {
      let iconStatus: 'inactive' | 'active' | 'warning' = 'inactive';
      if (vmsBoard.status === 'Active') {
        const lastConnectedEllapsed = !vmsBoard.lastConnected
          ? 0
          : msNow - vmsBoard.lastConnected;
        iconStatus =
          lastConnectedEllapsed > MS_ONE_HOUR || !vmsBoard.isRoutePolling
            ? 'warning'
            : 'active';
      }
      const iconUrl = getIconUrl({ color: vmsBoard.color, status: iconStatus });
      return (
        <Marker
          key={vmsBoard.vmsConfigId}
          position={{ lat: vmsBoard.latitude!, lng: vmsBoard.longitude! }}
          icon={{
            url: iconUrl,
          }}
          onClick={() => onVmsClick && onVmsClick(vmsBoard.vmsConfigId)}
        />
      );
    });
}

interface VmsIconComponentProps {
  color: string;
}

function VmsIconComponent({ color }: VmsIconComponentProps) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{`.iconColor { fill: ${color}; }`}</style>
      <mask
        id="path-1-outside-1_15718_6333"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
        fill="black"
      >
        <rect fill="white" width="24" height="25" />
        <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" />
      </mask>
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        className="iconColor"
      />
      <path
        d="M0 0H24H0ZM24 20C24 22.7614 21.7614 25 19 25H5C2.23858 25 0 22.7614 0 20C0 21.6569 1.79086 23 4 23H20C22.2091 23 24 21.6569 24 20ZM0 24V0V24ZM24 0V24V0Z"
        className="iconColor"
        style={{ filter: `brightness(0.7)` }}
        mask="url(#path-1-outside-1_15718_6333)"
      />
      <path
        d="M11.6667 8.66667C11.6667 8.48257 11.8159 8.33333 12 8.33333C12.1841 8.33333 12.3333 8.48257 12.3333 8.66667C12.3333 8.85076 12.1841 9 12 9C11.8159 9 11.6667 8.85076 11.6667 8.66667Z"
        fill="white"
      />
      <path
        d="M12 9.33333C11.8159 9.33333 11.6667 9.48257 11.6667 9.66667C11.6667 9.85076 11.8159 10 12 10C12.1841 10 12.3333 9.85076 12.3333 9.66667C12.3333 9.48257 12.1841 9.33333 12 9.33333Z"
        fill="white"
      />
      <path
        d="M11.6667 10.6667C11.6667 10.4826 11.8159 10.3333 12 10.3333C12.1841 10.3333 12.3333 10.4826 12.3333 10.6667C12.3333 10.8508 12.1841 11 12 11C11.8159 11 11.6667 10.8508 11.6667 10.6667Z"
        fill="white"
      />
      <path
        d="M11.6667 12C11.6667 11.8159 11.8159 11.6667 12 11.6667C12.1841 11.6667 12.3333 11.8159 12.3333 12C12.3333 12.1841 12.1841 12.3333 12 12.3333C11.8159 12.3333 11.6667 12.1841 11.6667 12Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7.33333C6 6.59695 6.59695 6 7.33333 6H16.6667C17.403 6 18 6.59695 18 7.33333V13.3333C18 14.0697 17.403 14.6667 16.6667 14.6667H12.6667V16H15.3333C15.3333 15.6318 15.6318 15.3333 16 15.3333C16.3682 15.3333 16.6667 15.6318 16.6667 16V17.3333C16.6667 17.7015 16.3682 18 16 18C15.6318 18 15.3333 17.7015 15.3333 17.3333H8.66667C8.66667 17.7015 8.36819 18 8 18C7.63181 18 7.33333 17.7015 7.33333 17.3333V16C7.33333 15.6318 7.63181 15.3333 8 15.3333C8.36819 15.3333 8.66667 15.6318 8.66667 16H11.3333V14.6667H7.33333C6.59695 14.6667 6 14.0697 6 13.3333V7.33333ZM7.33333 8C7.33333 7.63181 7.63181 7.33333 8 7.33333H16C16.3682 7.33333 16.6667 7.63181 16.6667 8V12.6667C16.6667 13.0349 16.3682 13.3333 16 13.3333H8C7.63181 13.3333 7.33333 13.0349 7.33333 12.6667V8Z"
        fill="white"
      />
    </svg>
  );
}
function VmsIconComponentWithWarning({ color }: VmsIconComponentProps) {
  return (
    <svg
      width="32"
      height="34"
      viewBox="0 0 32 34"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{`.iconColor { fill: ${color}; }`}</style>
      <mask
        id="path-1-outside-1_3_31"
        maskUnits="userSpaceOnUse"
        x="0"
        y="9"
        width="24"
        height="25"
        fill="black"
      >
        <rect fill="white" y="9" width="24" height="25" />
        <path d="M0 13C0 10.7909 1.79086 9 4 9H20C22.2091 9 24 10.7909 24 13V29C24 31.2091 22.2091 33 20 33H4C1.79086 33 0 31.2091 0 29V13Z" />
      </mask>
      <path
        d="M0 13C0 10.7909 1.79086 9 4 9H20C22.2091 9 24 10.7909 24 13V29C24 31.2091 22.2091 33 20 33H4C1.79086 33 0 31.2091 0 29V13Z"
        className="iconColor"
      />
      <path
        d="M0 9H24H0ZM24 29C24 31.7614 21.7614 34 19 34H5C2.23858 34 0 31.7614 0 29C0 30.6569 1.79086 32 4 32H20C22.2091 32 24 30.6569 24 29ZM0 33V9V33ZM24 9V33V9Z"
        className="iconColor"
        style={{ filter: `brightness(0.7)` }}
        mask="url(#path-1-outside-1_3_31)"
      />
      <path
        d="M11.6667 17.6667C11.6667 17.4826 11.8159 17.3333 12 17.3333C12.1841 17.3333 12.3333 17.4826 12.3333 17.6667C12.3333 17.8508 12.1841 18 12 18C11.8159 18 11.6667 17.8508 11.6667 17.6667Z"
        fill="white"
      />
      <path
        d="M12 18.3333C11.8159 18.3333 11.6667 18.4826 11.6667 18.6667C11.6667 18.8508 11.8159 19 12 19C12.1841 19 12.3333 18.8508 12.3333 18.6667C12.3333 18.4826 12.1841 18.3333 12 18.3333Z"
        fill="white"
      />
      <path
        d="M11.6667 19.6667C11.6667 19.4826 11.8159 19.3333 12 19.3333C12.1841 19.3333 12.3333 19.4826 12.3333 19.6667C12.3333 19.8508 12.1841 20 12 20C11.8159 20 11.6667 19.8508 11.6667 19.6667Z"
        fill="white"
      />
      <path
        d="M11.6667 21C11.6667 20.8159 11.8159 20.6667 12 20.6667C12.1841 20.6667 12.3333 20.8159 12.3333 21C12.3333 21.1841 12.1841 21.3333 12 21.3333C11.8159 21.3333 11.6667 21.1841 11.6667 21Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 16.3333C6 15.597 6.59695 15 7.33333 15H16.6667C17.403 15 18 15.597 18 16.3333V22.3333C18 23.0697 17.403 23.6667 16.6667 23.6667H12.6667V25H15.3333C15.3333 24.6318 15.6318 24.3333 16 24.3333C16.3682 24.3333 16.6667 24.6318 16.6667 25V26.3333C16.6667 26.7015 16.3682 27 16 27C15.6318 27 15.3333 26.7015 15.3333 26.3333H8.66667C8.66667 26.7015 8.36819 27 8 27C7.63181 27 7.33333 26.7015 7.33333 26.3333V25C7.33333 24.6318 7.63181 24.3333 8 24.3333C8.36819 24.3333 8.66667 24.6318 8.66667 25H11.3333V23.6667H7.33333C6.59695 23.6667 6 23.0697 6 22.3333V16.3333ZM7.33333 17C7.33333 16.6318 7.63181 16.3333 8 16.3333H16C16.3682 16.3333 16.6667 16.6318 16.6667 17V21.6667C16.6667 22.0349 16.3682 22.3333 16 22.3333H8C7.63181 22.3333 7.33333 22.0349 7.33333 21.6667V17Z"
        fill="white"
      />
      <path
        d="M25.5862 3.0762L25.5861 3.07614C24.8802 1.85683 23.12 1.85683 22.414 3.07614L22.414 3.0762L17.394 11.7495C17.394 11.7495 17.394 11.7495 17.394 11.7495C16.688 12.9691 17.5677 14.5 18.9801 14.5H29.0201C30.4324 14.5 31.3122 12.9691 30.6062 11.7495C30.6061 11.7495 30.6061 11.7495 30.6061 11.7495L25.5862 3.0762ZM24.0001 8.83333C23.9096 8.83333 23.8334 8.75719 23.8334 8.66666V7.33333C23.8334 7.2428 23.9096 7.16666 24.0001 7.16666C24.0906 7.16666 24.1667 7.2428 24.1667 7.33333V8.66666C24.1667 8.75719 24.0906 8.83333 24.0001 8.83333ZM23.8334 11.5V11.1667H24.1667V11.5H23.8334Z"
        fill="#ED8936"
        stroke="white"
      />
    </svg>
  );
}
function VmsIconComponentInactive() {
  return (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="path-1-outside-1_3_43"
        maskUnits="userSpaceOnUse"
        x="0"
        y="7"
        width="24"
        height="25"
        fill="black"
      >
        <rect fill="white" y="7" width="24" height="25" />
        <path d="M0 11C0 8.79086 1.79086 7 4 7H20C22.2091 7 24 8.79086 24 11V27C24 29.2091 22.2091 31 20 31H4C1.79086 31 0 29.2091 0 27V11Z" />
      </mask>
      <path
        d="M0 11C0 8.79086 1.79086 7 4 7H20C22.2091 7 24 8.79086 24 11V27C24 29.2091 22.2091 31 20 31H4C1.79086 31 0 29.2091 0 27V11Z"
        fill="#D4DBE2"
      />
      <path
        d="M0 7H24H0ZM24 27C24 29.7614 21.7614 32 19 32H5C2.23858 32 0 29.7614 0 27C0 28.6569 1.79086 30 4 30H20C22.2091 30 24 28.6569 24 27ZM0 31V7V31ZM24 7V31V7Z"
        fill="#ACB5BF"
        mask="url(#path-1-outside-1_3_43)"
      />
      <path
        d="M11.6667 15.6667C11.6667 15.4826 11.8159 15.3333 12 15.3333C12.1841 15.3333 12.3333 15.4826 12.3333 15.6667C12.3333 15.8508 12.1841 16 12 16C11.8159 16 11.6667 15.8508 11.6667 15.6667Z"
        fill="#7E8590"
      />
      <path
        d="M12 16.3333C11.8159 16.3333 11.6667 16.4826 11.6667 16.6667C11.6667 16.8508 11.8159 17 12 17C12.1841 17 12.3333 16.8508 12.3333 16.6667C12.3333 16.4826 12.1841 16.3333 12 16.3333Z"
        fill="#7E8590"
      />
      <path
        d="M11.6667 17.6667C11.6667 17.4826 11.8159 17.3333 12 17.3333C12.1841 17.3333 12.3333 17.4826 12.3333 17.6667C12.3333 17.8508 12.1841 18 12 18C11.8159 18 11.6667 17.8508 11.6667 17.6667Z"
        fill="#7E8590"
      />
      <path
        d="M11.6667 19C11.6667 18.8159 11.8159 18.6667 12 18.6667C12.1841 18.6667 12.3333 18.8159 12.3333 19C12.3333 19.1841 12.1841 19.3333 12 19.3333C11.8159 19.3333 11.6667 19.1841 11.6667 19Z"
        fill="#7E8590"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 14.3333C6 13.597 6.59695 13 7.33333 13H16.6667C17.403 13 18 13.597 18 14.3333V20.3333C18 21.0697 17.403 21.6667 16.6667 21.6667H12.6667V23H15.3333C15.3333 22.6318 15.6318 22.3333 16 22.3333C16.3682 22.3333 16.6667 22.6318 16.6667 23V24.3333C16.6667 24.7015 16.3682 25 16 25C15.6318 25 15.3333 24.7015 15.3333 24.3333H8.66667C8.66667 24.7015 8.36819 25 8 25C7.63181 25 7.33333 24.7015 7.33333 24.3333V23C7.33333 22.6318 7.63181 22.3333 8 22.3333C8.36819 22.3333 8.66667 22.6318 8.66667 23H11.3333V21.6667H7.33333C6.59695 21.6667 6 21.0697 6 20.3333V14.3333ZM7.33333 15C7.33333 14.6318 7.63181 14.3333 8 14.3333H16C16.3682 14.3333 16.6667 14.6318 16.6667 15V19.6667C16.6667 20.0349 16.3682 20.3333 16 20.3333H8C7.63181 20.3333 7.33333 20.0349 7.33333 19.6667V15Z"
        fill="#7E8590"
      />
      <path
        d="M26.6933 3.03333L25.7467 3.98C24.7566 3.18951 23.5419 2.73226 22.2763 2.67359C21.0107 2.61492 19.759 2.95784 18.7 3.65333L19.6733 4.62667C20.3533 4.23333 21.1533 4 22 4C24.58 4 26.6667 6.08667 26.6667 8.66667C26.6667 9.51333 26.4333 10.3133 26.04 10.9933L27.0067 11.96C27.6565 10.9848 28.0022 9.83858 28 8.66667C28 7.25333 27.5067 5.95333 26.6867 4.92667L27.6333 3.98L26.6933 3.03333V3.03333ZM24 0.666666H20V2H24V0.666666ZM21.3333 6.29333L22.6667 7.62667V5.33333H21.3333V6.29333ZM16.0133 2.66667L15.1667 3.51333L17 5.35333C16.345 6.33394 15.9969 7.48741 16 8.66667C16 11.98 18.68 14.6667 22 14.6667C23.2267 14.6667 24.3667 14.3 25.32 13.6667L26.9867 15.3333L27.8333 14.4867L22.6933 9.34667L16.0133 2.66667ZM22 13.3333C19.42 13.3333 17.3333 11.2467 17.3333 8.66667C17.3333 7.81333 17.5667 7.01333 17.9667 6.32L24.34 12.6933C23.6533 13.1 22.8533 13.3333 22 13.3333V13.3333Z"
        fill="#7E8590"
      />
    </svg>
  );
}

const iconString = {
  active: ReactDOMServer.renderToStaticMarkup(
    <VmsIconComponent color={'ICON_COLOR_REPLACE'} />
  ),
  warning: ReactDOMServer.renderToStaticMarkup(
    <VmsIconComponentWithWarning color={'ICON_COLOR_REPLACE'} />
  ),
  inactive: ReactDOMServer.renderToStaticMarkup(VmsIconComponentInactive()),
};
const encodedInactiveIcon = encodedSvgUrl(iconString.inactive);
function encodedSvgUrl(svg: string) {
  return `data:image/svg+xml,${encodeURIComponent(svg)}`;
}
function getIconUrl({
  color,
  status,
}: {
  color: string;
  status: 'active' | 'warning' | 'inactive';
}) {
  if (status === 'inactive') {
    return encodedInactiveIcon;
  }
  return encodedSvgUrl(iconString[status].replace('ICON_COLOR_REPLACE', color));
}
