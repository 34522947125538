import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';
import { WorkArea } from '@webapp/bff/src/types/work-area';
import { ActiveVms } from '@webapp/bff/src/types/vms';
import useSitesData from '../../data/useSitesData';
import VisibleRoutesSelector, { VisibleRoute } from '../VisibleRoutesSelector';
import useInsightData, { InsightDetails } from '../../data/useInsightData';
import useRoutesData from '../../data/useRoutesData';
import EmptySite, { EmptySiteMode } from '../EmptySite';
import { SiteInfo, useCurrentSiteData } from '../../data/useSiteData';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import useSiteView, { SiteView } from '../../hooks/useSiteView';
import useParamInsightId from '../../hooks/useParamInsightId';
import {
  NightDayPeriod,
  PlanConstraints,
  PlanSettingsParams,
  PredictionMode,
} from '../../types/plan';
import { MESSAGE_ERROR_SITE_NOT_FOUND } from '../../constants/messages';
import PlanConstraintsControls from './PlanConstraintsControls';
import SiteViewMap from '../SiteViewMap';
import { LayoutPlan } from './LayoutPlan';
import { colorsSortedByKey } from '../../utils/routes';
import { PlanInsightType, isPlanInsightType } from '../../constants/path';
import { getPlanChartConfigFromType } from '../../utils/planCharts';
import useMapInsightsData from '../../data/useMapInsightsData';
import { FEATURE_PLAN_INSIGHT_TYPE_SELECTOR } from '../../constants/features';
import { Site } from '../../types/site';
import { Route } from '../../types/route';
import useWorkAreasData from '../../data/useWorkAreasData';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import DailyProfileCharts from './DailyProfileCharts';
import VisibleWorkAreasSelector from '../VisibleWorkAreasSelector';
import { SiteType } from '../CreateSiteTrigger/types';
import useSiteVmsData from '../../data/useSiteVmsData';
import VisibleVmsSelector from '../VisibleVmsSelector';
import useSiteBaselinesData from '../../data/useSiteBaselinesData';

type LatLngLiteral = google.maps.LatLngLiteral;

const defaultPlanConstraintsState: PlanConstraints = {
  startTime: '09:00',
  endTime: '17:00',
  kpi: undefined,
  showConstraints: true,
};

export default function SitePlan() {
  const insightId = useParamInsightId();
  const { planInsightType } = useParams();
  if (
    !isPlanInsightType(planInsightType) ||
    planInsightType === PlanInsightType.COUNT
  ) {
    throw new Error(
      `Could not get planInsightType from router params. "${planInsightType}" is not a valid planInsightType.`
    );
  }
  const { formatMessage } = useIntl();
  const insightDataHandler = useInsightData(insightId);
  const siteView = useSiteView();
  const sitesDataHandler = useSitesData(insightId);
  const currentSiteDataHandler = useCurrentSiteData();
  const currentSite = currentSiteDataHandler.data;
  const baselinesHandler = useSiteBaselinesData(
    currentSite ? `${currentSite.siteId}` : undefined
  );
  const workAreasDataHandler = useWorkAreasData(
    siteView?.state.siteId.toString()
  );
  const routesDataHandler = useRoutesData(siteView.state.siteId);
  const vmsDataHandler = useSiteVmsData(siteView.state.siteId.toString());
  if (currentSiteDataHandler.error?.message === 'Not Found') {
    return (
      <div className="text-center pt-5">
        {formatMessage(MESSAGE_ERROR_SITE_NOT_FOUND)}
      </div>
    );
  }
  if (
    hasDataHandlerFeedBack([
      sitesDataHandler,
      baselinesHandler,
      currentSiteDataHandler,
      workAreasDataHandler,
      routesDataHandler,
      insightDataHandler,
      vmsDataHandler,
    ])
  ) {
    return (
      <DataHandlerFeedback
        dataHandlersParam={[
          sitesDataHandler,
          currentSiteDataHandler,
          workAreasDataHandler,
          routesDataHandler,
          insightDataHandler,
          vmsDataHandler,
        ]}
      />
    );
  }
  return (
    <SitePlanImpl
      insightId={insightId}
      currentSite={{
        ...currentSite!,
        hasBaseline: (baselinesHandler.data?.baselines?.length ?? 0) > 0,
      }}
      insightDetails={insightDataHandler.data!}
      insightType={planInsightType}
      workAreas={workAreasDataHandler.data!}
      routes={routesDataHandler.data!}
      vms={vmsDataHandler.data!}
      siteView={siteView!}
      deleteVmsBoard={vmsDataHandler.deleteVmsBoard}
    />
  );
}

function SitePlanImpl({
  insightId,
  currentSite,
  insightDetails,
  insightType,
  workAreas,
  routes,
  vms,
  siteView,
  deleteVmsBoard,
}: {
  insightId: string;
  currentSite: SiteInfo;
  insightDetails: InsightDetails;
  insightType: Exclude<PlanInsightType, PlanInsightType.COUNT>;
  workAreas: WorkArea[];
  routes: Array<Route>;
  vms: ActiveVms[];
  siteView: SiteView;
  deleteVmsBoard: (vmsConfigId: number) => Promise<void>;
}) {
  const featurePlanInsightTypeSelector = useFeatureSwitch(
    FEATURE_PLAN_INSIGHT_TYPE_SELECTOR
  );
  const isFeaturePlanInsightTypeSelector =
    featurePlanInsightTypeSelector === FeatureSwitchState.ON;
  const vmsColors = colorsSortedByKey(vms, 'vmsConfigId');

  const mapPanel = insightDetails.sections?.find(
    (panel) => panel.panelType === 'map'
  );

  const { formatMessage } = useIntl();

  const chartConfig = useMemo(
    () => getPlanChartConfigFromType(insightType, currentSite, formatMessage),
    [insightType, currentSite, formatMessage]
  );

  const availableDateRange = !currentSite.startPollingDate
    ? undefined
    : {
        from: (currentSite.startPollingDate || 0) * 1000,
        to: currentSite.active
          ? Date.now()
          : (currentSite.endPollingDate || 0) * 1000,
      };
  const defaultSettingsState = getDefaultSettingsStateForSite(currentSite);
  const planSettings =
    siteView?.state.planSettingsParams ?? defaultSettingsState;
  const setPlanSettings = (value: PlanSettingsParams) => {
    siteView?.setState({
      ...siteView.state,
      planSettingsParams: value,
    });
  };
  const planConstraints =
    siteView?.state.planConstraints ?? defaultPlanConstraintsState;
  const setPlanConstraints = (value: PlanConstraints) => {
    siteView?.setState({
      ...siteView.state,
      planConstraints: value,
    });
  };

  const planControlsManager = {
    insightId,
    insightType,
    insightUnitLabel: chartConfig.kpiUnitLabel,
    hasSiteImpact: currentSite.hasBaseline ?? false,
    siteTimeZone: currentSite.siteTimeZone,
    availableDateRange,
    controlsState: {
      ...planSettings,
      ...planConstraints,
    },
    setControlsState: (newState) => {
      siteView?.setState({
        ...siteView.state,
        planSettingsParams: {
          startDate: newState.startDate,
          endDate: newState.endDate,
          daysOfTheWeek: newState.daysOfTheWeek,
          period: newState.period,
          mode: newState.mode,
        },
        planConstraints: {
          startTime: newState.startTime,
          endTime: newState.endTime,
          kpi: newState.kpi,
          showConstraints: newState.showConstraints,
        },
      });
    },
  };

  const mapInsightsDataHandler = useMapInsightsData({
    insightId: insightDetails.insightId?.toString(),
    siteId: currentSite.siteId,
    panelId: mapPanel?.panelId,
  });

  const routesColors = colorsSortedByKey(routes, 'trackId');
  return (
    <LayoutPlan
      planControlsManager={planControlsManager}
      siteTimeZone={currentSite.siteTimeZone}
      availableDateRange={availableDateRange}
      planSettingsParams={planSettings}
      setPlanSettingsParams={setPlanSettings}
      windowTitle={`Mooven | ${insightDetails.name}`}
      sideBarContent={
        <>
          {!isFeaturePlanInsightTypeSelector && (
            <PlanConstraintsControls
              constraints={planConstraints}
              setConstraints={setPlanConstraints}
              kpiUnitLabel={chartConfig.kpiUnitLabel}
            />
          )}
          <VisibleRoutesSelector
            routes={(currentSite.routes as VisibleRoute[]) || []} // TODO: make "id" required for site route schema
            hiddenRoutes={siteView.state.hiddenRoutes}
            setHiddenRoutes={siteView.setHiddenRoutes}
          />
          {currentSite.siteType === SiteType.PROJECT_SITE && (
            <VisibleWorkAreasSelector
              workAreas={workAreas || []}
              hiddenWorkAreas={siteView.state.hiddenWorkAreas}
              setHiddenWorkAreas={siteView.setHiddenWorkAreas}
            />
          )}
          {vms && vms.length > 0 && (
            <VisibleVmsSelector
              vms={vms}
              vmsColors={vmsColors}
              hiddenVms={siteView.state.hiddenVms}
              setHiddenVms={siteView.setHiddenVms}
              insightId={insightId}
              siteId={currentSite.siteId.toString()}
              deleteVmsBoard={deleteVmsBoard}
            />
          )}
        </>
      }
      mapContent={
        <SiteViewMap
          orgId={insightId}
          deleteVmsBoard={deleteVmsBoard}
          site={currentSite}
          workAreas={workAreas}
          routes={routes}
          vms={vms}
          vmsColors={vmsColors}
          hiddenRoutes={siteView.state.hiddenRoutes}
          hiddenWorkAreas={siteView.state.hiddenWorkAreas}
          hiddenVms={siteView.state.hiddenVms}
          mapInsights={mapInsightsDataHandler.data}
          routesColors={routesColors}
          siteLocation={currentSite.siteLocation as LatLngLiteral} // TODO: make props of "siteLocation" required for site schema
          setHiddenRoutes={siteView.setHiddenRoutes}
        />
      }
    >
      {!currentSite.routes || currentSite.routes.length === 0 ? (
        <EmptySite
          siteId={siteView.state.siteId}
          emptySiteMode={EmptySiteMode.NO_ROUTE}
        />
      ) : (
        <DailyProfileCharts
          chartConfig={chartConfig}
          planDataParams={{
            insightId: insightDetails.insightId?.toString() ?? '',
            siteId: currentSite.siteId,
            siteTimeZone: currentSite.siteTimeZone,
            insightType,
            startDate: planSettings.startDate,
            endDate: planSettings.endDate,
            daysOfTheWeek: planSettings.daysOfTheWeek,
            predictionMode: planSettings.mode,
            isNightShift: planSettings.period === NightDayPeriod.NIGHT,
          }}
          constraints={planConstraints}
          hiddenRoutes={siteView.state.hiddenRoutes}
          routesColors={routesColors}
          resetDates={
            currentSite.startPollingDate
              ? () => {
                  setPlanSettings({
                    ...planSettings,
                    startDate: defaultSettingsState.startDate,
                    endDate: defaultSettingsState.endDate,
                  });
                }
              : undefined
          }
          hasSiteImpact={currentSite.hasBaseline ?? false}
        />
      )}
    </LayoutPlan>
  );
}

function getDefaultSettingsStateForSite(site: Site) {
  const endDate = (site.endPollingDate ?? 0) * 1000 || Date.now();
  return {
    startDate: Math.max(
      (site.startPollingDate ?? 0) * 1000 ||
        moment().subtract(4, 'week').startOf('day').valueOf(),
      moment(endDate).subtract(1, 'year').valueOf()
    ),
    endDate,
    daysOfTheWeek: [0, 1, 2, 3, 4, 5, 6],
    period: NightDayPeriod.DAY,
    mode: PredictionMode.AVERAGE,
  };
}
