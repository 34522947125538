import { StyledModal } from 'design-system/molecules/modal';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { RouteIcon } from 'design-system/atoms/custom-icons';
import RouteSelectMap from './RouteSelectMap';
import useRoutesData from '../../data/useRoutesData';

export interface RouteSelectModalProps {
  siteId: string;
  initialValue?: { name: string; routeId: number }[];
  onCancel: () => void;
  onSave: (routes: undefined | { name: string; routeId: number }[]) => void;
  isOpen: boolean;
  allowMultipleRoutes?: boolean;
}

export default function RouteSelectModal({
  siteId,
  initialValue,
  onCancel,
  isOpen,
  onSave,
  allowMultipleRoutes,
}: RouteSelectModalProps) {
  const routesDataHandler = useRoutesData(Number(siteId));
  const isFetching = routesDataHandler.isValidating;
  const routes = routesDataHandler.data;
  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onCancel}
      header={
        <Flex align="center" gap={2}>
          <RouteIcon w="24px" h="24px" />
          <Text
            mb={0}
            color="gray.900"
            fontSize="md"
            lineHeight={6}
            fontWeight={700}
          >
            <FormattedMessage
              id="4faqEn"
              defaultMessage="Select the route you like to display the journey time for"
            />
          </Text>
        </Flex>
      }
      modalProps={{ size: 'full' }}
      modalContentProps={{ backgroundColor: 'gray.50', h: '100%' }}
    >
      {isFetching && !routes ? (
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          p={10}
        >
          <Spinner variant="mooven" size="xl" />
        </Flex>
      ) : (
        <RouteSelectMap
          allowMultipleRoutes={allowMultipleRoutes}
          initialValue={initialValue}
          routes={routes ?? []}
          onSave={onSave}
          onCancel={onCancel}
        />
      )}
    </StyledModal>
  );
}
