import { FormattedMessage, useIntl } from 'react-intl';
import { StyledModal } from 'design-system/molecules/modal';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Spinner,
  VStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import type { MessageTypeConfig } from '@vms/shared/src/message-types';
import { useState } from 'react';
import ContextMenu from 'design-system/molecules/context-menu';
import VmsBoardContent from './VmsBoardContent';
import useVmsPreview from '../../data/useVmsPreview';
import { CreateVmsSteps, getVmsContextMenuItems } from './utils';
import DeleteBoardConfirmationModal from './DeleteBoardConfirmationModal';

export interface VmsPreviewProps {
  vmsConfigId?: string;
  onCancel: () => void;
  isOpen: boolean;
  isPaused?: boolean;
  isRoutePolling?: boolean;
  lastUpdatedDate?: Date;
  orgId?: string;
  siteId?: string;
  deleteVmsBoard?: (vmsConfigId: number) => Promise<void>;
  hasSetupCompleted?: boolean;
  currentStep?: CreateVmsSteps;
  messageConfig?: MessageTypeConfig;
  isLive: boolean;
}

export default function VmsPreview({
  vmsConfigId,
  isOpen,
  onCancel,
  isPaused = false,
  isRoutePolling,
  lastUpdatedDate,
  orgId,
  siteId,
  deleteVmsBoard,
  hasSetupCompleted,
  currentStep,
  messageConfig,
  isLive,
}: VmsPreviewProps) {
  const { formatMessage } = useIntl();
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(0);
  const vmsPreviewHandler = useVmsPreview(
    { vmsConfigId, overrideMessageConfig: messageConfig, isLive },
    false
  );
  const {
    isOpen: deleteModalOpen,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();
  const isFetching = vmsPreviewHandler.isValidating;
  const vmsPreview = vmsPreviewHandler.data;

  const handleOpenDeleteModal = () => {
    onOpenDeleteModal();
  };

  const handleDeleteBoard = async (id: number) => {
    if (deleteVmsBoard) {
      await deleteVmsBoard(id);
    }
    onCloseDeleteModal();
  };
  let messages =
    vmsPreview?.message?.map((m, index) => ({
      name:
        vmsPreview?.message.length === 1
          ? formatMessage({
              defaultMessage: 'Default',
              id: 'lKv8ex',
            })
          : formatMessage(
              {
                defaultMessage: 'Message {index}',
                id: 'ceDeTV',
              },
              { index: index + 1 }
            ),
      title:
        vmsPreview?.message.length === 1
          ? formatMessage({
              defaultMessage: 'Default message',
              id: 'Owk0Q5',
            })
          : formatMessage(
              {
                defaultMessage: 'Message {index}',
                id: 'ceDeTV',
              },
              { index: index + 1 }
            ),
      description: formatMessage({
        defaultMessage:
          'This will show when the conditions for this message are met.',
        id: 'rsu6lY',
      }),
      message: m,
      key: `message-${index}`,
    })) ?? [];
  if (vmsPreview?.liveMessage) {
    messages = [
      {
        name: formatMessage({
          defaultMessage: 'Live',
          id: 'Dn82AL',
        }),
        title: formatMessage({
          defaultMessage: 'Live view',
          id: 'M4tOOa',
        }),
        message: vmsPreview?.liveMessage,
        description: formatMessage({
          defaultMessage: 'The message that the board is currently displaying.',
          id: 'qH5tDb',
        }),
        key: 'live-message',
      },
      ...(messages.length > 1 ? messages : []),
    ];
  }
  return !vmsConfigId ? null : (
    <StyledModal
      isOpen={isOpen}
      onClose={onCancel}
      header={formatMessage({
        defaultMessage: 'Board preview',
        id: 'yEXyQb',
      })}
      footer={
        <HStack spacing={2}>
          {deleteVmsBoard && orgId && siteId && (
            <Box>
              <ContextMenu
                items={getVmsContextMenuItems({
                  orgId,
                  siteId,
                  vmsConfigId,
                  onOpenDeleteModal: handleOpenDeleteModal,
                  hasSetupCompleted,
                  currentStep,
                })}
                size="md"
                triggerLabel={
                  <FormattedMessage defaultMessage="Edit" id="wEQDC6" />
                }
              />
            </Box>
          )}
          <Button onClick={onCancel} variant="outline">
            <FormattedMessage id="rbrahO" defaultMessage="Close" />
          </Button>
        </HStack>
      }
      modalProps={{ size: 'lg' }}
    >
      {isFetching && !vmsPreview ? (
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          p={10}
        >
          <Spinner variant="mooven" size="xl" />
        </Flex>
      ) : (
        <VStack>
          {!isLive && (
            <Flex flexDir="column" w="full" mb={4}>
              <Text
                as="span"
                color="gray.900"
                fontSize="xs"
                lineHeight={4}
                fontWeight={400}
              >
                <FormattedMessage
                  defaultMessage="Your VMS messaging will be displayed as below."
                  id="eqqSfn"
                />
              </Text>
              <Text
                as="span"
                color="gray.900"
                fontSize="xs"
                lineHeight={4}
                fontWeight={400}
              >
                <FormattedMessage
                  defaultMessage="<strong>(X)</strong> represents selected journey times and can be seen in the live preview."
                  id="p9rXbE"
                />
              </Text>
            </Flex>
          )}
          {messages.length > 1 && (
            <ButtonGroup w="full" spacing={0} isAttached mt={0} mb={2}>
              {messages.map((m, index) => (
                <Button
                  onClick={() => setSelectedMessageIndex(index)}
                  key={m.name}
                  size="sm"
                  colorScheme={
                    selectedMessageIndex === index ? 'greenDark' : undefined
                  }
                  variant={selectedMessageIndex === index ? 'solid' : 'outline'}
                >
                  {m.name}
                </Button>
              ))}
            </ButtonGroup>
          )}
          <VmsBoardContent
            key={messages[selectedMessageIndex]?.key}
            isRoutePolling={isRoutePolling}
            lastUpdatedDate={lastUpdatedDate}
            title={messages[selectedMessageIndex]?.title}
            description={messages[selectedMessageIndex]?.description}
            message={messages[selectedMessageIndex]?.message?.frames ?? []}
            isPaused={isPaused}
          />
        </VStack>
      )}
      <DeleteBoardConfirmationModal
        isOpen={deleteModalOpen}
        onClose={onCloseDeleteModal}
        onConfirm={async () => {
          await handleDeleteBoard(Number(vmsConfigId));
        }}
      />
    </StyledModal>
  );
}
