import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { VehicleCountSummary } from '@data-pipeline/api/src/types/vehicle-count';
import { useIntl } from 'react-intl';
import { MESSAGE_ERROR_SITE_NOT_FOUND } from '../../constants/messages';
import useParamInsightId from '../../hooks/useParamInsightId';
import useCountSourcesData from '../../data/useCountSourcesData';
import useInsightData from '../../data/useInsightData';
import useRoutesData from '../../data/useRoutesData';
import useSitesData from '../../data/useSitesData';
import useSiteView from '../../hooks/useSiteView';
import {
  NightDayPeriod,
  SourceQueryParams,
} from '../../types/vehicleCountSource';
import EmptyCount from './EmptyCount';
import VehicleCountCharts from '../VehicleCountCharts';
import { useCurrentSiteData } from '../../data/useSiteData';
import { PlanInsightType } from '../../constants/path';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import { VehicleCountSources } from '../VehicleCountSource/VehicleCountSources';
import CountMap from '../CountMap';
import { LayoutPlan } from '../SitePlan/LayoutPlan';
import { PlanControlsManager } from '../PlanControls/types';
import useSiteBaselinesData from '../../data/useSiteBaselinesData';

export default function PlanVehicleCount() {
  const { formatMessage } = useIntl();
  const insightId = useParamInsightId();
  const insightDataHandler = useInsightData(insightId!);
  const sitesDataHandler = useSitesData(insightId!);
  const currentSiteDataHandler = useCurrentSiteData();
  const siteView = useSiteView();
  const currentSite = currentSiteDataHandler.data;
  const baselinesHandler = useSiteBaselinesData(
    currentSite ? `${currentSite.siteId}` : undefined
  );
  const countDataHandler = useCountSourcesData(
    insightId,
    siteView?.state.siteId
  );
  const routesDataHandler = useRoutesData(siteView?.state.siteId);
  const [focusedSourceId, setFocusedSourceId] = useState<number | null>(null);
  const [selectedSourceId, setSelectedSourceId] = useState<number>();
  const selectedSource =
    selectedSourceId !== undefined && countDataHandler.data
      ? countDataHandler.data.find((s) => s.id === selectedSourceId)
      : undefined;
  const [sourceQueryParams, setSourceQueryParams] = useState<SourceQueryParams>(
    {
      ...buildSourceParams(selectedSource, currentSite?.siteTimeZone),
      daysOfTheWeek: [0, 1, 2, 3, 4, 5, 6],
      period: NightDayPeriod.DAY,
    }
  );
  const availableDateRange = !selectedSource?.availableStartDate
    ? undefined
    : {
        from: selectedSource.availableStartDate * 1000,
        to: (selectedSource.availableEndDate || 0) * 1000,
      };
  const planControlsManager: PlanControlsManager = {
    insightId,
    insightType: PlanInsightType.COUNT,
    hasSiteImpact: (baselinesHandler.data?.baselines || []).length > 0,
    siteTimeZone: currentSite?.siteTimeZone ?? '',
    availableDateRange,
    controlsState: sourceQueryParams,
    setControlsState: setSourceQueryParams,
  };

  useEffect(() => {
    if (
      selectedSourceId === undefined &&
      countDataHandler.data &&
      countDataHandler.data.length > 0
    ) {
      setSelectedSourceId(countDataHandler.data[0].id);
    } else if (!selectedSource && selectedSourceId !== undefined) {
      setSelectedSourceId(undefined);
    }
  }, [countDataHandler.data, selectedSourceId, selectedSource]);

  useEffect(() => {
    if (
      currentSite &&
      selectedSource &&
      sourceQueryParams.sourceId !== selectedSource.id
    ) {
      setSourceQueryParams({
        ...sourceQueryParams,
        ...buildSourceParams(selectedSource, currentSite?.siteTimeZone),
      });
    }
  }, [currentSite, selectedSource, sourceQueryParams, setSourceQueryParams]);

  if (
    hasDataHandlerFeedBack([
      sitesDataHandler,
      currentSiteDataHandler,
      routesDataHandler,
      insightDataHandler,
      baselinesHandler,
    ])
  ) {
    return (
      <DataHandlerFeedback
        dataHandlersParam={[
          sitesDataHandler,
          currentSiteDataHandler,
          routesDataHandler,
          insightDataHandler,
        ]}
      />
    );
  }
  if (!currentSite) {
    return (
      <div className="text-center pt-5">
        {formatMessage(MESSAGE_ERROR_SITE_NOT_FOUND)}
      </div>
    );
  }
  if (!sitesDataHandler.data || !siteView) {
    return null;
  }
  return (
    <LayoutPlan
      siteTimeZone={currentSite.siteTimeZone}
      windowTitle={`Mooven | ${
        insightDataHandler.data ? insightDataHandler.data.name : ''
      }`}
      availableDateRange={availableDateRange}
      insightType={PlanInsightType.COUNT}
      planControlsManager={planControlsManager}
      // new type has made "mode" required for insight parameters, but old control is still
      // shared with plan page. Will be removed with FEATURE_PLAN_INSIGHT_TYPE_SELECTOR
      // @ts-ignore
      planSettingsParams={sourceQueryParams}
      setPlanSettingsParams={setSourceQueryParams}
      sideBarContent={
        <VehicleCountSources
          sources={countDataHandler.data}
          isFetchingSources={
            !countDataHandler.data && countDataHandler.isValidating
          }
          selectedSourceId={selectedSourceId}
          setSelectedSourceId={setSelectedSourceId}
          focusedSourceId={focusedSourceId}
          setFocusedSourceId={setFocusedSourceId}
        />
      }
      mapContent={
        <CountMap
          sources={countDataHandler.data}
          routes={routesDataHandler.data}
          isFetchingSources={
            !countDataHandler.data && countDataHandler.isValidating
          }
          selectedSourceId={selectedSourceId}
          setSelectedSourceId={setSelectedSourceId}
          focusedSourceId={focusedSourceId}
          setFocusedSourceId={setFocusedSourceId}
          siteLocation={currentSite.siteLocation}
        />
      }
    >
      <>
        {!selectedSource ? null : (
          <VehicleCountCharts
            insightId={insightId}
            site={currentSite}
            source={selectedSource}
            parameters={sourceQueryParams}
          />
        )}
        {!selectedSource && countDataHandler.data && <EmptyCount />}
      </>
    </LayoutPlan>
  );
}

function buildSourceParams(source?: VehicleCountSummary, timeZone = 'UTC') {
  if (!source) {
    return {
      sourceId: undefined,
      startDate: 0,
      endDate: 0,
    };
  }
  const sourceEndDate = source.availableEndDate ?? moment().unix();
  return {
    sourceId: source.id,
    startDate: Math.max(
      (source.availableStartDate ?? 0) * 1000 ||
        moment
          .unix(sourceEndDate)
          .tz(timeZone)
          .startOf('day')
          .subtract(4, 'weeks')
          .valueOf(),
      moment
        .unix(sourceEndDate)
        .tz(timeZone)
        .startOf('day')
        .subtract(1, 'year')
        .valueOf()
    ),
    endDate: moment.unix(sourceEndDate).tz(timeZone).endOf('day').valueOf(),
  };
}
