import { useImperativeHandle } from 'react';
import type { RefObject } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { isLocalDate, LocalDate } from '@lib/dates';
import { FormattedMessage, useIntl } from 'react-intl';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { BaselineConfig } from '@webapp/bff/src/types/baseline';
import moment from 'moment';
import RadioGroup from 'design-system/molecules/radio-group';
import MultiDayPicker from 'design-system/molecules/MultiDayPicker';
import AlertBox from '../AlertBox';
import { useHookFormSubmitAction } from '../../utils/form';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

interface BaselineFormFields {
  name: BaselineConfig['name'];
  description: BaselineConfig['description'];
  startDate?: LocalDate;
  endDate?: LocalDate;
  baselineType: BaselineConfig['baselineType'];
  excludedDates?: LocalDate[];
}

export default function BaselineForm({
  defaultValues,
  submitActionRef,
  onSubmit,
  isDisabled,
  minDate,
  baselineCreationTypes,
  isFeatureBaselinesPhase2Enabled = false,
  variant = 'default',
}: {
  defaultValues?: BaselineFormFields;
  submitActionRef: RefObject<() => Promise<void>>;
  onSubmit: SubmitHandler<BaselineFormFields>;
  isDisabled?: boolean;
  minDate?: string;
  baselineCreationTypes: (
    | 'SIMPLE_RANGE_BASELINE'
    | '4WEEKS_AVERAGE_BASELINE'
  )[];
  isFeatureBaselinesPhase2Enabled?: boolean;
  variant?: 'default' | 'narrow';
}) {
  const { formatMessage, formatDate } = useIntl();
  const { track } = useAnalytics();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<BaselineFormFields>({
    defaultValues: defaultValues ?? { baselineType: 'SIMPLE_RANGE_BASELINE' },
  });
  const startDateValue = watch('startDate');
  const baselineType = watch('baselineType');
  const endDateValue = watch('endDate');
  const formSubmitAction = useHookFormSubmitAction({
    handleSubmit,
    onSubmit,
    showToastOnSubmitError: true,
  });
  useImperativeHandle(submitActionRef, () =>
    isDisabled ? () => Promise.resolve() : formSubmitAction
  );
  const requiredMessage = formatMessage({
    defaultMessage: 'This field is required',
    id: 'TKmub+',
  });
  return (
    <VStack width="full" alignItems="stretch" spacing={8}>
      <VStack maxWidth="400px" alignItems="stretch">
        {baselineCreationTypes.length > 1 && (
          <RadioGroup
            direction="row"
            selection={baselineType}
            data-testid="baseline-type-radio-group"
            onSelectionChange={(value) => {
              setValue('baselineType', value);
              track('Baseline Type Changed', {
                baselineType: value,
                referrer: 'Baseline Form',
                feature: 'Baselines',
              });
            }}
            defaultSelection="SIMPLE_RANGE_BASELINE"
            options={[
              {
                isDisabled: isSubmitting || isDisabled,
                value: 'SIMPLE_RANGE_BASELINE',
                label: formatMessage({
                  defaultMessage: 'Static',
                  id: 'fCu/+n',
                }),
                description: formatMessage({
                  defaultMessage: 'Compare data to a defined date period',
                  id: 'QYHSvA',
                }),
              },
              {
                isDisabled: isSubmitting || isDisabled,
                value: '4WEEKS_AVERAGE_BASELINE',
                label: formatMessage({
                  defaultMessage: 'Rolling',
                  id: 'ZqHTTf',
                }),
                description: formatMessage({
                  defaultMessage: 'Compare to the previous 4 weeks',
                  id: 'j8c6VJ',
                }),
              },
            ]}
          />
        )}
        {baselineType === 'SIMPLE_RANGE_BASELINE' && (
          <>
            <Box>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel fontSize="xs" fontWeight="normal">
                  <FormattedMessage
                    defaultMessage="Name of baseline"
                    id="VIrAKI"
                  />
                </FormLabel>
                <Input
                  size="sm"
                  placeholder={formatMessage({
                    defaultMessage: 'Give your baseline a name',
                    id: '6iNS2D',
                  })}
                  isDisabled={isSubmitting || isDisabled}
                  maxLength={36}
                  {...register('name', { required: true })}
                />
                {errors.name && (
                  <FormErrorMessage>{requiredMessage}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <FormLabel fontSize="xs" fontWeight="normal">
                  <FormattedMessage defaultMessage="Description" id="Q8Qw5B" />
                </FormLabel>
                <Input
                  size="sm"
                  placeholder={formatMessage({
                    defaultMessage: 'Add description',
                    id: 'JB4Jq1',
                  })}
                  isDisabled={isSubmitting || isDisabled}
                  {...register('description')}
                />
              </FormControl>
            </Box>
            <VStack alignItems="stretch">
              <Flex direction="row" gap={4}>
                <Box flex="1">
                  <FormControl isInvalid={!!errors.startDate}>
                    <FormLabel fontSize="xs" fontWeight="normal">
                      <FormattedMessage
                        defaultMessage="Start date"
                        id="n5QvJy"
                      />
                    </FormLabel>
                    <Input
                      size="sm"
                      type="date"
                      min={minDate}
                      isDisabled={isSubmitting || isDisabled}
                      {...register('startDate', {
                        validate: (value) => {
                          if (!value) {
                            return requiredMessage;
                          }
                          if (
                            minDate &&
                            moment(value).isBefore(
                              moment(minDate).startOf('day')
                            )
                          ) {
                            const formattedMinDate = formatDate(
                              new Date(minDate),
                              {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              }
                            );
                            return formatMessage(
                              {
                                defaultMessage:
                                  'Data not available before {date}',
                                id: 'mdQgFi',
                              },
                              { date: formattedMinDate }
                            );
                          }
                          return true;
                        },
                      })}
                    />
                    {errors.startDate?.message && (
                      <FormErrorMessage>
                        {errors.startDate?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box flex="1">
                  <FormControl isInvalid={!!errors.endDate}>
                    <FormLabel fontSize="xs" fontWeight="normal">
                      <FormattedMessage defaultMessage="End date" id="Humfno" />
                    </FormLabel>
                    <Input
                      size="sm"
                      type="date"
                      min={minDate}
                      isDisabled={isSubmitting || isDisabled}
                      {...register('endDate', {
                        validate: (value) => {
                          if (!value) {
                            return requiredMessage;
                          }
                          if (
                            startDateValue &&
                            new Date(value) <= new Date(startDateValue)
                          ) {
                            return formatMessage({
                              defaultMessage:
                                'End date must be after start date',
                              id: 'OkC7HF',
                            });
                          }
                          return (
                            !startDateValue ||
                            new Date(value) > new Date(startDateValue)
                          );
                        },
                      })}
                    />
                    {errors.endDate?.message && (
                      <FormErrorMessage>
                        {errors.endDate?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
              </Flex>

              {areDatesInFuture(startDateValue, endDateValue) && (
                <Box>
                  <AlertBox
                    status="info"
                    title={formatMessage({
                      defaultMessage: 'You have included future dates',
                      id: 'DjOO1e',
                    })}
                    message={formatMessage({
                      defaultMessage:
                        "We'll inform you when your baseline is ready to be compared against.",
                      id: 'tbLM/b',
                    })}
                  />
                </Box>
              )}
              {areDatesLessThanFourWeeks(startDateValue, endDateValue) && (
                <Box>
                  <AlertBox
                    title={formatMessage({
                      defaultMessage: 'Fewer than 4 weeks selected',
                      id: 'gkM9cz',
                    })}
                    message={formatMessage({
                      defaultMessage:
                        'Mooven recommends including 4 weeks in your baseline in order to increase accuracy.',
                      id: 'RKx6xO',
                    })}
                  />
                </Box>
              )}
            </VStack>
          </>
        )}
      </VStack>
      {isFeatureBaselinesPhase2Enabled &&
        baselineType === 'SIMPLE_RANGE_BASELINE' &&
        isLocalDate(startDateValue) &&
        isLocalDate(endDateValue) && (
          <VStack alignItems="stretch" spacing={6}>
            <VStack alignItems="flex-start" spacing={2}>
              <Box fontSize="lg" fontWeight="bold">
                <FormattedMessage
                  defaultMessage="Exclude days from baseline"
                  id="KVGEPX"
                />
              </Box>
              <Box fontSize="xs" fontWeight="500" color="gray.500">
                <FormattedMessage
                  defaultMessage="Select days to exclude from baseline such as public holidays."
                  id="218inP"
                />
              </Box>
            </VStack>
            <Box>
              <Controller
                name="excludedDates"
                control={control}
                render={({ field }) => (
                  <MultiDayPicker
                    onChange={(selectedDays) => {
                      field.onChange(selectedDays);
                    }}
                    value={field.value ?? []}
                    minDate={startDateValue}
                    maxDate={endDateValue}
                    columns={variant === 'narrow' ? 1 : 2}
                  />
                )}
              />
            </Box>
          </VStack>
        )}
    </VStack>
  );
}
function areDatesInFuture(startDate?: string, endDate?: string) {
  const areDatesValid =
    isStringValidDateFormat(startDate) && isStringValidDateFormat(endDate);
  return (
    areDatesValid &&
    (new Date(startDate!) > new Date() || new Date(endDate!) > new Date())
  );
}
const MS_FOUR_WEEKS = 2419200000;
function areDatesLessThanFourWeeks(startDate?: string, endDate?: string) {
  const areDatesValid =
    isStringValidDateFormat(startDate) && isStringValidDateFormat(endDate);
  return (
    areDatesValid &&
    new Date(endDate!).getTime() - new Date(startDate!).getTime() <
      MS_FOUR_WEEKS
  );
}
function isStringValidDateFormat(date: string | undefined) {
  return !!date?.match(/^\d{4}-\d{2}-\d{2}$/);
}
