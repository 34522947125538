const ROUTE_COLORS = [
  '#D69E2E',
  '#B83280',
  '#2B6CB0',
  '#00A3C4',
  '#2C7A7B',
  '#00A042',
  '#DD6B20',
  '#4FD1C5',
  '#B794F4',
  '#2D3748',
  '#285E61',
  '#63B3ED',
  '#F6AD55',
  '#E53E3E',
  '#805AD5',
  '#7E8590',
  '#FC8181',
  '#B7791F',
  '#C05621',
  '#319795',
  '#00B5D8',
  '#6B46C1',
  '#00C853',
  '#3182CE',
  '#F6E05E',
  '#56586B',
  '#D53F8C',
  '#0987A0',
  '#F687B3',
  '#C53030',
];

export function getRouteColor(routeIndex: number): string {
  return ROUTE_COLORS[routeIndex % ROUTE_COLORS.length];
}
